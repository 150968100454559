import React, { useEffect } from "react";
import Card from "../../../components/card";
import Heading from "../../../components/heading/Heading";
import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//=============style=================

const root = { p: { xs: "10px", sm: "20px" } };
const detail = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  justifyContent: "space-between",
  alignItems: { xs: "flex-start", sm: "center" },
  minHeight: "40px",
};
const boxDirection = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  width: "100%",
};
const imgBox = {
  background: "#EFF1F7",
  borderRadius: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: { xs: "220px", md: "500px" },
  width: { xs: "100%", md: "30%" },
  p: "10px",
};
const mainImg = { width: { md: "90%" }, maxWidth: "200px", height: "200px" };
const datailBox = {
  height: { xs: "auto", md: "500px" },
  width: { xs: "100%", md: "70%" },
  p: "10px",
  pl: { xs: "0px", md: "16px" },
};
const desText = {
  width: { xs: "100%", sm: "40% " },
  textAlign: { xs: "left", sm: "justify" },
};

//=============component=================

const View = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedItem } = useSelector((state) => state.team);

  useEffect(() => {
    if (id !== selectedItem._id) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Card>
      <Box sx={root}>
        <Heading text="Details " />
        <Box sx={boxDirection}>
          <Box sx={imgBox}>
            <Box sx={mainImg}>
              <img
                src={selectedItem.photo}
                alt="profile"
                width="100%"
                height="100%"
                style={{ borderRadius: "50%" }}
              />
            </Box>
          </Box>
          <Box sx={datailBox}>
            <Box sx={detail}>
              <Typography>Name</Typography>
              <Typography variant="h6" >
                {selectedItem.name}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography>Department Name</Typography>
              <Typography variant="h6"> {selectedItem.department}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography>Designation</Typography>
              <Typography variant="h6">{selectedItem.designation}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography>Category</Typography>
              <Typography variant="h6">{selectedItem.category}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography>Linkedin URL</Typography>
              <a
                href={selectedItem.linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Typography variant="h6" sx={{ color: "#0575e6" }}>
                  {selectedItem.linkedInUrl}
                </Typography>
              </a>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography>Sequence</Typography>
              <Typography variant="h6">{selectedItem.level}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography>Gender</Typography>
              <Typography variant="h6">{selectedItem.gender}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography>Description</Typography>
              <Typography variant="h6" sx={desText}>
                {selectedItem.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default View;

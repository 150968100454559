import { IconButton } from "@mui/material";
import { backTopButton } from "../../assets/images";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  // const location =useLocation()
  // const { id } = useParams();
  function handleBack() {
    navigate(-1);
   
  }
  // /career?page=1
  return (
    <IconButton
      sx={{
        width: "30px",
        height: "30px",
        // display: back ? "flex" : "none",
        cursor: "pointer",
        mr: 1,
        mt: "2px",
      }}
      onClick={handleBack}
      data-cy="top-back-button"
    >
      <img src={backTopButton} alt="back" width="18px" height="100%" />
    </IconButton>
  );
};

export default BackButton;

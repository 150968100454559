import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { clButton } from "./FormOption";
const SubmitButton = ({ onCancel, onSubmit, isLoading }) => {
  return (
    <Box sx={clButton}>
      <Button
        variant="outlined"
        color="primary"
        onClick={onCancel}
        sx={{ maxWidth: "90px" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        type="submit"
        disabled={isLoading}
        onClick={onSubmit}
        sx={{ maxWidth: "90px" }}
      >
        {isLoading ? (
          <CircularProgress sx={{ color: "#fff" }} size={20} />
        ) : (
          "Submit"
        )}
      </Button>
    </Box>
  );
};

export default SubmitButton;

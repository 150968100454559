import axios from "axios";
import { REACT_APP_API_URL } from "../helper/envUrl";

//====get all==========
export const fetchDataExpertise = async () => {
  return await axios.get(`${process.env.REACT_APP_API_URL}/api/expertise`);
};

//====deleteExpertise==========

export const deleteExpertise = async ({ id, token }) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/expertise/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data; // Modify this based on your API response structure
    });
};

//===========addExpertiseData==========

export const addExpertiseData = async ({ data, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", data.title);
  bodyFormData.append("description", data.description);
  bodyFormData.append("image", data.image);
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/expertise`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};
//===========editExpertiseData==========

export const editExpertiseData = async ({ id, data, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", data.title);
  bodyFormData.append("description", data.description);
  bodyFormData.append("image", data.image);
  return await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/expertise/${id}`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};

import { Box, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState, useRef, useEffect } from "react";
import { ChromePicker } from "react-color";
import { plus } from "../../assets/images";

const ColorField = ({
  placeholder,
  name,
  type,
  value,
  onChange,
  error,
  helperText,
  sxProps,
  onBlur,
  ...otherProps
}) => {
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const colorPickerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        // Clicked outside of color picker, close it
        setColorPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleColorPickerToggle = () => {
    setColorPickerOpen(!colorPickerOpen);
  };

  const handleColorPickerClick = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up
    if (onBlur) {
      onBlur();
    }
  };

  const handleColorChange = (color) => {
    onChange({ target: { name, value: color.hex } });
    if (onBlur) {
    onBlur();
    }
  };

  return (
    <Box position="relative" ref={colorPickerRef}>
      <TextField
        fullWidth
        placeholder="Select color"
        name={name}
        type={value ? type : "text"}
        value={value}
        disabled={error ? false : true}
        error={error}
        helperText={helperText}
        sx={{
          ...sxProps,
        }}
        {...otherProps}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={handleColorPickerToggle}>
              <Box sx={{ width: "20px", height: "20px" }}>
                <img
                  src={plus}
                  alt="Color Picker"
                  style={{ cursor: "pointer", width: "100%", height: "100%" }}
                />
              </Box>
              {colorPickerOpen && (
                <Box
                  position="absolute"
                  zIndex="tooltip"
                  top="100%"
                  left={0}
                  mt={1}
                  onClick={handleColorPickerClick} // Close color picker on click inside
                >
                  <ChromePicker color={value} onChange={handleColorChange} />
                </Box>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ColorField;

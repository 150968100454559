export const baseFileName = (file) => {
  const matches = file.match(/-(\d+)-(.+?)\.[a-zA-Z0-9]+$/);
  return matches ? matches[2] : null;
};
export const downloadFile = async (fileUrl, index) => {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const fileExtension = fileUrl.split(".").pop();

    const filename = `${baseFileName(fileUrl)}.${fileExtension}`;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
export const capitalize = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const convertJobTypeLabel = (value) => {
  // Add more cases as needed for other job types

  switch (value) {
    case "fullTime":
      return "Full-Time";
    case "partTime":
      return "Part-Time";
    // Add more cases as needed
    default:
      return capitalize(value);
  }
};
export const capitalizeFirstLetter = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
export const formatJobType = (jobType) => {
  if (jobType === "fullTime") {
    return "Full-Time";
  } else if (jobType === "partTime") {
    return "Part-Time";
  } else if (jobType === "contract") {
    return "Special Service Contract";
  } else {
    // Handle other cases or return the original value if needed
    return jobType;
  }
};

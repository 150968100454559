import {
  Box,
  Button,
  IconButton,
  TableContainer,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { filterIcon } from "../../assets/images";
import Card from "../card";
import TableNoData from "../tablenodata";
import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import TableLoader from "../tableloader";
import { useDispatch, useSelector } from "react-redux";
import { setSelectItem } from "../../redux/slices/teamSlice";
import { OpenDeleteModal } from "../../redux/slices/adminSlice";
import { deleteCareer, fetchCareerData } from "../../api/Career";
import { toast } from "react-hot-toast";
import queryString from "query-string";
import { useLogout } from "../../hooks/logout";
import CareerDeleteModal from "../modals/CareerDeleteModal";
import TableHeadComponent from "./Tablehead";
import SidebarDrawer from "../reusableFilter/SidebarDrawer";
import dayjs from "dayjs";
import { CustomTooltip } from "../customTooltip";
import useCareerFilterForm from "./useCareerFilterForm";
import FilterChips from "./FilterChips";
import ReusablePagination from "./ReusablePagination";
import { capitalizeFirstLetter, convertJobTypeLabel, formatJobType } from "../../helper/helper";
import {
  addButtonStyle,
  containerd,
  drawercareerbtn,
  ellpsisBox,
  generateCircleStyle,
  mainBox,
  tableBox,
  wrap,
} from "../tableStyle/style";
import ActionButtons from "./ActionButton";
// ---------Component style------------

const CareerTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const handleLogout = useLogout();
  const queryClient = useQueryClient();
  const { selectedItem } = useSelector((state) => state.team);
  const { token } = useSelector((state) => state.login);
  const [recordStart, setRecordStart] = React.useState(1);
  const [filter, setFilter] = React.useState({});
  const [drawerfilter, setdrawerfilter] = React.useState({});

  const [open, setOpen] = React.useState(false);

  const handleOpen = (item) => {
    dispatch(setSelectItem(item));
    dispatch(OpenDeleteModal(true));
  };
  const handleEdit = (item) => {
    dispatch(setSelectItem(item));
    // navigate(`/edit-job/${item._id}`);
  };

  //===================get all data api=============
  const { isFetching, data, isLoading } = useQuery({
    queryKey: ["dataCareer", filter],
    queryFn: () => fetchCareerData(filter, token, handleLogout),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
  });

  const res = data?.data?.data.result;
  //============api delete ============
  const { mutate: deleteMutate } = useMutation(deleteCareer, {
    onSuccess: (data) => {
      toast.success("Job is deleted successfully.");
      queryClient.refetchQueries("dataCareer");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });
  const handleDelete = () => {
    deleteMutate({ id: selectedItem._id });
    dispatch(OpenDeleteModal(false));
  };

  const handlePageChange = (event, value) => {
    setFilter({ ...filter, page: 2 });
    navigate(`/jobs?page=${value}`);
  };

  React.useEffect(() => {
    const { page = 1 } = queryString.parse(location?.search);
    page && setFilter({ ...filter, ...drawerfilter, page: parseInt(page) });
    const start = (parseInt(page) - 1) * 10 + 1;
    setRecordStart(start);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, drawerfilter]);
  const handleViewClick = (item) => {
    dispatch(setSelectItem(item));
    // navigate(`/jobs/${item._id}`);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { formik, resetForm, resetSpecificValue } = useCareerFilterForm(
    setdrawerfilter,
    handleDrawerClose
  );

  const Redirectlink = (item) => {
    queryParams.set("id", item?._id);
    // const updatedUrl = `/applicant?${queryParams.toString()}`;

    // navigate(updatedUrl, { replace: true });
  };
  const filteredValues = Object.keys(drawerfilter).reduce((acc, key) => {
    if (drawerfilter[key] !== "" && drawerfilter[key] !== null) {
      acc[key] = drawerfilter[key];
    }
    return acc;
  }, {});

  return (
    <>
      <Card>
        <Box sx={wrap}>
          <Box sx={mainBox}>
            <Box
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                flexWrap: "wrap",
                minHeight: "45px",
              }}
            >
              <Typography variant="h1">Jobs List</Typography>
              <FilterChips
                filterValues={filteredValues}
                drawerFilter={drawerfilter}
                resetSpecificValue={resetSpecificValue}
                convertLabel={convertJobTypeLabel}
              />
            </Box>

            <Box sx={containerd}>
              <>
                <Button
                  variant="contained"
                  sx={addButtonStyle}
                  onClick={() => navigate("/add-job")}
                  data-cy="add-Employee"
                >
                  + Add jobs
                </Button>
              </>
              <Box sx={drawercareerbtn} onClick={handleDrawerOpen}>
                <IconButton>
                  <img src={filterIcon} alt="filterImage" />
                </IconButton>
              </Box>
              <SidebarDrawer
                open={open}
                handleDrawerClose={handleDrawerClose}
                setdrawerfilter={setdrawerfilter}
                formik={formik}
                resetForm={resetForm}
                filter={filter}
                setFilter={setFilter}
              />
            </Box>
          </Box>
          <Box sx={tableBox}>
            <TableContainer>
              <Table hover="true" responsive="true">
                <TableHead>
                  <TableHeadComponent />
                </TableHead>
                <TableBody>
                  {isLoading ||
                  (isFetching && Object.keys(filteredValues).length > 0) ? (
                    <TableLoader colSpan={11} height="60vh" />
                  ) : !res?.length ? (
                    <TableNoData colSpan={11} height="60vh" />
                  ) : (
                    res?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left" sx={{ width: "50px" }}>
                          <Typography variant="h6">
                            {recordStart + index}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <CustomTooltip
                              title={capitalizeFirstLetter(item?.title)}
                            >
                              <Typography variant="h6" sx={ellpsisBox("180px")}>
                                {item?.title}
                              </Typography>
                            </CustomTooltip>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box sx={{ marginLeft: "34px" }}>
                            <Box sx={generateCircleStyle(item?.positions)}>
                              {item?.positions}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box sx={{ marginLeft: "34px" }}>
                            <Box sx={generateCircleStyle(item?.applications)}>
                              {item?.applications}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            // width: "240px",
                            textTransform: "capitalize",
                          }}
                        >
                          <Typography variant="h6">
                            {item?.department === "sqa"
                              ? "SQA"
                              : item?.department}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            // width: "180px",
                            textTransform: "capitalize",
                          }}
                        >
                          <Typography variant="h6">
                            {item.designation}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h6">
                            {dayjs(new Date(item.postDate)).format(
                              "DD MMM YYYY"
                            )}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h6">
                            {dayjs(new Date(item.dueDate)).format(
                              "DD MMM YYYY"
                            )}{" "}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="h6"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {formatJobType(item.jobType)}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            variant="h6"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {formatJobType(item.jobStatus)}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <ActionButtons
                            handleViewClick={handleViewClick}
                            handleEdit={handleEdit}
                            handleDelete={handleOpen}
                            Redirectlink={Redirectlink}
                            item={item}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <ReusablePagination
          count={data?.data?.data?.page_total}
          page={data?.data?.data?.page_number}
          handlePageChange={handlePageChange}
        />
      </Card>
      <CareerDeleteModal handleDelete={handleDelete} />
    </>
  );
};
export default CareerTable;

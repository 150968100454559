// SidebarDrawer.jsx

import React from "react";
import {
  Drawer,
  Box,
  Stack,
  Typography,
  Divider,
  Button,
  CircularProgress,
  // IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CustomSelect from "./Customselect";
import SingleDatePicker from "../DateFilter";
import dayjs from "dayjs";
import { fetchApplicantresume } from "../../api/JobApplication";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { Designation, JobType, options } from "../../pages/career/reusablefield/FormOption";
const addButtonStyle = () => ({
  maxWidth: "135px",

  fontWeight: "normal",
  "@media (max-width: 570px)": {
    mt: 1.5,
    ml: 0,
    // maxWidth: "100%",
  },
});
const JobapplicationDrawer = ({
  open,
  handleDrawerClose,
  formik,
  resetForm,
  filter,
  setFilter,
}) => {
  const queryClient = useQueryClient();
  

  const handleClearEndDate = (e) => {
    // Clear the end date here
    e.stopPropagation();
    formik.setFieldValue("applyDateTo", null);
  };
  const handleClearStartDate = (e) => {
    e.stopPropagation();
    formik.setFieldValue("applyDateFrom", null);
  };

  const [startDateOpen, setStartDateOpen] = React.useState(false);
  const [EndDateOpen, setEndDateOpen] = React.useState(false);
  const downloadFiles = async (files) => {
    const fileUrl = `${files}`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "JobApplications.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { isLoading, mutate: ResumeMutate } = useMutation(
    fetchApplicantresume,
    {
      onSuccess: (data) => {
        // toast.success("Employee deleted successfully.");
        queryClient.refetchQueries("Resumedata");

        const resumes = data?.data?.data;
       
        if (resumes) {
          downloadFiles(resumes);
        } else {
          toast.error("No file available against the applied filter");
        }
      },
      onError: (error) => {},
    }
  );
  const handleResume = () => {
    const filteredValues = Object.keys(formik.values).reduce((acc, key) => {
      if (formik.values[key] !== "" && formik.values[key] !== null) {
        acc[key] = formik.values[key];
      }
      return acc;
    }, {});

    // setdrawerfilter(filteredValues);
    // If there are any non-empty and non-null values, update state and handle form submission
    if (Boolean(filter?.jobId)) {
      ResumeMutate(filter);
    }
    if (filter?.name?.trim().length > 2) {
      ResumeMutate(filter);
    }
    if (
      !Boolean(filter?.name?.trim().length > 2) &&
      Object.keys(filteredValues).length > 0
    ) {
      ResumeMutate(formik.values);
    } else if (!Boolean(filter?.name?.trim().length > 2)) {
      ResumeMutate();
    }
  };

  return (
    
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleDrawerClose}
        sx={{ boxShadow: "-5px 5px 14px 0px #0000001A" }}
        BackdropProps={{
          sx: {
            backgroundColor: "transparent", // Set the background color to transparent
          },
        }}
      >
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ width: { xs: "300px", md: "320px" }, paddingBlock: "18px" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingX: { xs: "10px", md: "15px" } }}
          >
            <Typography variant="h1">Filters jobs Application</Typography>
            <ClearIcon
              color="#0575E6"
              sx={{
                fill: "#0575E6 !important",
                fontSize: "28px",
                cursor: "pointer",
              }}
              onClick={handleDrawerClose}
            />
          </Stack>
          <Divider sx={{ paddingY: "8px" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: { xs: "10px", md: "15px" },
              gap: "12px",
              marginTop: "10px",
            }}
          >
            <CustomSelect
              fieldProps={{
                ...formik.getFieldProps("department"),
              }}
              options={options}
              formikvalue={formik.values.department}
              label="Select Department"
            />
            <CustomSelect
              fieldProps={{
                ...formik.getFieldProps("jobType"),
              }}
              options={JobType}
              formikvalue={formik.values.jobType}
              label="Job Type"
            />
            <CustomSelect
              fieldProps={{
                ...formik.getFieldProps("designation"),
              }}
              options={Designation}
              formikvalue={formik.values.designation}
              label="Select Designation"
            />
            <Typography variant="h3">Apply Date </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <SingleDatePicker
                title=""
                disablefuture={true}
                open={startDateOpen}
                setOpen={setStartDateOpen}
                handleClear={handleClearStartDate}
                date={formik.values.applyDateFrom}
                handleChange={(newValue) => {
                  let formattedValue;

                  if (newValue) {
                    // If it's AM, format the time in 12-hour format
                    formattedValue = dayjs(newValue)
                      .format("YYYY-MM-DD")
                      .trim();

                    // Check if the hour is 12 and change it to 00
                  }

                  formik.setFieldValue("applyDateFrom", formattedValue);
                }}
                placeholder="From Date"
                id="applyDateFrom"
              />
              <SingleDatePicker
                title=""
                disablefuture={true}
                open={EndDateOpen}
                setOpen={setEndDateOpen}
                handleClear={handleClearEndDate}
                date={formik.values.applyDateTo}
                handleChange={(newValue) => {
                  let formattedValue;

                  if (newValue) {
                    // If it's AM, format the time in 12-hour format
                    formattedValue = dayjs(newValue)
                      .format("YYYY-MM-DD")
                      .trim();

                    // Check if the hour is 12 and change it to 00
                  }

                  formik.setFieldValue("applyDateTo", formattedValue);
                }}
                placeholder="To Date"
                id="applyDateTo"
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                sx={addButtonStyle}
                onClick={() => handleResume()}
                data-cy="add-Employee"
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "#fff" }} size={20} />
                ) : (
                  "Export all"
                )}
              </Button>

              <Button sx={addButtonStyle} variant="contained" type="submit">
                Search
              </Button>
            </Box>

            <Button
              variant="outlined"
              onClick={() => {
                resetForm();
              }}
              sx={{ maxWidth: "100% !important" }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Drawer>
  );
};

export default JobapplicationDrawer;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TeamSection from "../pages/teamSection/TeamSection";
import MainLayout from "../layout";
import Contact from "../pages/contact/Contact";
import AddEmployee from "../pages/teamSection/component/addEmployee/AddEmployee";
import NotFound from "../pages/notFound/NotFound";
import LogIN from "../pages/signIn/LogIN";
import GoogleTwoFa from "../pages/signIn/GoogleTwoFa";
import Setting from "../pages/setting";
import Services from "../pages/services/Services";
import ExpertiseData from "../pages/expertise/ExpertiseData";
import AddServices from "../pages/services/component/AddServices";
import SubServices from "../pages/services/component/SubServices";
import AddExpertise from "../pages/expertise/component/AddExpertise";
import View from "../pages/teamSection/component/View";
import MessageList from "../pages/contact/component/MessageList";
import EditEmployee from "../pages/teamSection/component/editEmployee/EditEmployee";
import EditExpertise from "../pages/expertise/component/EditExpertise";
import EditServices from "../pages/services/component/EditServices";
import EditSubServices from "../pages/services/component/EditSubServices";
import PrivateRoutes from "../helper/PrivateRoutes";
import PublicRoute from "../helper/PublicRoute";
import CareerSection from "../pages/career/CareerSection";
import ViewCareer from "../pages/career/component/ViewCareer";
import EditEmployeeCareer from "../pages/career/component/editEmployee/EditEmployeeCareer";
import JobApplicationSection from "../pages/jobApplications/JobApplicationSection";
import ViewJobApplication from "../pages/jobApplications/component/ViewJobApplication";
import AddJob from "../pages/career/component/addJob/AddJob";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ServicePage from "../pages/servicePage/ServicePage";
import AddBanner from "../pages/servicePage/component/banner/component/addBanner/AddBanner";
import AddToken from "../pages/servicePage/component/token/component/addToken/AddToken";
import Product from "../pages/servicePage/component/product/Product";
import GetStarted from "../pages/servicePage/component/getStarted/GetStarted";
import Professionals from "../pages/servicePage/component/professionals/Professionals";
import AddProfessionals from "../pages/servicePage/component/professionals/component/AddProfessionals";
import AllPages from "../pages/servicePage/component/allPages/AllPages";
import Preview from "../pages/preview/Preview";

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route
            path="/"
            element={
              <MainLayout>
                <TeamSection />
              </MainLayout>
            }
          />
          <Route
            path="/team"
            element={
              <MainLayout>
                <TeamSection />
              </MainLayout>
            }
          />
          <Route
            path="/services"
            element={
              <MainLayout>
                <Services />
              </MainLayout>
            }
          />
          <Route
            path="/expertise"
            element={
              <MainLayout>
                <ExpertiseData />
              </MainLayout>
            }
          />
          <Route
            path="/add-expertise"
            element={
              <MainLayout>
                <AddExpertise />
              </MainLayout>
            }
          />
          <Route
            path="/edit-expertise/:id"
            element={
              <MainLayout>
                <EditExpertise />
              </MainLayout>
            }
          />
          <Route
            path="/contact"
            element={
              <MainLayout>
                <Contact />
              </MainLayout>
            }
          />
          <Route
            path="/contact/:id"
            element={
              <MainLayout>
                <MessageList />
              </MainLayout>
            }
          />
          <Route
            path="/add-employee"
            element={
              <MainLayout>
                <AddEmployee />
              </MainLayout>
            }
          />
          <Route
            path="/add-job"
            element={
              <MainLayout>
                <AddJob />
              </MainLayout>
            }
          />
          <Route
            path="/edit-employee/:id"
            element={
              <MainLayout>
                <EditEmployee />
              </MainLayout>
            }
          />
          <Route
            path="/edit-job/:id"
            element={
              <MainLayout>
                <EditEmployeeCareer />
              </MainLayout>
            }
          />
          <Route
            path="/add-services"
            element={
              <MainLayout>
                <AddServices />
              </MainLayout>
            }
          />
          <Route
            path="/edit-services/:id"
            element={
              <MainLayout>
                <EditServices />
              </MainLayout>
            }
          />
          <Route
            path="/sub-services"
            element={
              <MainLayout>
                <SubServices />
              </MainLayout>
            }
          />
          <Route
            path="/edit-sub-services/:id"
            element={
              <MainLayout>
                <EditSubServices />
              </MainLayout>
            }
          />
          <Route
            path="/settings"
            element={
              <MainLayout>
                <Setting />
              </MainLayout>
            }
          />
          <Route
            path="/team/:id"
            element={
              <MainLayout>
                <View />
              </MainLayout>
            }
          />
          <Route
            path="/jobs/:id"
            element={
              <MainLayout>
                <ViewCareer />
              </MainLayout>
            }
          />
          <Route
            path="/jobs"
            element={
              <MainLayout>
                <CareerSection />
              </MainLayout>
            }
          />
          <Route
            path="/applicant"
            element={
              <MainLayout>
                <JobApplicationSection />
              </MainLayout>
            }
          />

          <Route
            path="/applicant/:id"
            element={
              <MainLayout>
                <ViewJobApplication />
              </MainLayout>
            }
          />

          <Route
            path="/campaign-ads"
            element={
              <MainLayout>
                <AllPages />
              </MainLayout>
            }
          />
          <Route
            path="/add-campaign-ads"
            element={
              <MainLayout>
                <ServicePage />
              </MainLayout>
            }
          />
          <Route
            path="/edit-campaign-ads/:name"
            element={
              <MainLayout>
                <ServicePage />
              </MainLayout>
            }
          />
          <Route
            path="/add-banner"
            element={
              <MainLayout>
                <AddBanner />
              </MainLayout>
            }
          />
          <Route
            path="/edit-banner"
            element={
              <MainLayout>
                <AddBanner />
              </MainLayout>
            }
          />
          <Route
            path="/add-token"
            element={
              <MainLayout>
                <AddToken />
              </MainLayout>
            }
          />
          <Route
            path="/edit-token"
            element={
              <MainLayout>
                <AddToken />
              </MainLayout>
            }
          />
          <Route
            path="/add-product"
            element={
              <MainLayout>
                <Product />
              </MainLayout>
            }
          />
          <Route
            path="/edit-product"
            element={
              <MainLayout>
                <Product />
              </MainLayout>
            }
          />
          <Route
            path="/add-getStarted"
            element={
              <MainLayout>
                <GetStarted />
              </MainLayout>
            }
          />
          <Route
            path="/edit-getStarted"
            element={
              <MainLayout>
                <GetStarted />
              </MainLayout>
            }
          />
          <Route
            path="/professionals"
            element={
              <MainLayout>
                <Professionals />
              </MainLayout>
            }
          />
          <Route
            path="/add-professionals"
            element={
              <MainLayout>
                <AddProfessionals />
              </MainLayout>
            }
          />
          <Route
            path="/edit-professionals"
            element={
              <MainLayout>
                <AddProfessionals />
              </MainLayout>
            }
          />
        </Route>
        <Route element={<PublicRoute />}>
          <Route path="/preview" element={<Preview />} />
          <Route
            path="/sign-in"
            element={
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHAY_KEY}
              >
                <LogIN />
              </GoogleReCaptchaProvider>
            }
          />
          <Route path="/two-fa" element={<GoogleTwoFa />} />
        </Route>
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;

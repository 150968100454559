import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { uploadImage } from "../../../../api/Career";
import {
  setAddTags,
  setGetStarted,
  setProductPage,
  setTokenPage,
} from "../../../../redux/slices/servicePageSlice";
import Card from "../../../../components/card";
import Heading from "../../../../components/heading/Heading";
import { addEmployeeData } from "../../../../api/Employee";
import {
  addGetStartedSchema,
  addProductValidationSchema,
} from "../../../../utils/Schema";
import ImgBox from "../../../../components/imgBox/ImgBox";
import AddTags from "../token/component/addTags/AddTags";
import AddNewProducts from "../product/component/AddNewProducts";
import AddList from "./component/AddList";
import CloseIcon from "@mui/icons-material/Close";
import ColorField from "../../../../components/colorField/ColorField";
import { plus } from "../../../../assets/images";
import { CustomTooltip } from "../../../../components/customTooltip";

//=============style=================

const main = { p: "20px" };

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};

const root = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const dispBox = {
  display: "flex",
  flexWrap: "wrap",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const clButton = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

//=============component=================

const GetStarted = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [inputKey, setInputKey] = useState(0);
  const { getStarted } = useSelector((state) => state.page);
  const [newTag, setNewTag] = useState("");

  const initialValues = {
    title: "",
    titleColor: "",
    description: "",
    descColor: "",
    bgColor: "",
    bgIcon: "",
    formBgColor: "",
    inputTextColor: "",
    inputBgColor: "",
    btnBgColor: "",
    btnTextColor: "",
    bulletColor: "",
    bulletBgColor: "",
    list: [],
  };

  //=======formick====

  const formik = useFormik({
    initialValues,
    validationSchema: addGetStartedSchema,
    onSubmit: (values) => {
      const formData = {
        title: values.title,
        titleColor: values.titleColor,
        description: values.description,
        descColor: values.descColor,
        bgColor: values.bgColor,
        bgIcon: values.bgIcon,
        formBgColor: values.formBgColor,
        inputTextColor: values.inputTextColor,
        inputBgColor: values.inputBgColor,
        btnBgColor: values.btnBgColor,
        btnTextColor: values.btnTextColor,
        bulletColor: values.bulletColor,
        bulletBgColor: values.bulletBgColor,
        list: values.list,
      };

      dispatch(setGetStarted(formData));
      navigate(-1);
    },
  });

  const handleImageChange = async (event, image) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const imageUrl = await uploadImage(file);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
        setInputKey(inputKey + 1);
      }
    } catch (error) {}
  };

  const handleDrop = async (event, image) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append(image, selectedImage);
    // setSelectedFileLoading(true);
    try {
      const imageUrl = await uploadImage(selectedImage);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
      }
    } catch (error) {}
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleAddList = () => {
    if (newTag.trim() !== "") {
      setFieldValue("list", [...values.list, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleDelete = (index) => {
    const updatedTags = [...values.list];
    updatedTags.splice(index, 1);
    setFieldValue("list", updatedTags);
    // const updatedTags = values?.tags?.filter((item, i) => i !== index);
    // setFieldValue("list", updatedTags);
  };

  useEffect(() => {
    // dispatch(setAddTags([]));
    if (
      location.pathname === "/edit-getStarted" &&
      getStarted &&
      Object.keys(getStarted).length > 0
    ) {
      setFieldValue("title", getStarted?.title);
      setFieldValue("titleColor", getStarted?.titleColor);
      setFieldValue("description", getStarted?.description);
      setFieldValue("descColor", getStarted?.descColor);
      setFieldValue("bgColor", getStarted?.bgColor);
      setFieldValue("bgIcon", getStarted?.bgIcon);
      setFieldValue("formBgColor", getStarted?.formBgColor);
      setFieldValue("inputTextColor", getStarted?.inputTextColor);
      setFieldValue("inputBgColor", getStarted?.inputBgColor);
      setFieldValue("btnBgColor", getStarted?.btnBgColor);
      setFieldValue("btnTextColor", getStarted?.btnTextColor);
      setFieldValue("bulletColor", getStarted?.bulletColor);
      setFieldValue("bulletBgColor", getStarted?.bulletBgColor);
      setFieldValue("list", getStarted?.list);
    }
  }, []);

  const handlePreview = () => {
    const formData = {
      title: values.title,
      titleColor: values.titleColor,
      description: values.description,
      descColor: values.descColor,
      bgColor: values.bgColor,
      bgIcon: values.bgIcon,
      formBgColor: values.formBgColor,
      inputTextColor: values.inputTextColor,
      inputBgColor: values.inputBgColor,
      btnBgColor: values.btnBgColor,
      btnTextColor: values.btnTextColor,
      bulletColor: values.bulletColor,
      bulletBgColor: values.bulletBgColor,
      list: values.list,
    };

    dispatch(setGetStarted(formData));
  };
  const handleBlur = () => {
    handlePreview(); // Call handleSubmit onBlur
  };

  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  useEffect(() => {
    handleBlur();
  }, [values.bgIcon]);
  return (
    <Card>
      <Box sx={main}>
        <form onSubmit={handleSubmit}>
          <Heading
            text={
              location.pathname === "/add-getStarted"
                ? "Add GetStarted"
                : "Update GetStarted"
            }
          />

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Get Started Section Title"}>
                <Typography>Title*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Background Color for Get Started Section"}
              >
                <Typography>Background Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="bgColor"
                name="bgColor"
                type="color"
                value={values.bgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.bgColor && errors.bgColor)}
                helperText={touched.bgColor && errors.bgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Background Icon for Get Started Section"}
              >
                <Typography>Background Icon*</Typography>
              </CustomTooltip>
            </Box>
            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              onBlur={handleBlur}
              fieldName="bgIcon" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Form Background Color for Get Started Section"}
              >
                <Typography>Form Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="formBgColor"
                name="formBgColor"
                value={values.formBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.formBgColor && errors.formBgColor)}
                helperText={touched.formBgColor && errors.formBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Input Text Color for Get Started Section"}
              >
                <Typography>Input Text*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="inputTextColor"
                name="inputTextColor"
                type="color"
                value={values.inputTextColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.inputTextColor && errors.inputTextColor)}
                helperText={touched.inputTextColor && errors.inputTextColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Input Background Color for Get Started Section"}
              >
                <Typography>Input Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="inputBgColor"
                name="inputBgColor"
                type="color"
                value={values.inputBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.inputBgColor && errors.inputBgColor)}
                helperText={touched.inputBgColor && errors.inputBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Button Background Color for Get Started Section"}
              >
                <Typography>Button Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="btnBgColor"
                type="color"
                name="btnBgColor"
                value={values.btnBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.btnBgColor && errors.btnBgColor)}
                helperText={touched.btnBgColor && errors.btnBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Button Text Color for Get Started Section"}
              >
                <Typography>Button Text*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="btnTextColor"
                type="color"
                name="btnTextColor"
                value={values.btnTextColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.btnTextColor && errors.btnTextColor)}
                helperText={touched.btnTextColor && errors.btnTextColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Bullet Color for Get Started Section"}
              >
                <Typography>Bullet Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="bulletColor"
                type="color"
                name="bulletColor"
                value={values.bulletColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.bulletColor && errors.bulletColor)}
                helperText={touched.bulletColor && errors.bulletColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Bullet Background Color for Get Started Section"}
              >
                <Typography>Bullet Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="bulletBgColor"
                type="color"
                name="bulletBgColor"
                value={values.bulletBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.bulletBgColor && errors.bulletBgColor)}
                helperText={touched.bulletBgColor && errors.bulletBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Title Color for Get Started Section"}
              >
                <Typography>Title Color* </Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="titleColor"
                name="titleColor"
                type="color"
                value={values.titleColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.titleColor && errors.titleColor)}
                helperText={touched.titleColor && errors.titleColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Description Color for Get Started Section"}
              >
                <Typography>Description Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="Description Color"
                name="descColor"
                type="color"
                value={values.descColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.descColor && errors.descColor)}
                helperText={touched.descColor && errors.descColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={dispBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Enter Description for Get Started Section"}
              >
                <Typography>Description*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                name="description"
                minRows={4}
                maxRows={4}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Add Items to Your List"}>
                <Typography>List*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <Box>
                <TextField
                  type="text"
                  placeholder="Add List"
                  fullWidth
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  onBlur={handleBlur}
                  error={Boolean(touched.list && errors.list)}
                  helperText={touched.list && errors.list}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={handleAddList}
                        sx={{ background: "#0575E6" }}
                      >
                        <Box sx={{ width: "20px", height: "20px" }}>
                          <img
                            src={plus}
                            alt="Color Picker"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Button
                  variant="contained"
                  onClick={handleAddList}
                  sx={{ my: 2, maxWidth: "100%" }}
                >
                  add
                </Button> */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    margin: "10px 0px",
                  }}
                >
                  {values?.list?.map((itm, i) => (
                    <Box
                      key={i}
                      sx={{
                        p: "5px 20px",
                        borderRadius: "30px",
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        border: "1px solid #000",
                      }}
                    >
                      <Typography sx={{ wordBreak: "break-all" }}>
                        {itm}
                      </Typography>

                      <CloseIcon
                        fontSize="20px"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleDelete(i)}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Add a Button for submitting the data */}
          <Box sx={clButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              cancel
            </Button>

            <Link to="/preview" onClick={handlePreview} target="_blank">
              <Button variant="contained" sx={{ maxWidth: "90px" }}>
                preview
              </Button>
            </Link>
            <Button variant="contained" type="submit" sx={{ maxWidth: "90px" }}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default GetStarted;

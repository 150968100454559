import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation, useQueryClient } from "react-query";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { uploadImage } from "../../../../../api/Career";
import Card from "../../../../../components/card";
import Heading from "../../../../../components/heading/Heading";
import ImgBox from "../../../../../components/imgBox/ImgBox";
import { setProfessional } from "../../../../../redux/slices/servicePageSlice";
import {
  addBannerValidationSchema,
  addProfessionalSchema,
} from "../../../../../utils/Schema";
import ColorField from "../../../../../components/colorField/ColorField";
import { CustomTooltip } from "../../../../../components/customTooltip";

//=============style=================

const main = { p: "20px" };

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};
const genderBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "50px",
  gap: 1,
};
const root = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const selectPlaceholder = {
  color: "#666666",
  fontFamily: "Poppins-Regular",
  fontSize: "12px",
  textAlign: "left",
  opacity: 0.8,
};

const dispBox = {
  display: "flex",
  flexWrap: "wrap",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const clButton = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

//=============component=================

const AddProfessionals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [inputKey, setInputKey] = useState(0);
  const { professional } = useSelector((state) => state.page);
  const initialValues = {
    name: "",
    nameColor: "",
    description: "",
    descColor: "",
    designation: "",
    desiColor: "",
    linkedInUrl: "",
    linkedInIcon: "",
    image: "",
    sliderBgColor: "",
    bgColor: "",
  };

  const handleImageChange = async (event, image) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const imageUrl = await uploadImage(file);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
        setInputKey(inputKey + 1);
      }
    } catch (error) {}
  };

  const handleDrop = async (event, image) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append(image, selectedImage);
    // setSelectedFileLoading(true);
    try {
      const imageUrl = await uploadImage(selectedImage);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
      }
    } catch (error) {}
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  //=======formick====

  const formik = useFormik({
    initialValues,
    validationSchema: addProfessionalSchema,
    onSubmit: (values) => {
      const formData = {
        name: values.name,
        designation: values.designation,
        desiColor: values.desiColor,
        image: values.image,
        nameColor: values.nameColor,
        descColor: values.descColor,
        linkedInIcon: values.linkedInIcon,
        linkedInUrl: values.linkedInUrl,
        sliderBgColor: values.sliderBgColor,
        bgColor: values.bgColor,
        description: values.description,
      };

      if (
        location.pathname === "/edit-professionals" &&
        location?.state?.item
      ) {
        const editedIndex = professional.findIndex(
          (item, i) => i === location.state.item.index
        );
        const newProfessional = [...professional];
        if (editedIndex !== -1) {
          newProfessional[editedIndex] = formData;
          dispatch(setProfessional(newProfessional));
          navigate(-1);
        }
      } else {
        // const existingIndex = professional.findIndex(
        //   (item) => item.name === values.name
        // );
        // if (existingIndex) {
          navigate(-1);
        //   console.log("addffff")
        // } else {
        //   dispatch(setProfessional([...professional, formData]));
        //   navigate(-1);
        //   console.log(values.name,"addllllllll",existingIndex)
        // }
      }

      //   dispatch(setProfessional([...professional, formData]));
    },
  });

  useEffect(() => {
    if (location.pathname === "/edit-professionals" && location?.state?.item) {
      setFieldValue("name", location?.state?.item?.itemData?.name);
      setFieldValue(
        "designation",
        location?.state?.item?.itemData?.designation
      );
      setFieldValue("image", location?.state?.item?.itemData?.image);
      setFieldValue(
        "linkedInIcon",
        location?.state?.item?.itemData?.linkedInIcon
      );
      setFieldValue("nameColor", location?.state?.item?.itemData?.nameColor);
      setFieldValue("descColor", location?.state?.item?.itemData?.descColor);
      setFieldValue("desiColor", location?.state?.item?.itemData?.desiColor);
      setFieldValue(
        "linkedInUrl",
        location?.state?.item?.itemData?.linkedInUrl
      );
      setFieldValue(
        "sliderBgColor",
        location?.state?.item?.itemData?.sliderBgColor
      );
      setFieldValue("bgColor", location?.state?.item?.itemData?.bgColor);
      setFieldValue(
        "description",
        location?.state?.item?.itemData?.description
      );
    }
  }, []);

  const handlePreview = () => {
    const formData = {
      name: values.name,
      designation: values.designation,
      desiColor: values.desiColor,
      image: values.image,
      nameColor: values.nameColor,
      descColor: values.descColor,
      linkedInIcon: values.linkedInIcon,
      linkedInUrl: values.linkedInUrl,
      sliderBgColor: values.sliderBgColor,
      bgColor: values.bgColor,
      description: values.description,
    };

    // Check if editing an existing item
    if (location.pathname === "/edit-professionals" && location?.state?.item) {
      const editedIndex = professional.findIndex(
        (item) => item.name === location.state.item.name
      );
      if (editedIndex !== -1) {
        const newProfessional = [...professional];
        newProfessional[editedIndex] = formData;
        dispatch(setProfessional(newProfessional));
      }
    } else if (values.name.trim() !== "") {
      // Check if the name is not empty
      const existingIndex = professional.findIndex(
        (item) => item.name === values.name
      );
      if (existingIndex !== -1) {
        // If the item with the same name already exists, update it
        const newProfessional = [...professional];
        newProfessional[existingIndex] = formData;
        dispatch(setProfessional(newProfessional));
      } else {
        // If it's a new item, add it to the list
        dispatch(setProfessional([...professional, formData]));
      }
    }
  };

  const handleBlur = () => {
    // This function will trigger the preview update when a field is blurred
    handlePreview();
  };

  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  useEffect(() => {
    handleBlur();
  }, [values.image, values.linkedInIcon]);

  return (
    <Card>
      <Box sx={main}>
        <form onSubmit={handleSubmit}>
          <Heading
            text={
              location.pathname === "/add-professionals"
                ? "Add Professional"
                : "Update Professional"
            }
          />

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Professionals Section Name"}>
                <Typography>Name*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Professionals Section Designation"}>
                <Typography>Designation*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Designation"
                name="designation"
                value={values.designation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.designation && errors.designation)}
                helperText={touched.designation && errors.designation}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Description Color for Professionals Section"}
              >
                <Typography>Description Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="desiColor"
                name="desiColor"
                type="color"
                value={values.desiColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.desiColor && errors.desiColor)}
                helperText={touched.desiColor && errors.desiColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={root}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Image for Professionals Section"}>
                <Typography>Image</Typography>
              </CustomTooltip>
            </Box>

            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fieldName="image" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Name Color for Professionals Section"}
              >
                <Typography>Name Color* </Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="nameColor"
                name="nameColor"
                type="color"
                value={values.nameColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.nameColor && errors.nameColor)}
                helperText={touched.nameColor && errors.nameColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Description Color for Professionals Section"}
              >
                <Typography>Description Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="Description Color"
                name="descColor"
                type="color"
                value={values.descColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.descColor && errors.descColor)}
                helperText={touched.descColor && errors.descColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select LinkedIn Icon for Professionals Section"}
              >
                <Typography>LinkedIn Icon*</Typography>
              </CustomTooltip>
            </Box>
            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              onBlur={handleBlur}
              fieldName="linkedInIcon" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              <CustomTooltip
                title={"Enter LinkedIn URL for Professionals Section"}
              >
                <Typography>LinkedIn URL*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                name="linkedInUrl"
                value={values.linkedInUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="linkedIn Url"
                error={Boolean(touched.linkedInUrl && errors.linkedInUrl)}
                helperText={touched.linkedInUrl && errors.linkedInUrl}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Background Color for Professionals Section"}
              >
                <Typography>Background Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="bgColor"
                name="bgColor"
                value={values.bgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.bgColor && errors.bgColor)}
                helperText={touched.bgColor && errors.bgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Background Color for Slider Section"}
              >
                <Typography>Slider Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="sliderBgColor"
                name="sliderBgColor"
                value={values.sliderBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.sliderBgColor && errors.sliderBgColor)}
                helperText={touched.sliderBgColor && errors.sliderBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={dispBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Enter Description Professionals Section"}>
                <Typography>Description*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                name="description"
                minRows={4}
                maxRows={4}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Box>

          {/* Add a Button for submitting the data */}
          <Box sx={clButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              cancel
            </Button>
            <Link to="/preview" onClick={handlePreview} target="_blank">
              <Button variant="contained" sx={{ maxWidth: "90px" }}>
                preview
              </Button>
            </Link>
            <Button variant="contained" type="submit" sx={{ maxWidth: "90px" }}>
              submit
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default AddProfessionals;

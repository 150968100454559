import React, { useState } from "react";
import { Select, MenuItem, InputLabel, FormControl, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomSelect = ({
  fieldProps,
  options,
  label,

  formikvalue,
  ...props
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const customStyles = {
    padding: "0px !important",
    backgroundColor: "rgba(233, 236, 239, 1)",
    color: "#666666",
    fontFamily: "Poppins-Regular",
    fontSize: "12px",
    height: "44px",
    border: "none !important",
    width: "100%",
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": {
      border: 0,
      borderWidth: "0px",
      borderRadius: "6px",
    },
    "&.MuiOutlinedInput-root:hover ": {
      border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused ": {
      border: 0,
    },
  };

  return (
    <FormControl>
      <InputLabel
        shrink={Boolean(formikvalue)}
        sx={{
          display: Boolean(formikvalue) ? "none" : "inline-block",
          mt: "-4px",
          color: "#666666",
          fontFamily: "Poppins-Regular",
          fontSize: "12px",
        }}
      >
        {label}
      </InputLabel>
      <Select
        {...fieldProps}
        sx={customStyles}
        IconComponent={() => (
          <Box sx={{ marginRight: "10px" }}>
            <ExpandMoreIcon
              sx={{ fill: "#666666", color: "#666666", cursor: "pointer" }}
              onClick={() => setIsSelectOpen(!isSelectOpen)}
            />
          </Box>
        )}
        open={isSelectOpen}
        onClose={() => setIsSelectOpen(false)}
        onOpen={() => setIsSelectOpen(true)}
        {...props}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "rgba(233, 236, 239, 1) !important", // Set your background color
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            sx={{
              color: "#666666",
              fontFamily: "Poppins-Regular",
              fontSize: "12px",
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default CustomSelect;

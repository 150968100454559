import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  deleteTableIcon,
  editTableIcon,
  plus,
} from "../../../assets/images";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  deleteServices,
  deleteSubServices,
  fetchDataServices,
} from "../../../api/Services";
import Loader from "../../../components/loader/Loader";
import NoData from "../../../components/noData/NoData";
import DeleteModal from "../../../components/modals/DeleteModal";
import {
  OpenDeleteModal,
  setSelectedTab,
} from "../../../redux/slices/adminSlice";
import { setSelectItem } from "../../../redux/slices/teamSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";

//============style=============

const icons = {
  color: "blue",
  padding: "6px",
  fontSize: "1rem",
};
const tabsStyle = {
  position: "relative",
  minHeight: { xs: "60px", md: "80vh" },
  height: "90%",
  backgroundColor: "#fff",
  borderBottom: { xs: "1px solid #DDDDDD", md: 0 },
  pt: "10px",
  "& .MuiTabs-indicator": {
    backgroundColor: "#4899fd",
    right: 0,
    mt: 2.5,
    ml: 10,
    width: "0px ",
    height: "0px ",
    borderRadius: "50%",
  },
  "& .MuiTab-root": {
    opacity: 1,
    padding: "5px 16px",
    "&.Mui-selected": {
      backgroundColor: "#0575e62e",
      minWidth: { xs: "290px", md: "100%" },
      padding: "5px 16px",
    },
  },
  "& .MuiTabScrollButton-root": {
    display: { xs: "inline-flex", md: "none" },
  },
  "& .MuiButtonBase-root-MuiTab-root": {
    padding: "5px 10px",
  },
};

const singleTab = {
  display: "inline-flex",
  alignItems: "center",
  position: "relative",
  height: "40px",
  color: "#202c40",
  width: "100%",
  justifyContent: "space-between",
};

//============component=============

const ServicesList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { selectedItem } = useSelector((state) => state.team);
  const { selectedTab } = useSelector((state) => state.admin);
  const { token } = useSelector((state) => state.login);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (_, newValue) => {
    dispatch(setSelectedTab(newValue));
  };

  //===================get all data api=============

  const { data, isLoading } = useQuery({
    queryKey: ["dataser"],
    queryFn: () => fetchDataServices(),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
  });

  const res = data?.data?.data;

  //============api delet ============

  const { mutate: deleteMutate } = useMutation(deleteServices, {
    onSuccess: (data) => {
      toast.success("Service deleted successfully.");
      queryClient.refetchQueries("dataser");
      dispatch(setSelectedTab(0));
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  //============api deleteSubServices ============

  const { mutate: deleteMutateSub } = useMutation(deleteSubServices, {
    onSuccess: (data) => {
      toast.success("Sub-Service deleted successfully.");
      queryClient.refetchQueries("dataser");
      // setSelectedTab(0);
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  const item = res && res[selectedTab];
  const handleOpen = () => {
    dispatch(setSelectItem(item));
    dispatch(OpenDeleteModal(true));
  };
  const handleDelete = () => {
    deleteMutate({ id: item._id, token });
    dispatch(OpenDeleteModal(false));
  };
  const handleOpenSub = (subitem) => {
    dispatch(setSelectItem(subitem));
    dispatch(OpenDeleteModal(true));
  };
  const handleDeleteSub = () => {
    deleteMutateSub({ id: selectedItem._id, token });
    dispatch(OpenDeleteModal(false));
  };

  const handleEdit = () => {
    dispatch(setSelectItem(item));
    navigate(`/edit-services/${item._id}`);
  };
  const handleEditSub = (itemData) => {
    dispatch(setSelectItem(itemData));
    navigate(`/edit-sub-services/${itemData._id}`);
  };

  return (
    <Box sx={{ width: "97%", m: "17px auto" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "30%", lg: "25%" } }}>
          <Box
            sx={{
              borderBottom: "1px solid #E9ECEF",
              background: "#fff",
              borderRadius: "4px 4px 0 0 ",
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pl: "16px",
            }}
          >
            <Typography variant="h1">Services list</Typography>
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <>
                <IconButton
                  sx={icons}
                  onClick={() => handleEdit()}
                  data-cy="edit-DepartmentTable"
                >
                  <img
                    src={editTableIcon}
                    width="12px"
                    height="100%"
                    alt="edit icon"
                  />
                </IconButton>
              </>

              <>
                <IconButton
                  sx={icons}
                  onClick={() => {
                    handleOpen();
                  }}
                  data-cy="delete-Employee"
                >
                  <img
                    src={deleteTableIcon}
                    width="12px"
                    height="100%"
                    alt="edit icon"
                  />
                </IconButton>
              </>
            </Box>
          </Box>
          <Tabs
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            variant="scrollable"
            value={selectedTab}
            onChange={handleChange}
            sx={tabsStyle}
          >
            {isLoading ? (
              <Loader />
            ) : !res?.length ? (
              <NoData />
            ) : (
              res &&
              res?.map((tab, index) => (
                <Tab
                  key={index}
                  label={
                    <Box sx={singleTab}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={
                            selectedTab === index
                              ? tab?.mainIcon
                              : tab?.mainIcon
                          }
                          alt=""
                          width="20px"
                          height="20px"
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color:
                              selectedTab === index ? "#0575E6" : "#666666",
                            fontFamily:
                              selectedTab === index
                                ? "Poppins-SemiBold"
                                : "Poppins-Regular",
                          }}
                        >
                          {tab.title}
                        </Typography>
                      </Box>
                      {selectedTab === index && (
                        <Box
                          sx={{
                            fontSize: { xs: ".859rem", sm: "1rem" },
                            color: selectedTab === index ? "#fff" : "#000",
                          }}
                        >
                          {!isSmallScreen && <ArrowForwardIosIcon sx={{fontSize:"14px", fill:"#0575E6"}} />}
                        </Box>
                      )}
                    </Box>
                  }
                />
              ))
            )}
            <Box
              sx={{ height: "50px", display: { xs: "none", md: "block" } }}
            />
          </Tabs>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              bottom: 0,
              width: "100%",
              mt: "-30px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/add-services")}
              sx={{
                maxWidth: "100%",
                minWidth: "100%",
                borderRadius: "0px",
              }}
            >
              + Add New service
            </Button>
          </Box>
        </Box>
        {isLoading ? (
          <Loader />
        ) : !res?.length ? (
          <NoData />
        ) : (
          <Box
            sx={{
              width: { xs: "100%", md: "68.5%", lg: "73.5%" },
              background: "#fff",
              p: "10px 16px",
              borderRadius: { xs: "0px", md: "4px" },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h1">
                {res && res[selectedTab]?.title}
              </Typography>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <>
                  <IconButton
                    sx={icons}
                    onClick={() => handleEdit()}
                    data-cy="edit-DepartmentTable"
                  >
                    <img
                      src={editTableIcon}
                      width="12px"
                      height="100%"
                      alt="edit icon"
                    />
                  </IconButton>
                </>

                <>
                  <IconButton
                    sx={icons}
                    onClick={() => {
                      handleOpen();
                    }}
                    data-cy="delete-Employee"
                  >
                    <img
                      src={deleteTableIcon}
                      width="12px"
                      height="100%"
                      alt="edit icon"
                    />
                  </IconButton>
                </>
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">
                {res && res[selectedTab]?.description}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography variant="h1">
                {res && res[selectedTab]?.subTitle}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">
                {res && res[selectedTab]?.subDescription}
              </Typography>
            </Box>
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  width: "100%",
                  mt: "30px",
                  ml: { xs: "-8px", md: "-16px" },
                }}
              >
                {res &&
                  res[selectedTab]?.subServices?.map((item, i) => (
                    <Grid item xs={12} sm={6} lg={4} key={i}>
                      <Box
                        sx={{
                          height: "100%",
                          p: " 5px 10px 10px",
                          boxShadow: "3px 4px 7px 0px #0000000A",
                          borderRadius: "8px",
                          width: "100%",
                          border: "1px solid #E9ECEF",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <>
                            <IconButton
                              sx={icons}
                              onClick={() =>
                                // navigate(`/edit-sub-services/${item._id}`)
                                handleEditSub(item)
                              }
                              data-cy="edit-DepartmentTable"
                            >
                              <img
                                src={editTableIcon}
                                width="12px"
                                height="100%"
                                alt="edit icon"
                              />
                            </IconButton>
                          </>

                          <>
                            <IconButton
                              sx={icons}
                              onClick={() => handleOpenSub(item)}
                              data-cy="delete-Employee"
                            >
                              <img
                                src={deleteTableIcon}
                                width="12px"
                                height="100%"
                                alt="edit icon"
                              />
                            </IconButton>
                          </>
                        </Box>
                        <Box align="center" mt={0.5}>
                          <img
                            src={item.icon}
                            alt="img"
                            width="25px"
                            height="100%"
                          />
                        </Box>
                        <Typography
                          variant="h3"
                          align="center"
                          my={0.5}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="h6" align="center">
                          {item.description}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}

                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={4}
                  onClick={() =>
                    navigate("/sub-services", { state: { item: item._id } })
                  }
                >
                  <Box
                    sx={{
                      height: "100%",
                      minHeight:"200px",
                      p: "10px",
                      borderRadius: "8px",
                      border: `1px dashed #0575E6`,
                      background: "#0575E61A",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <Box align="center">
                      <img src={plus} alt="plus" />
                    </Box>
                    <Typography variant="h2" align="center" color="#0575E6">
                      Add Sub service
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" }, mt: "20px" }}>
              <Button
                variant="contained"
                onClick={() => navigate("/add-services")}
                sx={{
                  maxWidth: "100% ",
                  minWidth: "100% ",
                }}
              >
                + Add New service
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      {/* )} */}
      <DeleteModal
        handleDelete={
          selectedItem?.subTitle === "" || selectedItem?.subTitle
            ? handleDelete
            : handleDeleteSub
        }
      />
      {/* <DeleteModal handleDelete={handleDeleteSub} /> */}
    </Box>
  );
};

export default ServicesList;

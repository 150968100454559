import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { loginSchema } from "../../utils/Schema";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bg1, bg2, logo } from "../../assets/images";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { logIn } from "../../api/Auth";
import {
 
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";


//=============style=================

const root = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  position: "relative",
};
const imgBox = { position: "absolute", top: 0, right: 0 };
const imgBox1 = { position: "absolute", bottom: 0, left: 0 };

const main = {
  pt: 3,
  pl: 4,
  pr: 4,
  pb: 3,
  display: "flex",
  alignItems: "left",
  justifyContent: "space-between",
  flexDirection: "column",
  minHeight: "350px",
  width: { xs: "100%", sm: "350px" },
  border: "1px solid #E9ECEF",
  borderRadius: "8px",
};

const logoStyle = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const lableText = { mb: 0.5 };

const logoBox = { width: "70px", height: "70px" };

//=============component=================

const LogIN = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = React.useState(false);
  const [recaptchaReady, setRecaptchaReady] = React.useState(false);
  const { isLoading, mutate } = useMutation(logIn, {
    onSuccess: (data) => {
      resetForm();
    },

    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: () => loginSchema(),
    onSubmit: async (values) => {
      if (!executeRecaptcha) {
        return;
      }
      const token = await executeRecaptcha("yourAction");
      const data = {
        email: values.email,
        password: values.password,
        recaptcha: token,
      };
      mutate({ data, dispatch, navigate });
    },
  });
  useEffect(() => {
    // You can set a timeout or use any other mechanism to check if reCAPTCHA has loaded
    const checkRecaptchaLoad = setInterval(() => {
      if (window.grecaptcha) {
        setRecaptchaReady(true);
        clearInterval(checkRecaptchaLoad);
      }
    }, 100);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(checkRecaptchaLoad);
  }, []);
  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    resetForm,
  } = formik;
  return (
    <Box sx={root}>
      <Box sx={imgBox}>
        <img src={bg1} alt="magnus mage" />
      </Box>
      <Box sx={imgBox1}>
        <img src={bg2} alt="magnus mage" width="80%" />
      </Box>
      <Box>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={main}>
            <Box>
              <Box sx={logoStyle}>
                <Box sx={logoBox}>
                  <img
                    src={logo}
                    alt="magnus mage"
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Box>
              <Typography variant="h1" align="center">
                Welcome Back!
              </Typography>
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Login to your account
              </Typography>
            </Box>
            <Box>
              <Box>
                <Typography variant="h4" sx={lableText}>
                  Email:
                </Typography>
                <Box sx={{ minHeight: "67px" }}>
                  <TextField
                    placeholder="Enter email"
                    name="email"
                    inputProps={{
                      "data-cy": "email-LoginForm",
                    }}
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Box>

                <Typography variant="h4" sx={lableText}>
                  Password:
                </Typography>
                <Box sx={{ minHeight: "67px" }}>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    inputProps={{
                      "data-cy": "password-LoginForm",
                    }}
                    value={values.password || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility
                                sx={{ fill: "#0575E6", fontSize: "18px" }}
                              />
                            ) : (
                              <VisibilityOff
                                sx={{ fill: "#0575E6", fontSize: "18px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: "none" }}
              fullWidth
              disabled={isLoading || !recaptchaReady}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              ) : (
                "Sign in"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default LogIN;

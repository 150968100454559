import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "data",
  users: [],
  open: false,
  selectedTab: 0,
  previousPath: null,
};

export const adminSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setUserData(state, action) {
      state.users = action.payload;
    },
    OpenDeleteModal(state, action) {
      state.open = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setPreviousPath(state, action) {
      state.previousPath = action.payload;
    },
  },
});

export const { setUserData, OpenDeleteModal, setSelectedTab, setPreviousPath } =
  adminSlice.actions;

export default adminSlice.reducer;

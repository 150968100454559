import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Card from "../../../../components/card";
import Heading from "../../../../components/heading/Heading";
import { check, radio } from "../../../../assets/images";
import { useFormik } from "formik";
import { addTeamSchema } from "../../../../utils/Schema";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { addEmployeeData } from "../../../../api/Employee";
import { toast } from "react-hot-toast";
import ImgBox from "../../../../components/imgBox/ImgBox";

//=============style=================

const main = { p: "20px" };

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};
const genderBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "50px",
  gap: 1,
};
const root = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const selectPlaceholder = {
  color: "#666666",
  fontFamily: "Poppins-Regular",
  fontSize: "12px",
  textAlign: "left",
  opacity: 0.8,
};

const dispBox = {
  display: "flex",
  flexWrap: "wrap",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const clButton = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

//=============component=================

const AddEmployee = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [inputKey, setInputKey] = useState(0);
  const { token } = useSelector((state) => state.login);
  const initialValues = {
    name: "",
    image: "",
    department: "",
    designation: "",
    category: "",
    Type: "",
    Gender: "male",
    Sequence: "",
    linkedin: "",
    description: "",
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setFieldValue("image", selectedImage);
    setInputKey(inputKey + 1);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    setFieldValue("image", selectedImage);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  //===================api==============

  const { isLoading, mutate } = useMutation(addEmployeeData, {
    onSuccess: (data) => {
      toast.success("Employee added successfully.");
      queryClient.refetchQueries("datatxs");
      navigate("/");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  //=======formick====

  const formik = useFormik({
    initialValues,
    validationSchema: () => addTeamSchema(),
    onSubmit: (values) => {
      const formData = {
        name: values.name,
        photo: values.image,
        department: values.department,
        designation: values.designation,
        category: values.category,
        type: values.Type,
        gender: values.Gender,
        level: values.Sequence,
        linkedInUrl: values.linkedin,
        description: values.description,
      };

      mutate({ formData, token });
    },
  });
  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  return (
    <Card>
      <Box sx={main}>
        <form onSubmit={handleSubmit}>
          <Heading text="Details" />
          <Box sx={root}>
            <Box sx={labelText}>
              {" "}
              <Typography>Image</Typography>
            </Box>

            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fieldName="image" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Name*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Department Name*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Department"
                name="department"
                value={values.department}
                onChange={handleChange}
                error={Boolean(touched.department && errors.department)}
                helperText={touched.department && errors.department}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Designation*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Designation"
                name="designation"
                value={values.designation}
                onChange={handleChange}
                error={Boolean(touched.designation && errors.designation)}
                helperText={touched.designation && errors.designation}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              <Typography>Category*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                select
                fullWidth
                name="category"
                value={values.category}
                onChange={handleChange}
                sx={{
                  "& .MuiSelect-icon": {
                    fill: "#0575E6 !important",
                    fontSize: "30px",
                  },
                }}
                error={Boolean(touched.category && errors.category)}
                helperText={touched.category && errors.category}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (selected) => {
                    if (!selected) {
                      return (
                        <Typography sx={selectPlaceholder}>
                          Select Category
                        </Typography>
                      );
                    }
                    return selected;
                  },
                }}
              >
                <MenuItem value="core-team">Core-Team</MenuItem>
                <MenuItem value="executive">Executive</MenuItem>
                <MenuItem value="mentor">Mentor</MenuItem>
              </TextField>
            </Box>
          </Box>

          {/* Conditional rendering of the "Type" field */}
          {values.category === "core-team" && (
            <Box sx={fieldBox}>
              <Box sx={labelText}>
                {" "}
                <Typography>Type*</Typography>
              </Box>
              <Box sx={{ width: { sx: "100%", md: "50%" } }}>
                <TextField
                  select
                  fullWidth
                  name="Type"
                  value={values.Type}
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-icon": {
                      fill: "#0575E6 !important",
                      fontSize: "30px",
                    },
                  }}
                  error={Boolean(touched.Type && errors.Type)}
                  helperText={touched.Type && errors.Type}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return (
                          <Typography sx={selectPlaceholder}>
                            Select Type
                          </Typography>
                        );
                      }
                      return selected;
                    },
                  }}
                >
                  <MenuItem value="head">Head</MenuItem>
                  <MenuItem value="professional">Professional</MenuItem>
                </TextField>
              </Box>
            </Box>
          )}

          {values.category === "executive" && (
            <Box sx={fieldBox}>
              <Box sx={labelText}>
                {" "}
                <Typography>Type*</Typography>
              </Box>
              <Box sx={{ width: { sx: "100%", md: "50%" } }}>
                <TextField
                  select
                  fullWidth
                  name="Type"
                  value={values.Type}
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-icon": {
                      fill: "#0575E6 !important",
                      fontSize: "30px",
                    },
                  }}
                  error={Boolean(touched.Type && errors.Type)}
                  helperText={touched.Type && errors.Type}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return (
                          <Typography sx={selectPlaceholder}>
                            Select Type
                          </Typography>
                        );
                      }
                      return selected;
                    },
                  }}
                >
                  <MenuItem value="executive">Executive</MenuItem>
                </TextField>
              </Box>
            </Box>
          )}

          {values.category === "mentor" && (
            <Box sx={fieldBox}>
              <Box sx={labelText}>
                {" "}
                <Typography>Type*</Typography>
              </Box>
              <Box sx={{ width: { sx: "100%", md: "50%" } }}>
                <TextField
                  select
                  fullWidth
                  name="Type"
                  value={values.Type}
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-icon": {
                      fill: "#0575E6 !important",
                      fontSize: "30px",
                    },
                  }}
                  error={Boolean(touched.Type && errors.Type)}
                  helperText={touched.Type && errors.Type}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) {
                        return (
                          <Typography sx={selectPlaceholder}>
                            Select Type
                          </Typography>
                        );
                      }
                      return selected;
                    },
                  }}
                >
                  <MenuItem value="mentor">Mentor</MenuItem>
                </TextField>
              </Box>
            </Box>
          )}

          <Box sx={genderBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Gender*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <RadioGroup
                aria-label="Gender"
                name="Gender"
                value={values.Gender}
                onChange={handleChange}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="male"
                  control={
                    <Radio
                      icon={<img src={radio} alt="unchecked" />}
                      checkedIcon={<img src={check} alt="checked" />}
                      checked={values.Gender === "male"}
                    />
                  }
                  label={
                    <Typography variant="h6" sx={{ color: "#666666" }}>
                      Male
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      icon={<img src={radio} alt="unchecked" />}
                      checkedIcon={<img src={check} alt="checked" />}
                      checked={values.Gender === "female"}
                    />
                  }
                  label={
                    <Typography variant="h6" sx={{ color: "#666666" }}>
                      Female
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Level*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Level type number"
                name="Sequence"
                value={values.Sequence}
                onChange={handleChange}
                type="number" // Set the input type to "number"
                inputProps={{
                  min: 0, // Optionally, you can set a minimum value
                }}
                error={Boolean(touched.Sequence && errors.Sequence)}
                helperText={touched.Sequence && errors.Sequence}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Linkedin URL*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="LinkedIn"
                name="linkedin"
                value={values.linkedin}
                onChange={handleChange}
                error={Boolean(touched.linkedin && errors.linkedin)}
                helperText={touched.linkedin && errors.linkedin}
              />
            </Box>
          </Box>

          <Box sx={dispBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>
                Description
                <span style={{ color: "#666666" }}>(Optional)</span>{" "}
              </Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                name="description"
                minRows={4}
                value={values.description}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
              />
            </Box>
          </Box>

          {/* Add a Button for submitting the data */}
          <Box sx={clButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{ maxWidth: "90px" }}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              ) : (
                "submit"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default AddEmployee;

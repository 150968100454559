/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */

import { Box } from "@mui/system";
import { Container, IconButton, Typography } from "@mui/material";
import Card from "../../../../../components/pageCard/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteIcone, editTableIcon } from "../../../../../assets/images";
import { setTokenPage } from "../../../../../redux/slices/servicePageSlice";
import { useDispatch } from "react-redux";

const root = (data) => ({
  width: "100%",
  background: data?.bgColor || "#fff",
  position: "relative",
});
const wrapper = {
  width: "100%",
  padding: "60px 0px 100px 0px",
};

const rootWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  flexDirection: "column",
};

const titleStyle = (data) => ({
  color: data?.titleColor,
});
const descStyle = (data) => ({
  color: data?.descColor,
});

const headingWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: { xs: "100%", sm: "80%", md: "61%", lg: "61%" },
};
const backImage = {
  position: "absolute",
  bottom: 50,
  right: 50,
  height: 208,
  width: 196,
};
const backgroundImage = {
  position: "absolute",
  height: 118,
  width: 113,
  top: 140,
  left: 60,
};
const Fact = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  return (
    <Box sx={{ mt: 2, position: "relative" }}>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 3,
          zIndex: 1,
          display:location.pathname==="/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => navigate("/edit-token")}
      >
        <img src={editTableIcon} width="12px" height="12px" alt="edit icon" />
      </IconButton>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 30,
          zIndex: 1,
          display: location.pathname==="/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => dispatch(setTokenPage(null))}
      >
        <img src={deleteIcone} width="12px" height="12px" alt="edit icon" />
      </IconButton>

      <Box sx={root(data)}>
        <Box sx={backImage}>
          <img
            src={data?.BottomRightIcon || "/images/fact2.webp"}
            style={{ width: "100%", height: "100%" }}
            alt="background"
          />
        </Box>
        <Box sx={backgroundImage}>
          <img
            src={data?.topLeftIcon || "/images/fact1.webp"}
            style={{ objectFit: "contain" }}
            alt="background"
          />
        </Box>
        <Box sx={wrapper}>
          <Container>
            <Box sx={rootWrapper}>
              <Box sx={headingWrapper}>
                <Typography
                  variant="body2"
                  textAlign={"center"}
                  lineHeight={1.5}
                  mb={2}
                  sx={titleStyle(data)}
                >
                  {data?.title}
                </Typography>

                <Typography
                  mb={5}
                  lineHeight={1.5}
                  textAlign={"center"}
                  sx={descStyle(data)}
                >
                  {data?.description}
                </Typography>
              </Box>
              <Card data={data?.tokenCards} />
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Fact;

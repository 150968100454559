import axios from "axios";
import { REACT_APP_API_URL } from "../helper/envUrl";

//====get all==========
export const fetchDataServices = async (paramData) => {
  return await axios.get(`${process.env.REACT_APP_API_URL}/api/service/all`, {
    params: paramData,
  });
};

//===========addServices==========

export const addServices = async ({ formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", formData.title);
  bodyFormData.append("description", formData.description);
  bodyFormData.append("subTitle", formData.subTitle);
  bodyFormData.append("subDescription", formData.subDescription);
  bodyFormData.append("mainIcon", formData.mainIcon);

  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/service`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};

//====deleteServices==========

export const deleteServices = async ({ id, token }) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/service/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data; // Modify this based on your API response structure
    });
};

//===========editServices==========

export const editServices = async ({ id, formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", formData.title);
  bodyFormData.append("description", formData.description);
  bodyFormData.append("subTitle", formData.subTitle);
  bodyFormData.append("subDescription", formData.subDescription);
  bodyFormData.append("mainIcon", formData.mainIcon);
  return await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/service/${id}`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};

//===========addSubServices==========

export const addSubServices = async ({ formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", formData.title);
  bodyFormData.append("description", formData.description);
  bodyFormData.append("icon", formData.icon);
  bodyFormData.append("serviceId", formData.serviceId);

  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/subService`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};

//===========editSubServices==========

export const editSubServices = async ({ id, formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", formData.title);
  bodyFormData.append("description", formData.description);
  bodyFormData.append("icon", formData.icon);
  return await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/subService/${id}`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};

//====deleteServices==========

export const deleteSubServices = async ({ id, token }) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/subService/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data; // Modify this based on your API response structure
    });
};

import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import * as yup from "yup";

export const loginSchema = () =>
  yup.object({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

const linkedinUrlPattern =
  /^(https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?)$/;
const today = new Date();

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

const isDateValidFormat = (date) => {
  // Define a regex pattern for a valid date format
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  return datePattern.test(date);
};

const dateSchema = yup
  .date()
  .nullable()
  .test("is-valid-date", "Invalid date format", function (date) {
    if (date) {
      return (
        isValidDate(date) && isDateValidFormat(date.toISOString().split("T")[0])
      );
    }
    return true; // If date is not provided, the validation passes
  })
  .test("is-not-in-the-past", "Date must not be in the past.", function (date) {
    if (date) {
      const selectedDate = new Date(date);
      selectedDate.setHours(23, 59, 59, 999);
      return selectedDate >= today;
    }
    return true; // If date is not provided, the validation passes
  });

function isDueDateValid(postDate, dueDate) {
  // Assuming postDate and dueDate are Date objects
  const postDateObj = new Date(postDate);
  const dueDateObj = new Date(dueDate);
  return dueDateObj > postDateObj;
}

const datedSchema = yup
  .date()
  .nullable()
  .test("is-valid-date", "Invalid date format", function (date) {
    if (date) {
      return (
        isValidDate(date) && isDateValidFormat(date.toISOString().split("T")[0])
      );
    }
    return true; // If date is not provided, the validation passes
  });
export const Jobschema = yup.object({
  postDate: dateSchema,
  dueDate: dateSchema
    .required("Deadline is required")
    .test(
      "is-valid-end-date",
      "Deadline must be after or on post Date",
      function (dueDate) {
        const { postDate } = this.parent;

        if (!postDate && !dueDate) {
          return true; // Both start and end dates are not provided, no validation needed
        }

        if (postDate && dueDate) {
          const startTime = new Date(postDate).setHours(0, 0, 0, 0);
          const endTime = new Date(dueDate).setHours(0, 0, 0, 0);

          if (endTime < startTime) {
            return false; // End Date must be after Start Date
          }
        }

        return true; // If one of the dates is provided, no further validation needed
      }
    ),

  title: yup.string().required("Title is required"),
  jobStatus: yup.string().required("Job status is required"),
  department: yup.string().required("Department is required"),
  jobType: yup.string().required("Job Type is required"),
  designation: yup.string().required("Designation is required"),
  description: yup
    .mixed()
    .test(
      "is-non-empty-html",
      "Description must not be empty",
      function (value) {
        const contentState = convertToRaw(value.getCurrentContent());

        // Check if the HTML string is not empty
        if (draftToHtml(contentState).length <= 8) {
          return this.createError({
            message: "Description must not be empty",
          });
        }

        return true;
      }
    ),
  city: yup
    .string()
    .matches(/^[^\d]*$/, "City Name should not contain numbers")
    .required("City Name is required"),
  positions: yup
    .number()
    .required("Positions is required")
    .positive("Positions must be a positive number")
    .integer("Positions must be an integer")
    .moreThan(0, "Positions must be greater than zero"),
  jobLocation: yup.string().required("Job Location is required"),
  country: yup
    .string()
    .matches(/^[^\d]*$/, "Country Name should not contain numbers")
    .required("Country Name is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test(
      "fileType",
      "Please select a valid image file or provide a valid URL",
      (value) => {
        if (!value) return true; // Allow if no file is selected

        const allowedTypes = [
          "image/jpeg",
          "image/webp",
          "image/jp2",
          "image/png",
          "image/gif",
          "image/bmp",
          "image/tiff",
          "image/svg+xml",
          "image/x-icon",
          "image/vnd.microsoft.icon",
          "image/vnd.wap.wbmp",
          "image/heif",
          "image/heic",
          "image/avif",
          "image/apng",
          "image/flif",
          "image/x-portable-bitmap",
          "image/x-portable-graymap",
          "image/x-portable-pixmap",
        ];

        // Check if it's a valid image type
        if (allowedTypes.includes(value.type)) {
          return true;
        }

        // Check if it's a valid URL
        try {
          new URL(value);
          return true;
        } catch (error) {
          return false;
        }
      }
    ),
});

export const Jobupdateschema = yup.object({
  postDate: datedSchema,
  dueDate: datedSchema.when("postDate", (postDate, schema) => {
    return schema
      .nullable()
      .required("Due Date is required")
      .test({
        name: "is-valid-due-date",
        message: "Due Date must be greater than Date Posted ",
        test: function (dueDate) {
          if (!postDate || !dueDate) {
            // If either postDate or dueDate is not provided, validation passes
            return true;
          }
          // Compare dueDate with postDate
          return isDueDateValid(postDate, dueDate);
        },
      });
  }),

  title: yup.string().required("Title is required"),
  jobStatus: yup.string().required("Job status is required"),
  department: yup.string().required("Department is required"),
  jobType: yup.string().required("Job Type is required"),
  designation: yup.string().required("Designation is required"),
  description: yup
    .mixed()
    .test(
      "is-non-empty-html",
      "Description must not be empty",
      function (value) {
        const contentState = convertToRaw(value.getCurrentContent());

        // Check if the HTML string is not empty
        if (draftToHtml(contentState).length <= 8) {
          return this.createError({
            message: "Description must not be empty",
          });
        }

        return true;
      }
    ),
  city: yup
    .string()
    .matches(/^[^\d]*$/, "City Name should not contain numbers")
    .required("City Name is required"),
  positions: yup
    .number()
    .required("Positions is required")
    .positive("Positions must be a positive number")
    .integer("Positions must be an integer")
    .moreThan(0, "Positions must be greater than zero"),
  jobLocation: yup.string().required("Job Location is required"),
  country: yup
    .string()
    .matches(/^[^\d]*$/, "Country Name should not contain numbers")
    .required("Country Name is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test(
      "fileType",
      "Please select a valid image file or provide a valid URL",
      (value) => {
        if (!value) return true; // Allow if no file is selected

        const allowedTypes = [
          "image/jpeg",
          "image/webp",
          "image/jp2",
          "image/png",
          "image/gif",
          "image/bmp",
          "image/tiff",
          "image/svg+xml",
          "image/x-icon",
          "image/vnd.microsoft.icon",
          "image/vnd.wap.wbmp",
          "image/heif",
          "image/heic",
          "image/avif",
          "image/apng",
          "image/flif",
          "image/x-portable-bitmap",
          "image/x-portable-graymap",
          "image/x-portable-pixmap",
        ];

        // Check if it's a valid image type
        if (allowedTypes.includes(value.type)) {
          return true;
        }

        // Check if it's a valid URL
        try {
          new URL(value);
          return true;
        } catch (error) {
          return false;
        }
      }
    ),
});
export const addTeamSchema = () =>
  yup.object({
    name: yup
      .string()
      .trim()
      .required("Name is required")
      .max(25, "Name must be at most 25 characters"),
    department: yup
      .string()
      .trim()
      .required("Department is required")
      .max(35, "Department must be at most 35 characters"),
    designation: yup
      .string()
      .trim()
      .required("Designation is required")
      .max(35, "Designation must be at most 35 characters"),
    category: yup.string().required("Category is required"),
    Type: yup.string().required("Type is required"),
    Sequence: yup.string().required("Sequence is required"),
    linkedin: yup
      .string()
      .required("LinkedIn url is required")
      .matches(
        linkedinUrlPattern,
        "Invalid LinkedIn URL format. Please use a valid LinkedIn profile URL."
      ),
  });
export const timefilterSchema = yup.object().shape({
  postDateFrom: yup
    .date()
    .nullable()
    .test(
      "is-not-after-current-date",
      "Date and Time must not exceed current time.",
      function (postDateFrom) {
        if (postDateFrom) {
          const currentDateTime = new Date();
          const selectedDateTime = new Date(postDateFrom);

          // Check if Start Date is not greater than current date and time
          return selectedDateTime <= currentDateTime;
        }

        // If Start Date is not provided, the validation passes
        return true;
      }
    ),
  postDateTo: yup
    .date()
    .nullable()
    .test(
      "is-valid-end-date",
      "End Date must be after Start Date",
      function (postDateTo) {
        const { postDateFrom } = this.parent;

        if (!postDateFrom && !postDateTo) {
          // Both start and end dates are not provided, no validation needed
          return true;
        }

        if (postDateFrom && postDateTo) {
          const startTime = new Date(postDateFrom).setHours(0, 0, 0, 0); // Start of day
          const endTime = new Date(postDateTo).setHours(0, 0, 0, 0); // Start of day
          if (endTime <= startTime) {
            return false; // End Date must be after Start Date
          }
        }

        // If one of the dates is provided, no further validation needed
        return true;
      }
    )
    .test(
      "is-not-after-current-date",
      "Date and Time must not exceed current time.",
      function (postDateTo) {
        if (postDateTo) {
          const currentDateTime = new Date();
          const selectedDateTime = new Date(postDateTo);

          // Check if End Date is not greater than current date and time
          return selectedDateTime <= currentDateTime;
        }

        // If End Date is not provided, the validation passes
        return true;
      }
    ),
  dueDateFrom: yup
    .date()
    .nullable()
    .test(
      "is-not-after-current-date",
      "Date and Time must not exceed current time.",
      function (dueDateFrom) {
        if (dueDateFrom) {
          const currentDateTime = new Date();
          const selectedDateTime = new Date(dueDateFrom);

          // Check if Start Date is not greater than current date and time
          return selectedDateTime <= currentDateTime;
        }

        // If Start Date is not provided, the validation passes
        return true;
      }
    ),
  dueDateTo: yup
    .date()
    .nullable()
    .test(
      "is-valid-end-date",
      "End Date must be after Start Date",
      function (dueDateTo) {
        const { dueDateFrom } = this.parent;

        if (!dueDateFrom && !dueDateTo) {
          // Both start and end dates are not provided, no validation needed
          return true;
        }

        if (dueDateFrom && dueDateTo) {
          const startTime = new Date(dueDateFrom).setHours(0, 0, 0, 0); // Start of day
          const endTime = new Date(dueDateTo).setHours(0, 0, 0, 0); // Start of day
          if (endTime <= startTime) {
            return false; // End Date must be after Start Date
          }
        }

        // If one of the dates is provided, no further validation needed
        return true;
      }
    )
    .test(
      "is-not-after-current-date",
      "Date and Time must not exceed current time.",
      function (dueDateTo) {
        if (dueDateTo) {
          const currentDateTime = new Date();
          const selectedDateTime = new Date(dueDateTo);

          // Check if End Date is not greater than current date and time
          return selectedDateTime <= currentDateTime;
        }

        // If End Date is not provided, the validation passes
        return true;
      }
    ),
});
export const AddServicesSchema = () =>
  yup.object({
    title: yup.string().trim().required("Title is required"),
    image: yup.mixed().required("Image is required"),
    description: yup.string().trim().required("Description is required"),
  });
export const SubServicesSchema = () =>
  yup.object({
    title: yup.string().trim().required("Title is required"),
    image: yup.mixed().required("Image is required"),
    description: yup.string().trim().required("Description is required"),
  });
export const AddExpertiseSchema = () =>
  yup.object({
    name: yup.string().trim().required("Name is required"),
    image: yup.mixed().required("Image is required"),
  });

export const changePasswordSchema = () =>
  yup.object({
    currentPassword: yup.string().required("Current password is required"),

    password: yup
      .string()
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,

        "Must contain one uppercase, one lowercase, one number and one special case character"
      )
      .test(
        "no-empty-space",
        "Password should not contain empty spaces",
        (value) => !/\s/.test(value)
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Both password need to be the same")
      .required("Confirm password is required"),
  });

export const addBannerValidationSchema = yup.object().shape({
  // name: yup.string().required("Name is required"),
  title: yup.string().required("Title is required"),
  bgColor: yup.string().required("Background color is required"),
  // lottiefile: yup.string().required("Lottiefile is required"),
  lottiefile: yup
    .mixed()
    .test(
      "file-required",
      "Either Lottie file or Image is required",
      function (value) {
        const { image } = this.parent;
        return !!value || !!image;
      }
    ),
  image: yup
    .string()
    .test(
      "file-required",
      "Either Lottie file or Image is required",
      function (value) {
        const { lottiefile } = this.parent;
        return !!value || !!lottiefile;
      }
    ),
  bgIcon: yup.string().required("Background icon is required"),
  titleColor: yup.string().required("Title color is required"),
  descColor: yup.string().required("Description color is required"),
  formBgColor: yup.string().required("Form background color is required"),
  inputTextColor: yup.string().required("Input text color is required"),
  inputBgColor: yup.string().required("Input background color is required"),
  btnBgColor: yup.string().required("Button background color is required"),
  btnTextColor: yup.string().required("Button text color is required"),
  description: yup.string().required("Description is required"),
});
export const addTokenValidationSchema = yup.object().shape({
  bgColor: yup.string().required("Background color is required"),
  title: yup.string().required("Title is required"),
  topLeftIcon: yup.string().required("Top left icon is required"),
  titleColor: yup.string().required("Title color is required"),
  descColor: yup.string().required("Description color is required"),
  BottomRightIcon: yup.string().required("Bottom right icon is required"),
  description: yup.string().required("Description color is required"),
  tokenCards: yup.array().min(1, "At least one token card is required"),
});

export const validationTagSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  nameColor: yup.string().required("Name color is required"),
  descColor: yup.string().required("Description color is required"),
  cardBgColor: yup.string().required("Card background color is required"),
  description: yup.string().required("Description is required"),
  // Add validation rules for other fields as needed
});
export const validationAddTagSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  nameColor: yup.string().required("Name color is required"),
  BgColor: yup.string().required("Background color is required"),
  descColor: yup.string().required("Description color is required"),
  imgUrl: yup.string().required("Image is required"),
  description: yup.string().required("Description is required"),
  tags: yup.array().min(1, "At least one tag is required"),
  // Add validation rules for other fields as needed
});

export const addProductValidationSchema = yup.object().shape({
  bgColor: yup.string().required("Background colo is required"),
  title: yup.string().required("Title is required"),
  titleColor: yup.string().required("Title color is required"),
  description: yup.string().required("Description Color is required"),
  descColor: yup.string().required("DescColor Icon is required"),
  productImagesBgColor: yup
    .string()
    .required("Product images bgColor  is required"),
  cardBgColor: yup.string().required("CardBgColor is required"),
  tagsBgColor: yup.string().required("TagsBgColor  is required"),
  tagsColor: yup.string().required("TagsColor  is required"),
  products: yup.array().min(1, "At least one product is required"),
});

export const addGetStartedSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  titleColor: yup.string().required("TitleColor is required"),
  description: yup.string().required("Description color is required"),
  descColor: yup.string().required("DescColor tcon is required"),
  bgColor: yup.string().required("BgColor is required"),
  bgIcon: yup.string().required("BgIcon is required"),
  formBgColor: yup.string().required("FormBgColor  is required"),
  inputTextColor: yup.string().required("InputTextColor  is required"),
  inputBgColor: yup.string().required("InputBgColor  is required"),
  list: yup.array().min(1, "At least one list is required"),
  btnBgColor: yup.string().required("BtnBgColor  is required"),
  btnTextColor: yup.string().required("BtnTextColor  is required"),
  bulletColor: yup.string().required("BulletColor  is required"),
  bulletBgColor: yup.string().required("BulletBgColor  is required"),
});

export const addProfessionalSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  nameColor: yup.string().required("Name color is required"),
  description: yup.string().required("Description color is required"),
  descColor: yup.string().required("Description color Icon is required"),
  designation: yup.string().required("Designation is required"),
  desiColor: yup.string().required("Description color is required"),
  linkedInIcon: yup.string().required("LinkedIn icon is required"),
  linkedInUrl: yup
    .string()
    .required("LinkedIn url is required")
    .matches(
      linkedinUrlPattern,
      "Invalid LinkedIn URL format. Please use a valid LinkedIn profile URL."
    ),
  image: yup.string().required("Image is required"),
  sliderBgColor: yup.string().required("SliderBgColor  is required"),
  bgColor: yup.string().required("Background color  is required"),
});

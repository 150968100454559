import { Box, Typography } from "@mui/material";
import React from "react";

const Heading = ({ text }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "41px",
        px: "10px",
        background: "#EFF1F7",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        mb:"20px"
      }}
    >
     <Typography variant="h1">{text}</Typography> 
    </Box>
  );
};

export default Heading;

import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import MessageList from "./component/MessageList";
import UserList from "./component/UserList";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/slices/adminSlice";
import { setActive } from "../../redux/slices/teamSlice";

const root = { width: "97%", m: "17px auto 5px" };
const msgList = { display: { xs: "none", md: "block" } };

const Contact = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = React.useState();

  useEffect(() => {
    dispatch(setSelectedTab(0));
    dispatch(setActive(3));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <UserList filter={filter} setFilter={setFilter} />
        </Grid>
        <Grid item xs={12} md={8} lg={9} sx={msgList}>
          <Box>
            <MessageList filter={filter}/>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;

import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Card from "../../../../components/card";
import Heading from "../../../../components/heading/Heading";
import { useFormik } from "formik";
import { Jobschema } from "../../../../utils/Schema";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { addJobData, uploadImage } from "../../../../api/Career";
import { toast } from "react-hot-toast";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { dispBox, fieldBox, main } from "../../reusablefield/FormOption";
import RichTextEditor from "../../reusablefield/RetextEditor";
import FormField from "../../reusablefield/Formfield";
import SubmitButton from "../../reusablefield/Reusablebutton";
import Reusablelable from "../../reusablefield/Reusablelable";
import ReFormfields from "../../reusablefield/ReFormfields";
//=============style=================

//=============component=================

const AddJob = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [inputKey, setInputKey] = useState(0);
  const { token } = useSelector((state) => state.login);

  const initialValues = {
    title: "",
    department: "", // Required  "frontend", "backend", "design", "sqa"
    designation: "", // Required "hod", "lead", "senior", "associate", "assistant", "junior"
    image: "",
    description: EditorState.createEmpty(),
    positions: 0,
    jobType: "",
    jobLocation: "", //Expected values are "onsite", "remote", "both"    /Required
    postDate: null,
    dueDate: null, // Required
    city: "", // Required
    country: "Pakistan",
    jobStatus: "",
  };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const imageUrl = await uploadImage(file);

      if (imageUrl) {
        formik.setFieldValue("image", imageUrl);
        setInputKey(inputKey + 1);
      }
    } catch (error) {}
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append("file", selectedImage);
    // setSelectedFileLoading(true);
    try {
      const imageUrl = await uploadImage(selectedImage);

      if (imageUrl) {
        formik.setFieldValue("image", imageUrl);
      }
    } catch (error) {}
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  //===================api==============

  const { isLoading, mutate } = useMutation(addJobData, {
    onSuccess: (data) => {
      toast.success("Job is created successfully");
      queryClient.refetchQueries("datatres");
      navigate("/jobs");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  //=======formick====

  const formik = useFormik({
    initialValues,
    validationSchema: Jobschema,
    onSubmit: (values) => {
      const contentState = convertToRaw(values.description.getCurrentContent());
     
      let html = draftToHtml(contentState);
      const formData = {
        title: values.title,
        department: values.department,
        designation: values.designation,
        image: values.image,
        description: html,
        positions: values.positions,
        jobType: values.jobType,
        jobLocation: values.jobLocation,
        city: values.city,
        country: values.country,
        jobStatus: values.jobStatus,
      };

      // Conditionally add postDate to formData if it exists
      if (values.postDate) {
        formData.postDate = values.postDate;
      }

      // Conditionally add dueDate to formData if it exists
      if (values.dueDate) {
        formData.dueDate = values.dueDate;
      }

      mutate({ formData, token });
    },
  });

  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  return (
    <Card>
      <Box sx={main}>
        <form onSubmit={handleSubmit}>
          <Heading text="Add job" />
          <ReFormfields
            inputKey={inputKey}
            values={values}
            setFieldValue={setFieldValue}
            touched={touched}
            errors={errors}
            handleImageChange={handleImageChange}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            formik={formik}
            handleChange={handleChange}
          />

          <Box sx={fieldBox}>
            <Reusablelable label={"Country*"} />
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                type="text"
                fullWidth
                placeholder="Country"
                name="country"
                value={values.country}
                onChange={handleChange}
                error={Boolean(touched.country && errors.country)}
                helperText={touched.country && errors.country}
              />
            </Box>
          </Box>
          <FormField
            label="City"
            name="city"
            type="text"
            values={formik.values}
            handleChange={formik.handleChange}
            touched={formik.touched}
            errors={formik.errors}
            labeltext={"City Name"}
          />
          <Box sx={dispBox}>
            <Reusablelable label={"Description*"} />
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <RichTextEditor
                error={touched.description && errors.description}
                value={values.description}
                onChange={(editorState) =>
                  formik.setFieldValue("description", editorState)
                }
              />
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "12px !important",
                  color: "#e53935",
                  minHeight: "18px",
                }}
              >
                {touched.description && errors.description}
              </Typography>
            </Box>
          </Box>

          {/* Add a Button for submitting the data */}
          <SubmitButton
            onCancel={() => navigate(-1)}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </form>
      </Box>
    </Card>
  );
};

export default AddJob;

import React, { useEffect } from "react";
import ServicesList from "./component/ServicesList";
import { setActive } from "../../redux/slices/teamSlice";
import { useDispatch } from "react-redux";

const Services = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActive(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ServicesList />
    </div>
  );
};

export default Services;

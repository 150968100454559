import React from "react";
import {
  Box,
  // Button,
  // CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  // TextField,
  Typography,
} from "@mui/material";
import { root } from "./FormOption";
import Reusablelable from "./Reusablelable";
// import ImgcareerBox from "../../../components/careerimage/Careerimage";
import FormField from "./Formfield";
import ReusableSelect from "./ReusableSelect";
import SingleDatePicker from "../../../components/DateFilter";
import dayjs from "dayjs";
import {
  fieldBox,
  genderBox,
  Designation,
  options,
  JobType,
  JobStatus,
} from "./FormOption";
import { check, radio } from "../../../../src/assets/images";
import ImgBox from "../../../components/imgBox/ImgBox";
const ReFormfields = ({
  inputKey,
  values,
  setFieldValue,
  touched,
  errors,
  handleImageChange,
  handleDragOver,
  handleDrop,
  formik,
  handleChange,
}) => {
  const [startdateopen, setStartDateopen] = React.useState();
  // const [enddateopen, setendDateopen] = React.useState();
  const handleClearStartDate = (e) => {
    e.stopPropagation();
    formik.setFieldValue("postDate", null);
  };
  const handleClearduedate = (e) => {
    e.stopPropagation();
    formik.setFieldValue("dueDate", null);
  };
  return (
    <Box>
      <Box sx={root}>
        <Reusablelable label={"Image*"} />
        {/* <ImgcareerBox
          inputKey={inputKey}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          handleImageChange={handleImageChange}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
        /> */}

        <ImgBox
          inputKey={inputKey}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
          handleFileChange={handleImageChange} // Your file change handler
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          fieldName="image" // Field name for this file
          acceptedFileTypes={["image/*"]} // Accepted file types
          maxResolution="1024 x 1024 px" // Maximum resolution
        />
      </Box>
      <FormField
        label="Job Title"
        name="title"
        type="text"
        values={formik.values}
        handleChange={formik.handleChange}
        touched={formik.touched}
        errors={formik.errors}
        labeltext={"Job Title"}
      />
      <Box sx={fieldBox}>
        <Reusablelable label={"Department*"} />
        <ReusableSelect
          values={values.department}
          handleChange={handleChange}
          touched={touched.department}
          errors={errors.department}
          options={options}
          name="department"
          labeltext={"Select department"}
        />
      </Box>
      <Box sx={fieldBox}>
        <Reusablelable label={"Designation*"} />
        <ReusableSelect
          values={values.designation}
          handleChange={handleChange}
          touched={touched.designation}
          errors={errors.designation}
          options={Designation}
          name="designation"
          labeltext={"Select designation"}
        />
      </Box>

      <FormField
        label="Positions"
        name="positions"
        type="number"
        values={formik.values}
        handleChange={formik.handleChange}
        touched={formik.touched}
        errors={formik.errors}
        options={options}
        labeltext={"Positions"}
      />
      <Box sx={fieldBox}>
        <Reusablelable label={"Job Type*"} />
        <ReusableSelect
          values={values.jobType}
          handleChange={handleChange}
          touched={touched.jobType}
          errors={errors.jobType}
          options={JobType}
          name="jobType"
          labeltext={"Select job type"}
        />
      </Box>
      <Box sx={genderBox}>
        <Reusablelable label={"Job Location*"} />
        <Box sx={{ width: { sx: "100%", md: "50%" } }}>
          <RadioGroup
            aria-label="jobLocation"
            name="jobLocation"
            value={values.jobLocation}
            onChange={handleChange}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value="onsite"
              control={
                <Radio
                  icon={<img src={radio} alt="unchecked" />}
                  checkedIcon={<img src={check} alt="checked" />}
                  checked={values.jobLocation === "onsite"}
                />
              }
              label={
                <Typography variant="h6" sx={{ color: "#666666" }}>
                  Onsite
                </Typography>
              }
            />
            <FormControlLabel
              value="remote"
              control={
                <Radio
                  icon={<img src={radio} alt="unchecked" />}
                  checkedIcon={<img src={check} alt="checked" />}
                  checked={values.jobLocation === "remote"}
                />
              }
              label={
                <Typography variant="h6" sx={{ color: "#666666" }}>
                  Remote
                </Typography>
              }
            />
            <FormControlLabel
              value="hybrid"
              control={
                <Radio
                  icon={<img src={radio} alt="unchecked" />}
                  checkedIcon={<img src={check} alt="checked" />}
                  checked={values.jobLocation === "hybrid"}
                />
              }
              label={
                <Typography variant="h6" sx={{ color: "#666666" }}>
                  Hybrid
                </Typography>
              }
            />
          </RadioGroup>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "12px !important",
              color: "#e53935",
              minHeight: "18px",
            }}
          >
            {touched.jobLocation && errors.jobLocation}
          </Typography>
        </Box>
      </Box>
      <Box sx={fieldBox}>
        <Reusablelable label={"Date Posted "} />
        <Box sx={{ width: { sx: "100%", md: "50%" } }}>
          <SingleDatePicker
            field={true}
            error={Boolean(touched.postDate && errors.postDate)}
            title=""
            open={startdateopen}
            setOpen={setStartDateopen}
            handleClear={handleClearStartDate}
            date={values.postDate}
            handleChange={(newValue) => {
              let formattedValue;
              if (newValue) {
                formattedValue = dayjs(newValue)?.format("YYYY-MM-DD").trim();
              }

              formik.setFieldValue("postDate", formattedValue);
            }}
            placeholder="Select From Date"
            id="postDate"
          />
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "12px !important",
              color: "#e53935",
              minHeight: "18px",
            }}
          >
            {touched.postDate && errors.postDate}
          </Typography>
        </Box>
      </Box>
      <Box sx={fieldBox}>
        <Reusablelable label={"Deadline*"} />
        <Box sx={{ width: { sx: "100%", md: "50%" } }}>
          <SingleDatePicker
            error={Boolean(touched.dueDate && errors.dueDate)}
            field={true}
            // open={enddateopen}
            setOpen={setStartDateopen}
            handleClear={handleClearduedate}
            date={values.dueDate}
            handleChange={(newValue) => {
              let formattedValue;
              if (newValue) {
                formattedValue = dayjs(newValue).format("YYYY-MM-DD").trim();
              }
              formik.setFieldValue("dueDate", formattedValue);
            }}
            placeholder="Select To Date"
            id="dueDate"
          />
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "12px !important",
              color: "#e53935",
              minHeight: "18px",
            }}
          >
            {touched.dueDate && errors.dueDate}
          </Typography>
        </Box>
      </Box>
      <Box sx={fieldBox}>
        <Reusablelable label={"Job Status*"} />
        <ReusableSelect
          values={values.jobStatus}
          handleChange={handleChange}
          touched={touched.jobStatus}
          errors={errors.jobStatus}
          options={JobStatus}
          name="jobStatus"
          labeltext={"Select job status"}
        />
      </Box>
    </Box>
  );
};

export default ReFormfields;

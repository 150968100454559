import React from "react";
import Box from "@mui/material/Box";
// import { copyIcon1 } from "../../assets/images";
import copy from "copy-to-clipboard";
import DoneIcon from "@mui/icons-material/Done";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CustomTooltip } from "../customTooltip";

const copyBox = {
  width: "100%",
  maxWidth: "10px",
  height: "10px",
  borderRadius: "10px",
  boxShadow: "-7px -7px 10px 0 rgba(255, 255, 255, 0.5)",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  ml: 1,
};
const CopyBox = ({ title }) => {
  const [wasACopied, setWasACopied] = React.useState(false);

  const handleAddressCopy = () => {
    copy(title);
    setWasACopied(true);

    setTimeout(() => {
      setWasACopied(false);
    }, 2000);
  };
  return (
    <CustomTooltip title={wasACopied ? "Copied" : "Copy"}>
      <Box sx={copyBox} onClick={handleAddressCopy}>
        {wasACopied ? (
          <DoneIcon sx={{ fill: "#666666", maxWidth: "18px" }} />
        ) : (
          <ContentCopyIcon
            sx={{
              fill: "#666666",
              width: "15px",
              height: "15px ",
              fontSize: "10px",
            }}
          />
        )}
      </Box>
    </CustomTooltip>
  );
};

export default CopyBox;

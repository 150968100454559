import { Box } from "@mui/material";

import {
  AdsBlack,
  adsblue,
  careerBlue,
  careerLight,
  contactActive,
  contactLight,
  expertiseActive,
  expertiseLight,
  jobBlue,
  jobLight,
  serviseActive,
  serviseLight,
  teamActive,
  teamLight,
} from "../assets/images";
import React, { useEffect, useState } from "react";

import SideBar from "./sidebar";
import TopBar from "./topbar";

// ---------Component style------------

const mainBox = {
  display: "flex",
  flexDirection: { xs: "column", sm: "column", md: "row" },
  height: "100vh",
  background: "#EFF1F7",
};
const mainContainer = {
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  minWidth: { xs: "100%", sm: "100%", md: "100vh" },
  width: { xs: "100%", sm: "100%", md: "calc(100% - 60px)" },
};
const wrapper = {
  overflow: "auto",
  background: "#EFF1F7",
  // pb: { xs: 7, sm: 7, md: 0 },
};

// ---------Component ------------
export const adminData = [
  {
    id: "0",
    name: "Team",
    image: teamLight,
    activeImage: teamActive,
    path: "/",
    open: false,
  },
  {
    id: "1",
    name: "Services",
    image: serviseLight,
    activeImage: serviseActive,
    path: "/services",
    open: false,
  },

  {
    id: "2",
    name: "Expertise",
    path: "/expertise",
    image: expertiseLight,
    activeImage: expertiseActive,
    open: false,
  },
  {
    id: "3",
    name: "Contact",
    path: "/contact",
    image: contactLight,
    activeImage: contactActive,
    open: false,
  },
  {
    id: "4",
    name: "Jobs",
    path: "/jobs",
    image: careerLight,
    activeImage: careerBlue,
    open: false,
  },
  {
    id: "5",
    name: "Applicants",
    path: "/applicant",
    image: jobLight,
    activeImage: jobBlue,
    open: false,
  },
  {
    id: "6",
    name: "Campaign Ads",
    path: "/campaign-ads",
    image: AdsBlack,
    activeImage: adsblue,
    open: false,
  },
];

const MainLayout = ({ children }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const wrapperElement = document.querySelector(".content");
      const scrollTop = wrapperElement.scrollTop;

      if (scrollTop === 0) {
        setIsScrolling(false);
      } else {
        setIsScrolling(true);
      }
    };

    const wrapperElement = document.querySelector(".content");
    wrapperElement.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup the event listener when the component unmounts
      wrapperElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box sx={mainBox}>
      <Box>
        <SideBar />
      </Box>
      <Box sx={mainContainer}>
        <Box sx={wrapper} className="content">
          <>
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 9,
              }}
            >
              <TopBar isScrolling={isScrolling} />
            </Box>
            {children}
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;

export const mainBox = (theme) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  p: "0px 16px",
  minHeight: "65px",
  borderBottom: `2px solid ${theme.palette.primary.lightGray}`,
  justifyContent: "space-between",
  "@media (max-width: 570px)": {
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "8px 0px",
  },
});

export const tableBox = {
  width: "100%",
  overflowY: "auto",
  pl: 2,
  pr: 2,
  minHeight: "510px",
};
export const actionBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "10px",
  maxWidth: "10px",
};
export const actionBoxd = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  minWidth: "100px",
  maxWidth: "100px",
};
export const searchfieldStyle = {
  display: "flex",
  "& .MuiOutlinedInput-root": {
    height: "34px",
  },
};

export const searchButtonStyle = {
  width: "36px",
  height: "33px",
  display: "flex",
  background: "#E9ECEF",
  justifyContent: "center",
  borderTopRightRadius: "3px",
  borderBottomRightRadius: "3px",
  marginRight: "-14px",
  cursor: "pointer",
};
export const buttonfilter = () => ({
  display: "flex",
  gap: "3px",
  "@media (max-width: 570px)": {
    justifyContent: "space-between",
  },
});

export const container = {
  display: "flex",
  "@media (max-width: 570px)": {
    width: "100%",
    flexDirection: "column",
    margin: "8px 0px",
  },
};
export const containerd = {
  display: "flex",
  "@media (max-width: 570px)": {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "8px 0px",
  },
};
export const icons = {
  color: "blue",
  padding: "6px",
  fontSize: "1rem",
};

export const wrap = {
  minHeight: "60vh",
};
export const linkstyle = {
  color: "#0575E6",
  cursor: "pointer",
  maxWidth: "90px", // Adjust the width according to your layout
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const ellpsisBox = (width) => ({
  maxWidth: width, // Adjust the width according to your layout
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
export const drawerbtn = {
  background: "#EFF1F7",
  width: 50,
  height: 34,
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  ml: "10px",
  "@media (max-width: 570px)": {
    mt: "14px",
    ml: "0px",
  },
};
export const drawercareerbtn = {
  background: "#EFF1F7",
  width: 50,
  height: 34,
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "@media (max-width: 570px)": {
    marginTop: "14px",
  },
};
export const addButtonStyle = () => ({
  minWidth: "100px",
  mr: 1.5,
  fontWeight: "normal",
  "@media (max-width: 570px)": {
    mt: 1.5,
    ml: 0,
    minWidth: "100px",
    maxWidth: "150px",
  },
});
export const generateCircleStyle = (item) => {
  const baseFontSize = item.positions >= 10 ? "10px" : "11px";

  return {
    width: "20px",
    height: "20px",
    background: " #0575E64D",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: baseFontSize,
  };
};

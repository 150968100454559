import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  banner: null,
  namePage: "",
  tokenPage: null,
  product: null,
  newProduct: null,
  file: null,
  addTags: [],
  addProductTags: [],
  getStarted: null,
  professional: [],
};

export const servicePageSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setBanner(state, action) {
      state.banner = action.payload;
    },
    setNamePage(state, action) {
      state.namePage = action.payload;
    },
    setAddTags(state, action) {
      state.addTags = action.payload;
    },
    setFile(state, action) {
      state.file = action.payload;
    },
    setTokenPage(state, action) {
      state.tokenPage = action.payload;
    },
    setProductPage(state, action) {
      state.product = action.payload;
    },
    setAddProductTags(state, action) {
      state.addProductTags = action.payload;
    },
    setNewProduct(state, action) {
      state.newProduct = action.payload;
    },
    setGetStarted(state, action) {
      state.getStarted = action.payload;
    },
    setProfessional(state, action) {
      state.professional = action.payload;
    },
  },
});

export const {
  setBanner,
  setNamePage,
  setAddTags,
  setTokenPage,
  setProductPage,
  setFile,
  setAddProductTags,
  setNewProduct,
  setGetStarted,
  setProfessional,
} = servicePageSlice.actions;

export default servicePageSlice.reducer;

import axios from "axios";
import Axiosapi from "../utils/utils";
import {
  setBanner,
  setGetStarted,
  setNamePage,
  setProductPage,
  setProfessional,
  setTokenPage,
} from "../redux/slices/servicePageSlice";

export const newPages = async ({ data }) => {
  return await Axiosapi.post(`/api/pages`, data, {}).then((response) => {
    return response.data; // Modify this based on your API response structure
  });
};
export const newPagesUpdate = async ({ data, name }) => {
  return await Axiosapi.patch(`/api/pages/${name}`, data, {}).then(
    (response) => {
      return response.data; // Modify this based on your API response structure
    }
  );
};

export const fetchPageData = async (paramData, token, handleLogout) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/pages`,
      {
        params: paramData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Handle successful response here, e.g., return data
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      handleLogout();
    }
    // Handle errors here
    console.error("An error occurred while fetching data:", error);
    throw error; // Optional: Rethrow the error to propagate it to the caller
  }
};
export const fetchSinglePageData = async (id, dispatch) => {
  try {
    const response = await Axiosapi.get(`/api/pages/${id}`, {});

    const data = response?.data?.data;

    // Dispatch actions to set data in the state
    dispatch(setNamePage(data?.name));
    dispatch(setBanner(data?.banner));
    dispatch(setTokenPage(data?.token));
    dispatch(setProductPage(data?.product));
    dispatch(setGetStarted(data?.getStarted));
    dispatch(setProfessional(data?.professionals));

    // Handle successful response here, e.g., return data
    return response;
  } catch (error) {
    // Handle errors here
    console.error("An error occurred while fetching data:", error);
    throw error; // Optional: Rethrow the error to propagate it to the caller
  }
};

export const geturl = async (slug) => {
  const response = await Axiosapi.get(`${slug}`);
  return response.data;
};

export const deletePage = async ({ id }) => {
  return await Axiosapi.delete(`/api/pages/${id}`, {}).then((response) => {
    return response.data; // Modify this based on your API response structure
  });
};

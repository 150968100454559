import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";

const dateStyle = {
  ".MuiInputBase-root": {
    ".MuiButtonBase-root": {
      ".MuiSvgIcon-root": {
        fill: "#0575E6",
      },
    },
  },
};

const inputLabel = {
  mt: 0,
  mb: 0,
  fontSize: "14px",
  color: "#000000",
  opacity: 0.5,
};

export const SingleDatePicker = ({
  title,
  setOpen,
  open,
  date,
  handleChange,
  handleClear,
  id,
  placeholder,
  field,
  error,
  disablefuture,
}) => {
  const ClearableIconButton = () => {
    return (
      <>
        <IconButton onClick={handleClear} disableRipple edge="end">
          <ClearIcon color="primary" />
        </IconButton>
      </>
    );
  };
  return (
    <Box>
      <Typography sx={inputLabel}>
        {title} <span style={{ fontSize: "12px" }}></span>
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          sx={dateStyle}
          disableFuture={disablefuture === true ? true : false}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          showDaysOutsideCurrentMonth
          openTo="day"
          views={["year", "month", "day"]}
          format="DD/MM/YYYY"
          value={date && dayjs(date)}
          onChange={(newValue) => handleChange(newValue, id)}
          slots={{
            openPickerButton: date ? ClearableIconButton : undefined,
          }}
          slotProps={{
            textField: {
              readOnly: true, // Set readOnly to true
              onClick: () => setOpen(true),

              placeholder: placeholder || "search...",
              variant: "outlined",
              fullWidth: true,
              sx: {
                ".MuiInputBase-root": {
                  border: "2px solid transparent !important",

                  width: "100%",
                  outlined: "none",
                  "& input": {
                    paddingInline: "7.5px !important",
                  },
                  "@media (max-width: 900px)": {
                    "& input": {
                      /* Add your media query styles for the input element here */
                      // For example:
                      paddingInline: "5px !important",
                    },
                  },
                  ".MuiButtonBase-root": {
                    paddingLeft: "0px !important",
                    padding: "0px !important",
                    ".MuiSvgIcon-root": {
                      fill: "#0575E6",
                      paddingRight: "3px !important",
                      marginRight: "4px !important",
                    },
                  },
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: field
                    ? "transparent"
                    : "rgba(233, 236, 239, 1)",
                  border: error && "1px solid red !important",
                  "& .MuiOutlinedInput-input": {
                    backgroundColor: field
                      ? "transparent"
                      : "rgba(233, 236, 239, 1)",
                  },

                  //   "&:hover fieldset": {
                  //     backgroundColor: "#EFF1F7",
                  //   },
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};
export default SingleDatePicker;

import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/slices/adminSlice";
import { setActive } from "../../redux/slices/teamSlice";
import CareerTable from "../../components/careerTable/CareerTable";

const CareerSection = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedTab(4));
    dispatch(setActive(4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <CareerTable />
    </Box>
  );
};

export default CareerSection;

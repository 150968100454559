/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { pakflag } from "../../../../../../assets/images";

const wrapper = (colors) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  background: colors?.formBgColor || "#527AFC",
  borderRadius: "4px",
});
const input = (colors) => ({
  mb: "5px",
  minHeight: "58px",
  borderRadius: "4px",

  "& .MuiOutlinedInput-root": {
    background: `${colors?.inputBgColor}` || "#4668D4",
    border: "none",

    "& .MuiOutlinedInput-input": {
      background: `${colors?.inputBgColor}` || "#4668D4",
      color: `${colors?.inputTextColor}` || "#fff",
      fontStyle: "normal",
      borderRadius: "4px",
      border: "none",
      "&:focus": {
        border: "none",
      },
    },
  },

  ".MuiFormHelperText-root": {
    color: "#ab2828",
  },

  ".Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ab2828",
  },

  ".MuiSvgIcon-root": {
    color: "#3f6dfd",
    position: "absolute",
    right: "-2%",
  },
});

const feed = (colors) => ({
  marginTop: "0px",
  marginBottom: "1px",
  minHeight: "140px",
  borderRadius: "4px",

  "& .MuiOutlinedInput-root": {
    background: `${colors?.inputBgColor}` || "#4668D4",

    "& .MuiOutlinedInput-input": {
      background: `${colors?.inputBgColor}` || "#4668D4",
      color: `${colors?.inputTextColor}` || "#fff",
      fontStyle: "normal",
      borderRadius: "4px",
      "&:focus": {
        border: "none",
      },
    },
  },

  ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ab2828",
  },

  ".MuiFormHelperText-root": {
    color: "#ab2828",
  },
});

const contentBox = {
  width: "90%",
  m: "16px 0px",
};

const form = {
  width: "100%",
  maxWidth: "1900px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const btnStyle = (colors) => ({
  width: "100%",
  background: colors?.btnBgColor || "#fff",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: 1.79,
  color: colors?.btnTextColor || "#3f6dfd",
  "svg.MuiCircularProgress-svg.css-1idz92c-MuiCircularProgress-svg": {
    color: "#fff",
  },
});

const FormBanner = ({ bannerData }) => {
  return (
    <Box sx={{ width: { xs: "100%", sm: "80%", md: "400px", lg: "30%",xl:"30%" } }}>
      <Box sx={wrapper(bannerData)}>
        <Box sx={contentBox}>
          <form style={form}>
            <TextField
              sx={input(bannerData)}
              multiline={false}
              placeholder="Full Name"
              type="text"
              // variant="outlined"
              fullWidth
              color="primary"
            />

            <TextField
              sx={input(bannerData)}
              placeholder="Email Address"
              type="text"
              // variant="outlined"
              color="primary"
              fullWidth
            />

            <TextField
              sx={input(bannerData)}
              placeholder="+92"
              color="primary"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      minWidth: "28px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box mt={1}>
                      <img src={pakflag} alt="pakflag" />
                    </Box>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              sx={feed(bannerData)}
              placeholder="Message here"
              multiline
              rows="4.65"
              type="text"
              // variant="outlined"
              color="primary"
              fullWidth
            />

            <Button type="submit" sx={btnStyle(bannerData)} variant="contained">
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default FormBanner;

import "./App.css";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import lightTheme from "./theme/lightTheme";
// import MainLayout from "./layout";
import AppRoutes from "./routes";
import { useLogout } from "./hooks/logout";
import NoInternet from "./components/noInternet/NoInternet";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = useLogout();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { token, loginData, isValidToken } = useSelector(
    (state) => state.login
  );

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const expTime = loginData.exp * 1000;

        if (expTime < Date.now()) {
          handleLogout();
        } else {
          setTimeout(checkTokenExpiration, 1000);
        }
      }
    };
    checkTokenExpiration();

    return () => {
      clearTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  useEffect(() => {
    if (!isValidToken) {
      handleLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidToken]);
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);
  useEffect(() => {
    if (token && location?.pathname === "/sign-in" && !loginData?.twoFAStatus) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location, loginData]);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />

      <ThemeProvider theme={lightTheme}>
        <QueryClientProvider client={queryClient}>
          {isOnline ? <AppRoutes /> : <NoInternet />}
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

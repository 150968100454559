// ActionButtons.js
import React from "react";
import { Box, IconButton } from "@mui/material";
// Assuming you have a CustomTooltip component
import PropTypes from "prop-types";
import {
  deleteTableIcon,
  editTableIcon,
  tickIcon,
  viewIcon,
} from "../../assets/images";
import { actionBoxd, icons } from "../tableStyle/style";
import { CustomTooltip } from "../customTooltip";
import { Link } from "react-router-dom/dist";

const ActionButtons = ({
  handleViewClick,
  handleEdit,
  handleDelete,
  Redirectlink,
  item,
}) => {
  return (
    <Box sx={actionBoxd}>
      <>
        <CustomTooltip title={"View Job"}>
          <Link to={`/jobs/${item._id}`} onClick={() => handleViewClick(item)}>
            <IconButton
              sx={icons}
              onClick={() => handleViewClick(item)}
              data-cy="view-Employee"
            >
              <img src={viewIcon} width="15px" height="100%" alt="view icon" />
            </IconButton>
          </Link>
        </CustomTooltip>
      </>

      <>
        <CustomTooltip title={"Update Job"}>
          <Link to={`/edit-job/${item._id}`} onClick={() => handleEdit(item)}>
            <IconButton
              sx={icons}
              onClick={() => handleEdit(item)}
              data-cy="edit-Job"
            >
              <img
                src={editTableIcon}
                width="12px"
                height="100%"
                alt="edit icon"
              />
            </IconButton>
          </Link>
        </CustomTooltip>
      </>

      <>
        {item?.applications === 0 && (
          <CustomTooltip title={"Delete Job"}>
            <IconButton
              sx={icons}
              onClick={() => {
                handleDelete(item);
              }}
              data-cy="delete-Job"
            >
              <img
                src={deleteTableIcon}
                width="12px"
                height="100%"
                alt="edit icon"
              />
            </IconButton>
          </CustomTooltip>
        )}
      </>
      <>
        <CustomTooltip title={"View Applicants"}>
          <Link
            to={`/applicant?id=${item._id}`}
            onClick={() => Redirectlink(item)}
          >
            <IconButton
              sx={icons}
              data-cy="view-Employee"
              onClick={() => Redirectlink(item)}
            >
              <img src={tickIcon} width="15px" height="100%" alt="view icon" />
            </IconButton>
          </Link>
        </CustomTooltip>
      </>
    </Box>
  );
};

ActionButtons.propTypes = {
  handleViewClick: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  Redirectlink: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default ActionButtons;

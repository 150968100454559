import axios from "axios";
import { REACT_APP_API_URL } from "../helper/envUrl";

//====get all==========

export const fetchData = async (paramData, token,handleLogout) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/team/all`, {
      params: paramData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Handle successful response here, e.g., return data
    return response;
  } catch (error) {

    if(error?.response?.status === 401){
      handleLogout();
    }
    // Handle errors here
    console.error("An error occurred while fetching data:", error);
    throw error; // Optional: Rethrow the error to propagate it to the caller
  }
};

//====deleteEmployee==========

export const deleteEmployee = async ({ id, token }) => {
  return await axios
    .delete(`${process.env.REACT_APP_API_URL}/api/team/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data; // Modify this based on your API response structure
    });
};

//===========addEmployeeData==========

export const addEmployeeData = async ({ formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("name", formData.name);
  bodyFormData.append("photo", formData.photo);
  bodyFormData.append("department", formData.department);
  bodyFormData.append("designation", formData.designation);
  bodyFormData.append("category", formData.category);
  bodyFormData.append("type", formData.type);
  bodyFormData.append("gender", formData.gender);
  bodyFormData.append("level", formData.level);
  bodyFormData.append("linkedInUrl", formData.linkedInUrl);
  bodyFormData.append("description", formData.description);

  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/team`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};
//===========editEmployeeData==========

export const editEmployeeData = async ({ id, formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("name", formData.name);
  bodyFormData.append("photo", formData.photo);
  bodyFormData.append("department", formData.department);
  bodyFormData.append("designation", formData.designation);
  bodyFormData.append("category", formData.category);
  bodyFormData.append("type", formData.type);
  bodyFormData.append("gender", formData.gender);
  bodyFormData.append("level", formData.level);
  bodyFormData.append("linkedInUrl", formData.linkedInUrl);
  bodyFormData.append("description", formData.description);
  return await axios
    .patch(`${process.env.REACT_APP_API_URL}/api/team/${id}`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      const data = res.data;
      return data;
    });
};

import React from "react";
import { Typography, Box, Container, IconButton } from "@mui/material";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteIcone, editTableIcon } from "../../../../../assets/images";
import { setProfessional } from "../../../../../redux/slices/servicePageSlice";
import { useDispatch } from "react-redux";

const root = (data) => ({
  width: "100%",
  pt: 2,
  backgroundColor: data?.bgColor,
});

const slickSettings = {
  dots: false,
  speed: 2500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoplay: true,
  prevArrow: null,
  nextArrow: null,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    { breakpoint: 800, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    { breakpoint: 460, settings: { slidesToShow: 1, slidesToScroll: 1 } },
  ],
};

const sliderBg = (data) => ({
  backgroundColor: data?.sliderBgColor,
  position: "relative",
  py: { xs: 6, md: 9 },
});

const expCard = {
  outline: "none",
  height: { xs: "100%", sm: "100%", md: "400px" },
  width: "100% !important",
  backgroundColor: "transparent",
  position: "relative",
  margin: "0 0 !important",
};

const backImage = {
  position: "absolute",
  height: 346,
  width: 900,
  right: -88,
  zIndex: 1,
  bottom: 0,
  top: 70,
  display: { xs: "none", sm: "none", md: "block" },
};

const text = {
  width: { xs: "100%", sm: "100%", md: "50%", lg: "45%" },
  height: { xs: 400, sm: 300, md: 318, lg: 318 },
  mt: "30px",
};
const designationStyle = (data) => ({
  mb: 1.5,
  color: data?.descColor,
  fontSize: 16,
});

const slide = {
  position: "relative",
  zIndex: "5",
};

const body = (data) => ({
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  alignItems: "center",
  gap: { xs: "24px", md: "54px" },
  maxWidth: { xs: "500px", md: "962px" },
  background: data?.sliderBgColor,
  margin: "0 auto",
});

const bodyImg = {
  borderRadius: "50%",
};

const avatar = {
  height: { xs: "200px", md: "250px" },
  width: { xs: "200px", md: "250px" },
};

const bodyContent = {
  display: "flex",
  flexDirection: "column",
  alignItems: { xs: "center", md: "flex-start" },
};

const nameStyles = (color) => ({
  color: color,
  fontSize: { xs: "24px", md: "30px" },
  textAlign: { xs: "center", md: "left" },
  lineHeight: "1.33",
  letterSpacing: "1.5px",
  mb: "6px",
});

const designationStyles = (color) => ({
  color: color,
  mb: 3,
  fontWeight: "normal",
  lineHeight: "1.5",
  fontSize: { xs: "16px", md: "18px" },
  textAlign: { xs: "center", md: "left" },
});

const socialImage = {
  cursor: "pointer",
  display: "inline-block",
  mb: 3,
};

const description = (color) => ({
  color: color,
  lineHeight: 1.7,
  letterSpacing: "0.5px",
});

const CTO = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <Box sx={{ mt: 2, position: "relative" }}>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 3,
          zIndex: 1,
          display: location.pathname === "/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => navigate("/professionals")}
      >
        <img src={editTableIcon} width="12px" height="12px" alt="edit icon" />
      </IconButton>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 30,
          zIndex: 1,
          display: location.pathname === "/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => dispatch(setProfessional([]))}
      >
        <img src={deleteIcone} width="12px" height="12px" alt="edit icon" />
      </IconButton>
      <Box sx={root(data?.length > 0 && data[0])} component="section">
        <Box sx={sliderBg(data?.length > 0 && data[0])}>
          <Box sx={backImage}>
            {/* <img
              src={"/images/ctoBg.webp"}
              fill
              sizes="(max-width: 768px) 100vw,
                   (max-width: 1200px) 50vw,
                   33vw"
              alt="background"
              style={{
                objectFit: "cover",
              }}
            /> */}
          </Box>
          <Box>
            <SlickSlider {...slickSettings}>
              {data?.length > 0 &&
                data?.map((item, index) => (
                  <Box key={index} sx={slide}>
                    <Container key={index}>
                      <Box sx={body(data)}>
                        <Box sx={bodyImg}>
                          <Box
                            component="img"
                            src={item?.image}
                            alt={item?.name}
                            sx={avatar}
                          />
                        </Box>

                        <Box sx={bodyContent}>
                          <Typography
                            variant="h3"
                            sx={nameStyles(item?.nameColor)}
                          >
                            {item?.name}
                          </Typography>

                          <Typography
                            variant="text"
                            component="h6"
                            sx={designationStyles(item?.desiColor)}
                          >
                            {item.designation}
                          </Typography>

                          <Box sx={socialImage}>
                            <a
                              target="_blank"
                              href={item.linkedInUrl}
                              rel="noreferrer"
                            >
                              <img
                                src={item?.linkedInIcon}
                                width={40}
                                height={40}
                                alt="background"
                              />
                            </a>
                          </Box>

                          <Typography sx={description(item?.descColor)}>
                            {item?.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Container>
                  </Box>
                ))}
            </SlickSlider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CTO;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import {
  validationAddTagSchema,
  validationTagSchema,
} from "../../../../../utils/Schema";
import {
  setAddProductTags,
  setAddTags,
  setNewProduct,
} from "../../../../../redux/slices/servicePageSlice";
import { uploadImage } from "../../../../../api/Career";
import ImgBox from "../../../../../components/imgBox/ImgBox";
import ColorField from "../../../../../components/colorField/ColorField";
import { plus } from "../../../../../assets/images";

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const field = { minHeight: "90px" };

const btnbox = {
  // display: "flex",
  // justifyContent: "space-between",
  // alignItems: "center",
};
const AddNewProducts = ({
  tokenCardsErrors,
  tokenCardsTouched,
  setValue,
  productValue,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [inputKey, setInputKey] = useState(0);
  const [newTag, setNewTag] = useState("");
  const [index, setindex] = useState("");
  const { addProductTags, product, products } = useSelector(
    (state) => state.page
  );

  const initialValues = {
    name: "",
    description: "",
    nameColor: "",
    descColor: "",
    imgUrl: "",
    BgColor: "",
    tags: [],
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
    setindex("");
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationAddTagSchema,
    onSubmit: (values) => {
      if (location.pathname === "/edit-Product" && index !== "") {
        const editedIndex = addProductTags.findIndex((item, i) => i === index);
        const newProfessional = [...addProductTags];
        if (editedIndex !== -1) {
          newProfessional[editedIndex] = values;
          dispatch(setAddProductTags(newProfessional));
          setValue("products ", newProfessional);
          handleClose();
        }
      } else {
        dispatch(setAddProductTags([...addProductTags, values]));
        setValue("products ", [...addProductTags, values]);
        handleClose();
      }
    },
  });

  const handleImageChange = async (event, image) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const imageUrl = await uploadImage(file);

      if (imageUrl) {
        setFieldValue(image, imageUrl);
        setInputKey(inputKey + 1);
      }
    } catch (error) {}
  };

  const handleDrop = async (event, image) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append(image, selectedImage);
    // setSelectedFileLoading(true);
    try {
      const imageUrl = await uploadImage(selectedImage);

      if (imageUrl) {
        setFieldValue(image, imageUrl);
      }
    } catch (error) {}
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = (index) => {
    // const updatedTags = [...values.tags];
    // updatedTags.splice(index, 1);
    // setFieldValue("tags", updatedTags);
    const updatedTags = addProductTags.filter((item, i) => i !== index);
    dispatch(setAddProductTags(updatedTags));
  };
  const handleDeleteTag = (index) => {
    const updatedTags = [...values.tags];
    updatedTags.splice(index, 1);
    setFieldValue("tags", updatedTags);
  };

  const handleAddTag = () => {
    if (newTag.trim() !== "") {
      setFieldValue("tags", [...values.tags, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleEdit = (item, i) => {
    setFieldValue("name", item?.name);
    setFieldValue("nameColor", item?.nameColor);
    setFieldValue("description", item?.description);
    setFieldValue("descColor", item?.descColor);
    setFieldValue("BgColor", item?.BgColor);
    setFieldValue("imgUrl", item?.imgUrl);
    setFieldValue("tags", item?.tags);
    setindex(i);
    setOpen(true);
    // setFieldValue("tags", updatedTags);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    touched,
    errors,
    setFieldValue,
    resetForm,
  } = formik;


  return (
    <Box sx={{ mt: "0rem" }}>
      <Box sx={btnbox}>
        <Box
          variant="contained"
          sx={{
            border: `1px dashed #0575E6`,
            cursor: "pointer",
            borderRadius: "4px",
            width: "100%",
            background: "#0575E61A",
            minHeight: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
          onClick={() => setOpen(true)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={plus} alt="drop" width="30px" />
            <Typography variant="h6" sx={{ color: "#000", mt: "5px" }}>
              Add Products
            </Typography>
          </Box>
        </Box>
        {tokenCardsTouched?.products && Boolean(tokenCardsErrors?.products) && (
          <FormHelperText error sx={{ pl: "10px" }}>
            {tokenCardsTouched?.products && tokenCardsErrors?.products}
          </FormHelperText>
        )}
      </Box>

      <Box
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", margin: "10px 0px" }}
      >
        {addProductTags?.map((itm, i) => (
          <Box
            key={i}
            sx={{
              p: "5px 20px",
              borderRadius: "30px",
              display: "flex",
              alignItems: "center",
              gap: 1,
              background: `${itm.BgColor}`,
            }}
          >
            <Typography
              sx={{
                color: `${itm.nameColor}`,
              }}
            >
              {itm.name}:
            </Typography>
            <Typography
              sx={{
                color: `${itm.descColor}`,
              }}
            >
              {itm.description}
            </Typography>
            <CloseIcon
              fontSize="20px"
              sx={{ cursor: "pointer", background: "#fff" }}
              onClick={() => handleDelete(i)}
            />
            <EditOutlinedIcon
              fontSize="20px"
              sx={{ cursor: "pointer", background: "#fff" }}
              onClick={() => handleEdit(itm, i)}
            />
          </Box>
        ))}
      </Box>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "25px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "25px",
              "@media (max-width: 480px)": {
                fontSize: "20px",
              },
            }}
          >
            {location.pathname === "/edit-Product"
              ? "Edit Products"
              : "Add Products"}
          </Typography>
          <Box
            onClick={() => handleClose()}
            sx={{ cursor: "pointer" }}
            data-cy="add-tages-CloseIcon"
          >
            <CloseIcon />
          </Box>
        </DialogTitle>
        <Divider />
        <Box
          sx={{
            p: { xs: "20px", md: "20px" },
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={field}>
              <Typography>Name*</Typography>
              <TextField
                fullWidth
                type="text"
                name="name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>

            <Box sx={field}>
              <Typography>Name Color*</Typography>
              <ColorField
                fullWidth
                name="nameColor"
                placeholder="Name Color"
                type="color"
                value={values.nameColor}
                onChange={handleChange}
                error={Boolean(touched.nameColor && errors.nameColor)}
                helperText={touched.nameColor && errors.nameColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>

            <Box sx={field}>
              <Typography>Description Color*</Typography>
              <ColorField
                fullWidth
                type="color"
                placeholder="Description Color"
                name="descColor"
                value={values.descColor}
                onChange={handleChange}
                error={Boolean(touched.descColor && errors.descColor)}
                helperText={touched.descColor && errors.descColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
            <Box sx={field}>
              <Typography>Background Color*</Typography>
              <ColorField
                fullWidth
                type="color"
                placeholder="BgColor"
                name="BgColor"
                value={values.BgColor}
                onChange={handleChange}
                error={Boolean(touched.BgColor && errors.BgColor)}
                helperText={touched.BgColor && errors.BgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>

            <Box sx={field}>
              <Typography>Image*</Typography>
              <ImgBox
                inputKey={inputKey}
                values={values}
                setFieldValue={setFieldValue}
                touched={touched}
                errors={errors}
                handleFileChange={handleImageChange} // Your file change handler
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                fieldName="imgUrl" // Field name for this file
                acceptedFileTypes={["image/*"]} // Accepted file types
                maxResolution="1024 x 1024 px" // Maximum resolution
                width="100%"
              />
            </Box>
            <Box>
              <Typography>Add Tags*</Typography>
              <TextField
                type="text"
                placeholder="Add Tags"
                fullWidth
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                error={Boolean(touched.tags && errors.tags)}
                helperText={touched.tags && errors.tags}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={handleAddTag}
                      sx={{ background: "#0575E6" }}
                    >
                      <Box sx={{ width: "20px", height: "20px" }}>
                        <img
                          src={plus}
                          alt="Color Picker"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <Button variant="contained" onClick={handleAddTag} sx={{ my: 2 }}>
                add
              </Button> */}

              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                  margin: "10px 0px",
                }}
              >
                {values.tags.map((itm, i) => (
                  <Box
                    key={i}
                    sx={{
                      p: "5px 20px",
                      borderRadius: "30px",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      border: "1px solid #000",
                    }}
                  >
                    <Typography sx={{ wordBreak: "break-all" }}>
                      {itm}
                    </Typography>

                    <CloseIcon
                      fontSize="20px"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleDeleteTag(i)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography>Description*</Typography>
              <TextField
                type="text"
                name="description"
                placeholder="Description"
                fullWidth
                value={values.description}
                onChange={handleChange}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                multiline
                minRows={4}
                maxRows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClose()}
                sx={{ maxWidth: "90px" }}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ maxWidth: "90px" }}
              >
                submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AddNewProducts;

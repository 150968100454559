import { useFormik } from "formik";
import { useNavigate } from "react-router";

const useCareerFilterForm = (setDrawerfilter, handleDrawerClose) => {
  const navigate = useNavigate();
  const initialValues = {
    department: "", // Initial value is set to 'crypto'
    designation: "",
    jobLocaion: "",
    postDateFrom: null,
    postDateTo: null,
    dueDateFrom: null,
    dueDateTo: null,
    jobType: "",
  };

  const handleSubmit = (values) => {
    // Handle form submission logic

    // Your custom logic with formValues

    setDrawerfilter(values);
    const filteredValues = Object?.keys(values)?.reduce((acc, key) => {
      if (values[key] !== "" && values[key] !== null) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    //  setdrawerfilter(filteredValues);
    // If there are any non-empty and non-null values, update state and handle form submission
    if (Object?.keys(filteredValues)?.length > 0) {
      navigate("/jobs?page=1");
      handleDrawerClose();
    } else {
    }

    return values; // Return the form values
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const resetForm = () => {
    formik.resetForm();
    formik.handleSubmit(); // Trigger form submission if needed
  };
  const resetSpecificValue = (fieldName) => {
    if (
      fieldName === "postDateFrom" ||
      fieldName === "postDateTo" ||
      fieldName === "dueDateFrom" ||
      fieldName === "dueDateTo"
    ) {
      formik.setFieldValue(fieldName, null);
    } else {
      formik.setFieldValue(fieldName, "");
    }

    formik.handleSubmit();
  };
  return { formik, resetForm, resetSpecificValue };
};

export default useCareerFilterForm;

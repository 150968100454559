import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedItem: null,
  active: null,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setSelectItem(state, action) {
      state.selectedItem = action.payload;
    },
    setActive(state, action) {
      state.active = action.payload;
    },
  },
});

export const { setSelectItem, setActive } = teamSlice.actions;

export default teamSlice.reducer;

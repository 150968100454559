import { Box, FormHelperText, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { drop } from "../../assets/images";

const firstBox = { width: { sx: "100%", md: "50%" } };
const imgBox = (isImage) => ({
  borderRadius: "4px",
  width: isImage ? "120px" : "100%",
  background: "#0575E61A",
  height: isImage ? "120px" : "auto",
  display: "flex",
  justifyContent: "space-between",
  border: "1px dashed #0575E6",
  position: "relative",
});
const boxWidth = { height: "100%", width: "100%" };
const selectedimg = (touched, errors) => ({
  border: `1px dashed ${touched.image && errors.image ? "red" : "#0575E6"}`,
  cursor: "pointer",
  borderRadius: "4px",
  width: "100%",
  background: "#0575E61A",
  minHeight: "70px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
});
const dropImg = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const errorMessage = { paddingLeft: "5px", mt: 0, fontSize: "12px" };
const ImgBox = (props) => {
  const {
    inputKey,
    values,
    setFieldValue,
    touched,
    errors,
    handleFileChange,
    handleDragOver,
    handleDrop,
    fieldName,
    acceptedFileTypes,
    maxResolution,
    width,
  } = props;

  const isImage = acceptedFileTypes.includes("image/*");

  return (
    <Box sx={{ width: { sx: "100%", md: width ? "100%" : "50%" } }}>
      {values[fieldName] ? (
        <Box sx={imgBox(isImage)}>
          <Box sx={boxWidth}>
            {typeof values[fieldName] === "string" ? (
              isImage ? (
                <img
                  src={values[fieldName]}
                  alt="file"
                  width="100%"
                  height="100%"
                />
              ) : (
                <Typography variant="body1">
                  {values[fieldName].name}
                </Typography>
              )
            ) : isImage ? (
              <img
                src={URL.createObjectURL(values[fieldName])}
                alt="file"
                width="100%"
                height="100%"
              />
            ) : (
              <Typography
                variant="body1"
                sx={{ wordBreak: "break-all", mt: "20px" }}
              >
                {values[fieldName].name}
              </Typography>
            )}
          </Box>
          <Box sx={{ position: "absolute", right: 0 }}>
            <CloseIcon
              onClick={() => {
                setFieldValue(fieldName, "");
              }}
              sx={{
                width: "15px",
                cursor: "pointer",
                fill: "#0575E6",
                background: "#fff",
              }}
            />
          </Box>
        </Box>
      ) : (
        <label
          style={selectedimg(touched, errors)}
          htmlFor={`${fieldName}-input`}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, fieldName)}
        >
          <Box sx={dropImg}>
            <img src={drop} alt="drop" width="30px" />
            <Typography variant="h6" sx={{ color: "#000", mt: "5px" }}>
              Click to choose your file, or drop it here
            </Typography>
          </Box>
        </label>
      )}

      <input
        id={`${fieldName}-input`}
        type="file"
        accept={acceptedFileTypes.join(",")}
        key={inputKey}
        style={{ display: "none" }}
        onChange={(e) => {
          handleFileChange(e, fieldName);
        }}
      />
      {maxResolution && (
        <Typography variant="h6" mt={0.5}>
          Maximum resolution is {maxResolution}
        </Typography>
      )}
      <Box sx={{ height: "20px", width: "100%" }}>
        {touched[fieldName] && Boolean(errors[fieldName]) && (
          <FormHelperText error sx={errorMessage}>
            {touched[fieldName] && errors[fieldName]}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default ImgBox;

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { logoIcon, logoutIcon } from "../../../assets/images";

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { adminData } from "../..";
import { useLogout } from "../../../hooks/logout";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "../../../redux/slices/teamSlice";
import { Link } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  borderTopRightRadius: "12px",
  borderBottomRightRadius: "12px",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  zIndex: 1,
});

const closedMixin = (theme) => ({
  borderTopRightRadius: "12px",
  borderBottomRightRadius: "12px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.5s",
  }),
  overflowX: "hidden",
  zIndex: 1,
  width: `0px`,
  [theme.breakpoints.up("sm")]: {
    width: `0px`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    borderRadius: "0 10px 10px 0",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const dividerStyle = {
  height: "2px",
  background: "rgba(246, 246, 246, 1)",
  width: "85%",
  borderRadius: "10px",
  margin: "auto",
  border: "none",
};

const sectionStyle = (open) => {
  return {
    dispaly: "flex",
    alignItems: "center",
    justifyContent: open ? "initial" : "flex-start",
    px: 2.5,
  };
};

const logoSection = {
  display: "flex",
  alignItems: "center",
  minHeight: "62px",
};

const logoBox = {
  width: "45px",
  height: "45px",
  cursor: "pointer",
  ml: "-5px",
};

const logoText = (open) => {
  return {
    pl: 1.5,
    cursor: "pointer",
    transition: "0.5s all",
    opacity: open ? 1 : 0,
  };
};

const menuListStyle = {
  height: "90vh",
  overflowY: "auto",
  overflowX: "hidden",
};

const buttonBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "40px",
  padding: "25px 0px",
  cursor: "pointer",
};

const iconTextBox = {
  display: "flex",
  alignItems: "center",
};

const iconBox = (open) => {
  return {
    ml: !open && "2px",
    // mr: open && "8px",
    transition: "0.5s all",
  };
};

const sideIconStyle = (open) => {
  return {
    width: "28px",
    height: "28px",
    border: "2px solid rgba(246, 246, 246, 1)",
    borderRadius: "10px",
    position: "absolute",
    left: open ? 225 : -50,
    top: 95,
    opacity: open ? 1 : 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffff",
    zIndex: 1,
    transition: "0.5s all",
    cursor: "pointer",
  };
};

const logoutStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "40px",
  padding: "25px 0px",
  cursor: "pointer",
};

const logoutTextStyle = {
  display: "flex",
  alignItems: "center",
};

const logoutIconStyle = (open) => {
  return {
    width: "20px",
    height: "20px",
    ml: open ? 0 : 1,
    mr: 1,
    transition: "0.5s all",
  };
};

const logoutText = (open) => {
  return {
    transition: "0.5s all",
    opacity: open ? 1 : 0,
  };
};

export default function MiniDrawer(props) {
  const { open, setAnchorEl1, setOpen, handleDrawerOpen } = props;
  const navigate = useNavigate();
  const handleLogout = useLogout();
  const dispatch = useDispatch();
  const { active } = useSelector((state) => state.team);

  const [collapse, setCollapse] = React.useState(false);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const [flag, setFlag] = React.useState(false);

  const handleClick = (index, item) => {
    !item?.options?.length && setOpen(false);
    dispatch(setActive(index));
    index?.toString() !== active?.toString() && setFlag(item?.open);
    index?.toString() === active?.toString() && setFlag(!flag);
    open ? setAnchorEl1(false) : setAnchorEl1(true);
    open ? setCollapse(!collapse) : setCollapse(false);
    item?.path && navigate(item?.path);
  };

  return (
    <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open} onClose={handleDrawerOpen}>
        {/* drawer header --------- */}
        <Box sx={sectionStyle(open)}>
          <Box sx={logoSection}>
            <Box sx={logoBox} onClick={() => navigate("/dashboard")}>
              <img
                src={logoIcon}
                alt="magnus mage"
                width="50px"
                height="50px"
              />
            </Box>

            <Typography
              variant="h2"
              sx={logoText(open)}
              onClick={() => navigate("/dashboard")}
            >
              Magnus Mage
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box sx={dividerStyle} />
        </Box>

        {/* drawer side menu list section----- */}
        <List sx={menuListStyle}>
          <Box sx={sectionStyle(open)}>
            {adminData?.map((item, index) => (
              <Box position="relative" key={index}>
                <Link
                  to={item.path}
                  style={{ textDecoration: "none" }}
                  key={index}
                  sx={buttonBox}
                  data-cy="sidedrwer-active"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleClick(index, item)}
                >
                  <Box sx={iconTextBox}>
                    <IconButton sx={iconBox(open)}>
                      <img
                        src={
                          active?.toString() === index?.toString()
                            ? item?.activeImage
                            : hoveredIndex === index
                            ? item?.activeImage
                            : item?.image
                        }
                        alt="magnus mage"
                        width="20px"
                        height="20px"
                      />
                    </IconButton>
                    <Typography
                      variant="h6"
                      sx={{
                        transition: "0.5s all",
                        fontFamily:
                          active?.toString() === index?.toString()
                            ? "Poppins-Medium"
                            : "Poppins-Regular",
                        opacity: open ? 1 : 0,
                        color:
                          active?.toString() === index?.toString() ||
                          hoveredIndex === index
                            ? "rgba(5, 117, 230, 1)"
                            : "rgba(116, 121, 130, 1)",
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </Box>
                </Link>
              </Box>
            ))}
          </Box>
        </List>

        {/* logout button------ */}
        <List>
          <Box sx={sectionStyle(open)}>
            <Box
              sx={logoutStyle}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Box sx={logoutTextStyle} onClick={handleLogout}>
                <Box sx={logoutIconStyle(open)}>
                  <img
                    src={logoutIcon}
                    alt="magnus mage"
                    width="20px"
                    height="20px"
                  />
                </Box>
                <Typography variant="subtitle5" sx={logoutText(open)}>
                  Logout
                </Typography>
              </Box>
            </Box>
          </Box>
        </List>
      </Drawer>
      <Box sx={sideIconStyle(open)} onClick={handleDrawerOpen}>
        {open ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
      </Box>
    </Box>
  );
}

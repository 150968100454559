import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import TwofaSetting from "./component/TwofaSetting";
import PasswordSetting from "./component/PasswordSetting";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/slices/adminSlice";
import { setActive } from "../../redux/slices/teamSlice";

//============style=============

const root = { width: "97%", m: "17px auto 0px" };
const mainBox = {
  display: "flex",
  gap: 2,
  flexDirection: { xs: "column", md: "row" },
};
const prBox = { width: { xs: "100%", md: "30%", lg: "25%" } };
const basic = {
  borderBottom: "1px solid #E9ECEF",
  background: "#fff",
  borderRadius: "4px 4px 0 0 ",
  height: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  pl: "16px",
};

const tabsStyle = {
  position: "relative",
  minHeight: { xs: "60px", md: "78vh" },
  height: "90%",
  backgroundColor: "#fff",
  borderBottom: { xs: "1px solid #DDDDDD", md: 0 },
  "& .MuiTabs-indicator": {
    backgroundColor: "#4899fd",
    right: 0,
    mt: 2.5,
    ml: 10,
    width: "0px ",
    height: "0px ",
    borderRadius: "50%",
  },
  "& .MuiTab-root": {
    opacity: 1,
    padding: "5px 16px",
    "&.Mui-selected": {
      backgroundColor: "#0575E6", // Set the background color of the active tab to black
      minWidth: { xs: "290px", md: "100%" },
      padding: "5px 16px",
    },
  },
  "& .MuiTabScrollButton-root": {
    display: { xs: "inline-flex", md: "none" },
  },
  "& .MuiButtonBase-root-MuiTab-root": {
    padding: "5px 10px",
  },
};
const singleTab = {
  display: "inline-flex",
  alignItems: "center",
  position: "relative",
  height: "40px",
  color: "#202c40",
  minWidth: "150px",
  width: "100%",
  justifyContent: "space-between",
};

const rightBox = {
  width: { xs: "100%", md: "68.5%", lg: "73.5%" },
  background: "#fff",
  p: "10px 16px",
  borderRadius: { xs: "0px", md: "4px" },
};

const TabPanel = ({ children, value, index }) => {
  return value === index ? <>{children}</> : null;
};

//============component=============

const Setting = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(setSelectedTab(0));
    dispatch(setActive(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={root}>
      <Box sx={mainBox}>
        <Box sx={prBox}>
          <Box sx={basic}>
            <Typography variant="h1">Basic Settings</Typography>
          </Box>
          <Tabs
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={tabsStyle}
          >
            <Tab
              label={
                <Box sx={singleTab}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: value === 0 ? "#fff" : "#666666",
                      fontFamily:
                        value === 0 ? "Poppins-SemiBold" : "Poppins-Regular",
                    }}
                  >
                    2FA Authentication
                  </Typography>
                </Box>
              }
            />
            <Tab
              label={
                <Box sx={singleTab}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: value === 1 ? "#fff" : "#666666",
                      fontFamily:
                        value === 1 ? "Poppins-SemiBold" : "Poppins-Regular",
                    }}
                  >
                    Change password
                  </Typography>
                </Box>
              }
            />
          </Tabs>
        </Box>
        <Box sx={rightBox}>
          <TabPanel value={value} index={0}>
            <TwofaSetting />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PasswordSetting />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default Setting;

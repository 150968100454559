import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import FormBanner from "../formBanner/FormBanner";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { fetchSinglePageData, geturl } from "../../../../../../api/ServicePage";
import animated_icon from "../../../amimationFile/animated_data.json";
// BannerPreview component Styling || SS means small screen---------------------

const root = (bannerData) => ({
  width: "100%",
  // height: { xs: "1200px", sm: "880px", md: "750px" },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: bannerData?.bgColor || "#3F6DFD",
  position: "relative",
});

const backgroundImage = {
  position: "absolute",
  height: 329,
  width: 190,
  top: 200,
  left: 0,
};
const textBox = {
  p: "40px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  flexWrap: { xs: "wrap", sm: "wrap", md: "wrap", lg: "nowrap" },
  // width: "90%",
};
const heading = (bannerData) => ({
  color: bannerData?.titleColor,
  textAlign: "left",
  lineHeight: { xs: "25px", sm: "56px", md: "56px" },
  fontSize: { xs: "20px", sm: "32px", md: "42px" },
});
const textSectionn = (bannerData) => ({
  fontSize: { xs: "12px", sm: "14px", md: "16px" },
  textAlign: "left",
  color: bannerData?.descColor,
  mt: 1,
  width: { xs: "90%", sm: "80%", md: "70%" },
  lineHeight: { xs: 1.5, sm: 1.5, md: 1.5 },
});

const BannerPreview = ({ data }) => {
  const location = useLocation();
  const { name: Name } = useParams();
  const dispatch = useDispatch();
  const [lot, setlot] = useState(null);
  // const [lottiefileData, setLottiefileData] = useState(null);

  const { banner } = useSelector((state) => state.page);

  // useEffect(() => {
  //   if (data.lottiefile && banner.lottiefile) {
  //     setLottiefileData(JSON.stringify(banner.lottiefile)); // Update lottiefileData state with the new object
  //   }
  // }, [data, banner]);

  // const { data: data2 } = useQuery({
  //   queryKey: ["dataPageSing"],
  //   queryFn: () => fetchSinglePageData(Name, dispatch),
  //   keepPreviousData: true,
  //   enabled: !Name,
  //   notifyOnChangeProps: ["data", "error"],

  // });

  useEffect(() => {
    async function fetchData() {
      try {
        // Wait for geturl to resolve the promise
      
        if (
          data?.lottiefile &&
          typeof data?.lottiefile === "object" &&
          "v" in data?.lottiefile &&
          "fr" in data?.lottiefile &&
          "ip" in data?.lottiefile &&
          "op" in data?.lottiefile &&
          "w" in data?.lottiefile
        ) {
          setlot(data?.lottiefile);
        } else if (
          typeof data?.lottiefile === "string" &&
          (data?.lottiefile.startsWith("http://") ||
            data?.lottiefile.startsWith("https://"))
        ) {
          const datal = await geturl(data?.lottiefile);
          // Log the resolved data
          if (
            datal &&
            typeof datal === "object" &&
            "v" in datal &&
            "fr" in datal &&
            "ip" in datal &&
            "op" in datal &&
            "w" in datal
          ) {
            // If `datal` matches the expected format, set it using `setlot`
            setlot(datal);
          } else {
            setlot(data?.lottiefile);
          }
        }
        
      } catch (error) {
        // Handle any errors that occur during the fetch
        console.error("Error fetching lottiefile data:", error);
      }
    }

    // Only fetch data if data?.lottiefile is a non-null, non-undefined value
    if (data?.lottiefile) {
      fetchData();
    }
  }, [data?.lottiefile]);

  const lottiefileData = JSON.stringify(lot);

  return (
    <Box sx={root(data)}>
      <Box sx={backgroundImage}>
        <img
          src={data?.bgIcon || "/images/bannerBg1.webp"}
          style={{ objectFit: "contain" }}
          alt="background"
        />
      </Box>

      <Box sx={textBox}>
        <Box
          sx={{
            width: { xs: "90%", sm: "70%", md: "36%", lg: "36%" },
            zIndex: 1,
          }}
        >
          <Typography variant="h2" sx={heading(data)}>
            {data?.title}
          </Typography>
          <Typography variant="h2" sx={heading(data)}></Typography>
          <Typography variant="body1" sx={textSectionn(data)}>
            {data?.description}
          </Typography>
        </Box>
        {/* {data?.lottiefile ? (
          <Box
            sx={{
              "& .react_lottie": {
                height: { xs: 310, sm: 375, md: 475, lg: 475 },
                width: { xs: 310, sm: 375, md: 475, lg: 475 },
              },
            }}
          >
            <Lottie
              animationData={JSON.parse(lottiefileData)}
              className="react_lottie"
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: { xs: 310, sm: 375, md: 475, lg: 475 },
              width: { xs: 310, sm: 375, md: 475, lg: 475 },
            }}
          >
            <img
              src={data?.image}
              alt="react_lottie image"
              width="100%"
              height="100%"
            />
          </Box>
        )} */}

        {data?.lottiefile ? (
          typeof lot === "string" &&
          (lot.startsWith("http://") || lot.startsWith("https://")) ? (
            <Box
              sx={{
                height: { xs: 310, sm: 375, md: 475, lg: 475 },
                width: { xs: 310, sm: 375, md: 475, lg: 450 },
              }}
            >
              <img src={lot} alt="Image" width="100%" height="100%" />
            </Box>
          ) : (
            <Box
              sx={{
                "& .react_lottie": {
                  height: { xs: 310, sm: 375, md: 475, lg: 475 },
                  width: { xs: 310, sm: 375, md: 475, lg: 450 },
                },
              }}
            >
              <Lottie
                animationData={JSON.parse(lottiefileData)}
                className="react_lottie"
              />
            </Box>
          )
        ) : (
          ""
        )}

        <FormBanner bannerData={data} />
      </Box>
    </Box>
  );
};

export default BannerPreview;

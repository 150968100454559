import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";


const useAuth = () => {
    const { token } = useSelector((state) => state.login);
    if (token) {
        return true;
    } else {
        return false;
    }
};
const PrivateRoutes = () => {
    const auth = useAuth();

    return auth ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default PrivateRoutes;

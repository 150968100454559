import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedEmail: null,
  replayData: null,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSelectedEmail(state, action) {
      state.selectedEmail = action.payload;
    },
    setReplayData(state, action) {
      state.replayData = action.payload;
    },
  },
});

export const { setReplayData, setSelectedEmail } = chatSlice.actions;

export default chatSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  loginData: null,
  twoFaReq: null,
  logout: "",
  twoFa: false,
  isValidToken: true,
};

export const loginSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setLoginData(state, action) {
      state.loginData = action.payload;
    },
    setTwoFa(state, action) {
      state.twoFaReq = action.payload;
    },
    setLogOut(state, action) {
      state.logout = action.payload;
    },
    setTwoFaCheck(state, action) {
      state.twoFa = action.payload;
    },
    setIsValidToken: (state, action) => {
      state.isValidToken = action.payload;
    },
  },
});

export const {
  setToken,
  setLoginData,
  setTwoFa,
  setLogOut,
  setTwoFaCheck,
  setIsValidToken,
} = loginSlice.actions;

export default loginSlice.reducer;

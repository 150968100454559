import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import adminreducer from "./slices/adminSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import themereducer from "./slices/themeSlice";
import chatreducer from "./slices/chatSlice";
import teamreducer from "./slices/teamSlice";
import loginreducer from "./slices/loginSlice";
import servicePagereducer from "./slices/servicePageSlice";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const persistConfig = {
  key: "root",
  storage,
};

// Define your app's reducers
const appReducers = combineReducers({
  login: loginreducer,
  admin: adminreducer,
  theme: themereducer,
  chat: chatreducer,
  team: teamreducer,
  page: servicePagereducer,
  // Add more reducers here if needed
});

// Define the root reducer that handles logout
const rootReducer = (state, action) => {
  if (action.type === "data/setLogOut") {
    state = undefined;
  }
  return appReducers(state, action);
};

// Create the persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);


// Configure and create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      createStateSyncMiddleware({
        // Specify configuration here
        blacklist: ['persist/PERSIST'], // Ignore specified actions
      })
    ),
});

// Initialize message listener
initMessageListener(store);

export default store;

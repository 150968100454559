import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { useDeviceSize } from "../../helper/hooks";


const SliderStyle = {
  width: "26rem",
  height: "21.60rem",
};

const mobileSliderStyle = {
  width: "100%",
  height: "17rem",
};

const TabSliderStyle = {
  width: "100%",
  height: "100%",
};

export default function Slider({ setCurrentSlideIndex, data }) {
  const width = useDeviceSize();
  const theme = useTheme();
  const isMobile = window.innerWidth > 450 && window.innerWidth <= 650;
  const isMobile500 = window.innerWidth <= 450;
  const slideStyle = isMobile
    ? TabSliderStyle
    : isMobile500
    ? mobileSliderStyle
    : SliderStyle;

  const handleSlideChange = (swiper) => {
    setCurrentSlideIndex(swiper.realIndex);
  };

  const imageStyle = {
    width: width < 400 ? 300 : 430,
    height: 330,
  };

  return (
    <Box
      sx={{
        width: "-webkit-fill-available",
      }}
    >
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          bgcolor: "#fff",
          ".swiper-slide.swiper-slide-visible.swiper-slide-prev": {
            filter: "blur(5px)",
            opacity: { xs: 1, sm: 1, md: 0, lg: 0 },
            cursor: "none",
          },
          ".swiper-slide.swiper-slide-visible.swiper-slide-next": {
            filter: "blur(2px)",
          },
          ".swiper-3d .swiper-slide-shadow-left": { backgroundImage: "none" },
          ".swiper-3d .swiper-slide-shadow-right": { backgroundImage: "none" },
          ".swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal":
            {
              display: "none",
            },
          //   ".swiper-slide img": imageStyle,
        }}
      >
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
          onSlideChange={handleSlideChange}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index} style={slideStyle}>
              <img
                src={item.imgSrc}
                width={imageStyle.width}
                height={imageStyle.height}
                alt="background"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Box>
  );
}

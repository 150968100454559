import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
function noop() {}
(() => {
  if (process.env.NODE_ENV === "production") {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
  }
})();

const persister = persistStore(store);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persister}>
        <Provider store={store}>
          <App />
        </Provider>
      </PersistGate>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { searchIcon } from "../../../assets/images";
import { useNavigate } from "react-router";
import useDeviceSize from "../../../hooks/windowResize";
import { useQuery } from "react-query";
import { contactData } from "../../../api/ContactUs";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { setSelectedEmail } from "../../../redux/slices/chatSlice";
import Loader from "../../../components/loader/Loader";
import NoData from "../../../components/noData/NoData";
import { useLogout } from "../../../hooks/logout";
import { setSelectedTab } from "../../../redux/slices/adminSlice";

//=============style==================

const root = {
  background: { xs: "#fff", md: "transparent" },
  p: { xs: "10px", md: "0px" },
  borderRadius: "4px",
};

const searchfieldStyle = {
  display: "flex",
  "& .MuiOutlinedInput-root": {
    height: "34px",
  },
};

const searchButtonStyle = {
  width: "36px",
  height: "33px",
  display: "flex",
  background: "#E9ECEF",
  justifyContent: "center",
  borderTopRightRadius: "3px",
  borderBottomRightRadius: "3px",
  marginRight: "-14px",
  cursor: "pointer",
};

const userList = {
  background: "#fff",
  border: { xs: "1px solid #E9ECEF" },
  borderRadius: "4px",
  height: { xs: "100%", md: "85vh" },
  pb: 2,
};

const listBox = {
  width: "100%",
  minHeight: "85px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
};

const readFilter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "98%",
  m: "0 auto",
  p: "0px 5px",
};
const boxChick = {
  fill: "#0575E6 !important",
  p: "9px 0px",
  "& .css-me6fx7-MuiSvgIcon-root": {
    fill: "#0575E6 !important",
    fontSize: "1rem !important",
  },
};
const unreadBox = {
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  background: "#0575E6",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const text = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap", // Prevents text from wrapping
  maxWidth: {
    xs: "120px",
    sm: "190px",
    md: "70px",
  },
};
const nameText = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  textTransform: "capitalize",
  whiteSpace: "nowrap", // Prevents text from wrapping
  maxWidth: {
    xs: "120px",
    sm: "190px",
    md: "70px",
    lg: "90px",
    xl: "170px",
  },
};

const imgbox = {
  width: 30,
  height: 30,
  textTransform: "capitalize",
};
const listitm = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
};

const leftBorder = (selectedEmail, user) => ({
  width: "3px",
  height: "58px",
  background: selectedEmail === user.email ? " #0575E6" : "",
  mt: "-1px",
});
const userClick = (selectedEmail, user) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "57px",
  alignItems: "center",
  borderBottom: "1px solid #E9ECEF",
  cursor: "pointer",
  width: "100%",
  backgroundColor: selectedEmail === user.email ? "#EFF1F7" : "transparent",
  p: "10px 15px",
  "&:hover": {
    background: "#EFF1F7",
  },
});

const buttons = ["website", "ads"];

const timeBox = (user) => ({
  display: "flex",
  justifyContent: user.num ? "center" : "flex-end",
  alignItems: "flex-end",
  flexDirection: "column",
  height: "100%",
});

//=============component==================

const UserList = ({ filter, setFilter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const size = useDeviceSize();
  const handleLogout = useLogout();
  const { token } = useSelector((state) => state.login);
  const { selectedEmail } = useSelector((state) => state.chat);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [active, setActive] = React.useState(0);

  const handleChangeSwitch = (event) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      setFilter({ ...filter, read: "" });
    } else {
      setFilter({ ...filter, read: false });
    }
  };

  const handleItemSelect = (itemId) => {
    dispatch(setSelectedEmail(itemId));
    if (size.width < 899) {
      navigate(`/contact/${itemId}`);
    }
  };

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setSearchTerm(e.target.value.toLowerCase());
    } else {
      setFilter({ ...filter, name: "" });
    }
  };

  const handleSearch = () => {
    setFilter({ name: searchTerm });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const setFilterHandler = (item, index) => {
    // dispatch(setSelectedTab(0));
    setActive(index);
    setFilter({ ...filter, read: "", resource: item === "ads" ? "ads" : "" });
  };

  //===================get all data api=============
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["dataContact", filter],
    queryFn: () => contactData(filter, token, handleLogout),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
  });
  const res = data?.data?.data.result;

  return (
    <Box sx={root}>
      <Box sx={userList}>
        <Box sx={listBox}>
          <Box sx={{ p: "15px 15px 8px" }}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h1">Contacts List</Typography>
              <ButtonGroup
                disabled={false}
                size="small"
                // variant="outlined"
                sx={{
                  width: 120,
                  outline: "none !important",
                  "& .MuiButton-outlined":{
                    borderColor: "#E8E9E9",
                  }
                }}
              >
                {buttons.map((item, index) => (
                  <Button
                    size="amall"
                    sx={{
                      fontSize: 12,
                      height: 32,
                      background: active === index && "#0575E6",
                      color: active === index ? "#fff" : "#000000FF",
                    }}
                    onClick={() => setFilterHandler(item, index)}
                    key={index}
                  >
                    {item}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
            <Box mt={1}>
              <TextField
                placeholder="Search By Name"
                fullWidth
                sx={searchfieldStyle}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                inputProps={{
                  "data-cy": "search-EmployeeTable",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={searchButtonStyle}
                        onClick={handleSearch}
                        data-cy="search-button-Employee"
                      >
                        <img
                          src={searchIcon}
                          style={{ width: "13px" }}
                          alt="visibleon"
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={readFilter}>
                <Typography variant="h4">Unread</Typography>

                <Checkbox
                  checked={checked}
                  onChange={handleChangeSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={boxChick}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            height: { xs: "100%", md: "65vh" },
            overflow: "scroll",
            background: "#fff",
          }}
        >
          {isLoading || isFetching ? (
            <Loader />
          ) : !res?.length ? (
            <NoData />
          ) : (
            <Box>
              {res &&
                res?.map((user) => (
                  <Box
                    key={user.email}
                    sx={{
                      display: "flex",
                      "&:hover": {
                        background: "#EFF1F7",
                      },
                    }}
                  >
                    <Box sx={leftBorder(selectedEmail, user)} />
                    <Box
                      onClick={() => handleItemSelect(user.email)}
                      sx={userClick(selectedEmail, user)}
                    >
                      <Box sx={listitm}>
                        <Avatar sx={imgbox}> {user?.name?.charAt(0)} </Avatar>
                        <Box>
                          <Typography variant="h4" sx={nameText}>
                            {user.name}
                          </Typography>
                          <Box>
                            <Typography variant="h6" sx={text}>
                              {user?.message}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={timeBox(user)}>
                        {" "}
                        {user.unRead > 0 ? (
                          <Box sx={unreadBox}>
                            <Typography variant="h6" sx={{ color: "#fff" }}>
                              {user.unRead}
                            </Typography>
                          </Box>
                        ) : null}
                        <Typography variant="h6">
                          {dayjs(user.updatedAt).format("HH:mm A")}{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserList;

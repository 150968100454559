import axios from "axios";
import { REACT_APP_API_URL } from "../helper/envUrl";

//====get all==========
export const contactData = async (paramData, token, handleLogout) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/contactUs`,
      {
        params: paramData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle successful response here, e.g., return data
    return response;
  } catch (error) {
    if (error?.response?.status === 401) {
      handleLogout();
    }
    // Handle errors here
    console.error("An error occurred while fetching contact data:", error);
    throw error; // Optional: Rethrow the error to propagate it to the caller
  }
};

//====get all messages==========
export const messagesData = async (token, email, ads) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/api/contactUs/${email}?resource=${ads}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
//====get profileData==========
export const profileData = async (token, email, ads) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/api/contactUs/profile?email=${email}&resource=${ads}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
//====setMessages==========
export const setMessages = async ({ formdata, token, idData }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("mark", formdata.mark);
  bodyFormData.append("message", formdata.message);
  bodyFormData.append("filePath", formdata.filePath);
  return await axios.patch(
    `${process.env.REACT_APP_API_URL}/api/contactUs/${idData}`,
    bodyFormData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

import { Box } from "@mui/material";
import React from "react";
import EmployeeTable from "../../components/table/Table";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/slices/adminSlice";
import { setActive } from "../../redux/slices/teamSlice";

const TeamSection = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedTab(0));
    dispatch(setActive(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <EmployeeTable />
    </Box>
  );
};

export default TeamSection;

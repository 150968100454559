import React, { useEffect } from "react";
import Card from "../../../components/card";
import Heading from "../../../components/heading/Heading";
import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import dayjs from "dayjs";
import { formatJobType } from "../../../helper/helper";

//=============style=================

const root = { p: { xs: "10px", sm: "20px" } };
const detail = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  alignItems: { xs: "flex-start", sm: "center" },
  minHeight: "40px",
  padding: "10px 0px",
  height: "auto",
};
const detaildest = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  alignItems: "flex-start",
  minHeight: "40px",
  padding: "10px 0px",
  height: "auto",
};

const boxDirection = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  width: "100%",
};

const datailBox = {
  height: "auto",
  width: { xs: "100%", md: "100%" },
  p: "10px",
  pl: { xs: "0px", md: "16px" },
};

const titleHeading = {
  color: "#202C40",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "capitalize",
  width: { xs: "220px", sm: "350px " },
};
const titleHeadingdes = {
  color: "#202C40",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "capitalize",
  width: { xs: "220px", sm: "350px " },
  mt: { xs: "0px", md: "0px" },
};

//=============component=================

const ViewCareer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedItem } = useSelector((state) => state.team);

  useEffect(() => {
    if (id !== selectedItem._id) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card>
      <Box sx={root}>
        <Heading text="Details " />
        <Box sx={boxDirection}>
          <Box sx={datailBox}>
            <Box sx={detail}>
              <Typography sx={titleHeading}>Image</Typography>
              <img
                src={selectedItem.image}
                alt="profile"
                style={{ maxWidth: "90px" }}
              />
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Job Title</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {selectedItem.title}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Job Location</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {selectedItem.jobLocation}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Department Name</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {selectedItem.department === "sqa"
                  ? "SQA"
                  : selectedItem.department}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Designation</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {" "}
                {selectedItem.designation}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Positions</Typography>
              <Typography variant="h6"> {selectedItem?.positions}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Applications</Typography>
              <Typography variant="h6"> {selectedItem?.applications}</Typography>
            </Box>
            <Divider />

            <Box sx={detail}>
              <Typography sx={titleHeading}>job Type</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {formatJobType(selectedItem.jobType)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>job Status</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {formatJobType(selectedItem.jobStatus)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Date Posted </Typography>
              <Typography variant="h6">
                {" "}
                {dayjs(new Date(selectedItem.postDate)).format(
                  "DD MMM YYYY"
                )}{" "}
              </Typography>
            </Box>
            <Divider />

            <Box sx={detail}>
              <Typography sx={titleHeading}>Deadline</Typography>
              <Typography variant="h6">
                {dayjs(new Date(selectedItem.dueDate)).format("DD MMM YYYY")}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Location</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {selectedItem.city}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Country</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {selectedItem.country}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detaildest}>
              <Typography sx={titleHeadingdes}>Description</Typography>
              <Box
                sx={{
                  "& h1": { fontSize: "18px", margin: "0px !important" },
                  "& span": { fontSize: "18px", margin: "0px !important" },
                  "& h2": { fontSize: "16px", margin: "0px !important" },
                  "& h3": { fontSize: "14px", margin: "0px !important" },
                  "& h4": { fontSize: "14px", margin: "0px !important" },
                  "& h5": { fontSize: "16px", margin: "0px !important" },
                  "& h6": { fontSize: "12px", margin: "0px !important" },

                  "& p": {
                    margin: "0",
                    fontFamily: "Poppins-Regular",
                    fontSize: "12px",
                    fontWeight: "normal",

                    color: "#666666",
                    // textTransform: "capitalize",
                    "& strong": {
                      // Style for the 'strong' element inside 'p' tag
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "black", // specify the color you want
                      // Add any other styles you want for the 'strong' element
                    },
                  },
                  "& ul": { paddingLeft: "1.5em" },
                  "& li": {
                    marginBottom: "0.2em",
                    fontFamily: "Poppins-Regular",
                    fontSize: "12px",
                    fontWeight: "normal",

                    color: "#666666",
                    // textTransform: "capitalize",
                    "& span": {
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px !important",
                      fontWeight: "normal",

                      color: "#666666 !important",
                    },
                  },

                  "& strong": { fontWeight: "bold", marginBottom: "0px" },
                  "& a": { color: "#007bff", textDecoration: "underline" },
                  maxWidth: { xs: "100%", sm: "40%", md: "50%" },
                  height: "auto",
                }}
              >
                {parse(selectedItem.description)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ViewCareer;

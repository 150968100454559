import React from "react";
import BannerPreview from "../servicePage/component/banner/component/bannerPreview/BannerPreview";
import { useSelector } from "react-redux";
import Fact from "../servicePage/component/token/component/Fact";
import { Box } from "@mui/material";
import Portfolio from "../servicePage/component/product/component/Portfolio";
import CTO from "../servicePage/component/professionals/component/CTO";
import LetGetStarted from "../servicePage/component/getStarted/component/LetGetStarted";

const Preview = () => {
  const { banner, tokenPage, product, getStarted, professional } = useSelector(
    (state) => state.page
  );

  return (
    <Box>
      <BannerPreview data={banner} />
      <Fact data={tokenPage} />
      <Portfolio data={product} />
      <CTO data={professional} />
      <LetGetStarted data={getStarted} />
    </Box>
  );
};

export default Preview;

import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTokenValidationSchema } from "../../../../../../utils/Schema";
import Card from "../../../../../../components/card";
import Heading from "../../../../../../components/heading/Heading";
import ImgBox from "../../../../../../components/imgBox/ImgBox";
import {
  setAddTags,
  setTokenPage,
} from "../../../../../../redux/slices/servicePageSlice";
import { uploadImage } from "../../../../../../api/Career";
import AddTags from "../addTags/AddTags";
import ColorField from "../../../../../../components/colorField/ColorField";
import { CustomTooltip } from "../../../../../../components/customTooltip";

//=============style=================

const main = { p: "20px" };

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};

const root = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const dispBox = {
  display: "flex",
  flexWrap: "wrap",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const clButton = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

//=============component=================

const AddToken = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [inputKey, setInputKey] = useState(0);
  const { addTags, tokenPage } = useSelector((state) => state.page);
  const initialValues = {
    bgColor: "",
    title: "",
    topLeftIcon: "",
    BottomRightIcon: "",
    titleColor: "",
    descColor: "",
    description: "",
    tokenCards: [],
  };

  const handleImageChange = async (event, image) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const imageUrl = await uploadImage(file);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
        setInputKey(inputKey + 1);
      }
    } catch (error) {}
  };

  const handleDrop = async (event, image) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append(image, selectedImage);
    // setSelectedFileLoading(true);
    try {
      const imageUrl = await uploadImage(selectedImage);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
      }
    } catch (error) {}
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  //=======formick====

  const formik = useFormik({
    initialValues,
    validationSchema: addTokenValidationSchema,
    onSubmit: (values) => {
      const formData = {
        bgColor: values.bgColor,
        title: values.title,
        topLeftIcon: values.topLeftIcon,
        titleColor: values.titleColor,
        descColor: values.descColor,
        BottomRightIcon: values.BottomRightIcon,
        description: values.description,
        tokenCards: values.tokenCards,
      };

      dispatch(setTokenPage(formData));
      navigate(-1);
    },
  });

  useEffect(() => {
    dispatch(setAddTags([]));
    if (
      location.pathname === "/edit-token" &&
      tokenPage &&
      Object.keys(tokenPage).length > 0
    ) {
      setFieldValue("bgColor", tokenPage?.bgColor);
      setFieldValue("title", tokenPage?.title);
      setFieldValue("topLeftIcon", tokenPage?.topLeftIcon);
      setFieldValue("BottomRightIcon", tokenPage?.BottomRightIcon);
      setFieldValue("titleColor", tokenPage?.titleColor);
      setFieldValue("descColor", tokenPage?.descColor);
      setFieldValue("description", tokenPage?.description);
      setFieldValue("tokenCards", tokenPage?.tokenCards);

      dispatch(setAddTags(tokenPage?.tokenCards));
    }
  }, []);

  useEffect(() => {
    setFieldValue("tokenCards", addTags);
  }, [addTags]);

  const handlePreview = () => {
    const formData = {
      bgColor: values.bgColor,
      title: values.title,
      topLeftIcon: values.topLeftIcon,
      titleColor: values.titleColor,
      descColor: values.descColor,
      BottomRightIcon: values.BottomRightIcon,
      description: values.description,
      tokenCards: values.tokenCards,
    };

    dispatch(setTokenPage(formData));
  };

  const handleBlur = () => {
    handlePreview(); // Call handleSubmit onBlur
  };

  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  useEffect(() => {
    handleBlur();
  }, [values.topLeftIcon, values.BottomRightIcon, values.tokenCards]);

  return (
    <Card>
      <Box sx={main}>
        <form onSubmit={handleSubmit}>
          <Heading
            text={
              location.pathname === "/add-token" ? "Add Token" : "Update Token"
            }
          />

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Background Color for Token Section"}>
                <Typography>Background Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="bgColor"
                name="bgColor"
                type="color"
                value={values.bgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.bgColor && errors.bgColor)}
                helperText={touched.bgColor && errors.bgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Token Section Title"}>
                <Typography>Title*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Box>
          </Box>

          <Box sx={root}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Icon for Top Left Corner of Token Section"}>
                <Typography>Left Icon*</Typography>
              </CustomTooltip>
            </Box>

            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fieldName="topLeftIcon" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Title Color for Token Section"}>
                <Typography>Title Color* </Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="titleColor"
                name="titleColor"
                type="color"
                value={values.titleColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.titleColor && errors.titleColor)}
                helperText={touched.titleColor && errors.titleColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Description Color for Token Section"}>
                <Typography>Description Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="Description Color"
                name="descColor"
                type="color"
                value={values.descColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.descColor && errors.descColor)}
                helperText={touched.descColor && errors.descColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Icon for Bottom Right Corner of Token Section"}>
                <Typography>Bottom Right Icon*</Typography>
              </CustomTooltip>
            </Box>
            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fieldName="BottomRightIcon" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>

          <Box sx={dispBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Token Cards Section"}>
                <Typography>Token Cards*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <AddTags
                tokenCardsErrors={errors}
                tokenCardsTouched={touched}
                setValue={setFieldValue}
              />
            </Box>
          </Box>

          <Box sx={dispBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Enter Description for Token Section"}>
                <Typography>Description*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                minRows={4}
                maxRows={4}
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Box>

          {/* Add a Button for submitting the data */}
          <Box sx={clButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              cancel
            </Button>
            <Link
              to={{
                pathname: "/preview",
                state: { item: values.lottiefile },
              }}
              onClick={handlePreview}
              target="_blank"
            >
              <Button variant="contained" sx={{ maxWidth: "90px" }}>
                preview
              </Button>
            </Link>
            <Button variant="contained" type="submit" sx={{ maxWidth: "90px" }}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default AddToken;

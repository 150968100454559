import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import {
  deleteTableIcon,
  editTableIcon,
  plus,
} from "../../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSelectItem } from "../../../../redux/slices/teamSlice";
import { OpenDeleteModal } from "../../../../redux/slices/adminSlice";
import Card from "../../../../components/card";
import DeleteModal from "../../../../components/modals/DeleteModal";
import { setProfessional } from "../../../../redux/slices/servicePageSlice";
import Heading from "../../../../components/heading/Heading";

const main = { p: "20px" };
const icons = {
  color: "blue",
  padding: "6px",
  fontSize: "1rem",
};
const clButton = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
  mt: 2,
};

const Professionals = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { professional } = useSelector((state) => state.page);

  const handleEditSub = (itemData, i) => {
    const data = {
      itemData,
      index: i,
    };
    navigate("/edit-professionals", { state: { item: data } });
  };

  const handleOpenSub = (i) => {
    dispatch(OpenDeleteModal(true));
  };
  const handleDeleteSub = (i) => {
    const updatedProfessional = [...professional];
    updatedProfessional.splice(i, 1);
    dispatch(setProfessional(updatedProfessional));
  };
  return (
    <Card>
      <Box sx={main}>
        <Heading text="Professionals List" />

        <Grid
          container
          //   justifyContent="center"
          spacing={2}
          sx={{
            width: "100%",
            mt: "30px",
            ml: { xs: "-8px", md: "-16px" },
          }}
        >
          {professional &&
            professional?.map((item, i) => (
              <Grid item xs={12} sm={6} lg={4} key={i}>
                <Box
                  sx={{
                    height: "100%",
                    p: " 5px 10px 10px",
                    boxShadow: "3px 4px 7px 0px #0000000A",
                    borderRadius: "8px",
                    width: "100%",
                    border: "1px solid #E9ECEF",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <>
                      <IconButton
                        sx={icons}
                        onClick={() =>
                          // navigate(`/edit-sub-services/${item._id}`)
                          handleEditSub(item, i)
                        }
                      >
                        <img
                          src={editTableIcon}
                          width="12px"
                          height="100%"
                          alt="edit icon"
                        />
                      </IconButton>
                    </>

                    <>
                      <IconButton
                        sx={icons}
                        // onClick={() => handleOpenSub(i)}
                        onClick={() => handleDeleteSub(i)}
                        data-cy="delete-Employee"
                      >
                        <img
                          src={deleteTableIcon}
                          width="12px"
                          height="100%"
                          alt="edit icon"
                        />
                      </IconButton>
                    </>
                  </Box>
                  <Box align="center" mt={0.5}>
                    <img
                      src={item.image}
                      alt="img"
                      width="25px"
                      height="100%"
                    />
                  </Box>
                  <Typography
                    variant="h3"
                    align="center"
                    my={0.5}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="h6" align="center">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            ))}

          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            onClick={() => navigate("/add-professionals")}
            // navigate("/add-professionals", { state: { item: item._id } })
          >
            <Box
              sx={{
                height: "100%",
                minHeight: "200px",
                p: "10px",
                borderRadius: "8px",
                border: `1px dashed #0575E6`,
                background: "#0575E61A",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
                cursor: "pointer",
              }}
            >
              <Box align="center">
                <img src={plus} alt="plus" />
              </Box>
              <Typography variant="h2" align="center" color="#0575E6">
                Add Professionals
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {professional.length > 0 && (
          <Box sx={clButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              cancel
            </Button>

            <Link
              to="/preview"
              target="_blank"
            >
              <Button variant="contained" sx={{ maxWidth: "90px" }}>
                preview
              </Button>
            </Link>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              submit
            </Button>
          </Box>
        )}
      </Box>

      <DeleteModal handleDelete={handleDeleteSub} />
    </Card>
  );
};

export default Professionals;

import React, { useState } from "react";
import { bg1, bg2, logo } from "../../assets/images";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-hot-toast";
import { verifyTwoFA } from "../../api/Auth";
import { setTwoFaCheck } from "../../redux/slices/loginSlice";

//=============style=================

const root = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  position: "relative",
};
const imgBox = { position: "absolute", top: 0, right: 0 };
const imgBox1 = { position: "absolute", bottom: 0, left: 0 };
const main = {
  pt: 3,
  pl: 4,
  pr: 4,
  pb: 3,
  display: "flex",
  alignItems: "left",
  justifyContent: "space-between",
  flexDirection: "column",
  minHeight: "350px",
  width: { xs: "280px", sm: "350px" },
  border: "1px solid #E9ECEF",
  borderRadius: "8px",
};

const logoStyle = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const logoBox = { width: "70px", height: "70px" };
const helperTextStyle = {
  height: "15px",
  width: "100%",
  mb: "10px",
};
const errorStyle = { paddingLeft: "5px", mt: 0, fontSize: "13px" };

const codeInputBox = {
  "& .MuiOutlinedInput-input": {
    padding: " 7.5px 0px 7.5px 0px !important",
    maxHeight: "25px !important",
    maxWidth: "50px !important",
    width: "100%",
    height: "100%",
  },
  "& ::placeholder": {
    pl: "43%",
  },
};

//=============component=================

const GoogleTwoFa = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.login);
  const [otp, setOtp] = React.useState("");
  const [empty, setEmpty] = useState(false);

  const handleChangeOtp = (newValue) => {
    setEmpty(false);
    setOtp(newValue);
  };

  const { mutate, isLoading } = useMutation(verifyTwoFA, {
    onSuccess: (data) => {
      dispatch(setTwoFaCheck(true));
      navigate("/");
      toast.success("Login successfully");
    },

    onError: (error) => {
      dispatch(setTwoFaCheck(false));
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  const handleEnableDisable = () => {
    if (otp !== "" && otp.length === 6) {
      const dataRes = {
        code: otp,
      };
      mutate({ dataRes, token, dispatch });
    } else {
      setEmpty(true);
    }
  };

  return (
    <Box sx={root}>
      <Box sx={imgBox}>
        <img src={bg1} alt="magnus mage" />
      </Box>
      <Box sx={imgBox1}>
        <img src={bg2} alt="magnus mage" width="80%" />
      </Box>
      <Box sx={main}>
        <Box>
          <Box sx={logoStyle}>
            <Box sx={logoBox}>
              <img src={logo} alt="magnus mage" width="100%" height="100%" />
            </Box>
          </Box>
          <Typography variant="h1" align="center">
            Google Authentication 2FA
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            A 6-digit code has been sent , valid for 2 minutes. This session
            will expire after the allotted time for security.
          </Typography>
        </Box>
        <Box>
          <Box mb={1}>
            <MuiOtpInput
              length={6}
              value={otp}
              display="flex"
              justifyContent="center"
              gap={1}
              onChange={handleChangeOtp}
              TextFieldsProps={{
                placeholder: "-",
              }}
              sx={codeInputBox}
            />
          </Box>
          <Box sx={helperTextStyle}>
            {empty && (
              <FormHelperText error sx={errorStyle}>
                Code is required
              </FormHelperText>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            sx={{ textTransform: "none" }}
            fullWidth
            onClick={() => navigator("/sign-in")}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={handleEnableDisable}
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              "Done"
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleTwoFa;

import React from "react";
import { Box, MenuItem, TextField, Typography } from "@mui/material";

const ReusableSelect = ({
  values,
  handleChange,
  touched,
  errors,
  options,
  name,
  edit,
  labeltext,
}) => {
  return (
    <Box sx={{ width: { sx: "100%", md: "50%" } }}>
      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: 1 }}>
        <TextField
          select
          fullWidth
          name={name}
          value={values}
          onChange={handleChange}
          sx={{
            "& .MuiSelect-icon": {
              fill: "#0575E6 !important",
              fontSize: "30px",
            },
          }}
          error={Boolean(touched && errors)}
          helperText={touched && errors}
          SelectProps={{
            displayEmpty: true,
            renderValue: (selected) => {
              if (!selected) {
                return (
                  <Typography
                    sx={{
                      color: "#666666",
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                    }}
                  >
                    {edit ? values : labeltext ? labeltext : "Select category"}
                  </Typography>
                );
              }
              const selectedOption = options.find(
                (option) => option.value === selected
              );
              return selectedOption ? selectedOption.label : "";
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              sx={{
                color: "#666666",
                fontFamily: "Poppins-Regular",
                fontSize: "12px",
              }}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
};

export default ReusableSelect;

import React from "react";
import { Box, IconButton, Typography } from "@mui/material"; // Import Box and Typography from Material-UI
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Navigate } from "react-router-dom";
import { editTableIcon } from "../../../../assets/images";

const SectionButton = ({
  onClick,
  height,
  minHeight,
  borderColor,
  backgroundColor,
  imageSrc,
  altText,
  text,
  navgatePage,
}) => {
  return (
    <>
      {/* <IconButton

        onClick={}
      >
        <DeleteOutlineIcon />
      </IconButton> */}

      {navgatePage && (
        <>
          <IconButton
            sx={{
              color: "blue",
              padding: "6px",
              fontSize: "1rem",
            }}
            onClick={navgatePage}
            data-cy="delete-Employee"
          >
            <img
              src={editTableIcon}
              width="12px"
              height="100%"
              alt="edit icon"
            />
          </IconButton>
        </>
      )}
      <Box
        onClick={onClick}
        sx={{
          height: height || "100%",
          minHeight: minHeight || "200px",
          p: "10px",
          borderRadius: "8px",
          border: `1px dashed ${borderColor || "#0575E6"}`,
          background: backgroundColor || "#0575E61A",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
          cursor: "pointer",
        }}
      >
        {imageSrc && <img src={imageSrc} alt={altText} />}
        {text && (
          <Typography
            variant="h2"
            align="center"
            color={borderColor || "#0575E6"}
          >
            {text}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default SectionButton;

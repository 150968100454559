import { Box, TableCell, TableRow } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

const boxStyle = (props) => {
  return {
    display: "flex",
    height: props.height,
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  };
};

const TableLoader = (props) => {
  return (
    <TableRow {...props} style={{ borderBottom: "none",background:"#fff" }}>
      <TableCell colSpan={props.colSpan}>
        <Box sx={boxStyle(props)}>
          <CircularProgress size={34} />
        </Box>
      </TableCell>
    </TableRow>
  );
};

TableLoader.defaultProps = {
  height: 10,
  colSpan: 3,
};

export default TableLoader;

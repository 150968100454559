// CustomDisplay.js
import React from "react";
import { Box, IconButton, TableCell, Typography } from "@mui/material";
// Assuming you have a CustomTooltip component
import { CustomTooltip } from "../customTooltip";
import { Docxicon, Pdficon, downloadsvicon } from "../../assets/images";
import { actionBox, linkstyle } from "../tableStyle/style";
import { baseFileName } from "../../helper/helper";
import { Link } from "react-router-dom/dist";

const CustomDisplay = ({
  type,
  data,
  recordStart,
  index,
  handleAction,
  downloadFile,
  icons,
  viewIcon,
}) => {
  const renderContent = () => {
    switch (type) {
      case "file":
        const fileName = baseFileName(data);
        const fileExtension = data.split(".").pop();

        return (
          <CustomTooltip title={`${fileName}.${fileExtension}`}>
            <Box sx={{ display: "flex", gap: "4px" }}>
              <img
                src={fileExtension === "pdf" ? Pdficon : Docxicon}
                alt="File icon"
              />
              <Box
                sx={{
                  display: "flex",
                  gap: "4px",
                  flexDirection: "row",
                }}
                onClick={() => downloadFile(data, recordStart + index)}
              >
                <Typography variant="h6" sx={linkstyle}>
                  {`${fileName}.${fileExtension}`}
                </Typography>
                <img
                  src={downloadsvicon}
                  alt="Download icon"
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </CustomTooltip>
        );

      case "city":
        return (
          <CustomTooltip
            title={
              <Typography sx={{ textTransform: "capitalize" }}>
                {data}
              </Typography>
            }
          >
            <Typography
              variant="h6"
              sx={{
                maxWidth: "80px", // Adjust the width according to your layout
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "capitalize",
              }}
            >
              {data}
            </Typography>
          </CustomTooltip>
        );

      case "action":
        return (
          <Box sx={actionBox}>
            <CustomTooltip title={`View Action`}>
              <Link
                to={`/applicant/${data._id}`}
                onClick={() => handleAction(data)}
              >
                <IconButton
                  sx={icons}
                  onClick={() => handleAction(data)}
                  data-cy={`view-${type}`}
                >
                  <img
                    src={viewIcon}
                    width="15px"
                    height="100%"
                    alt={`view ${type}`}
                  />
                </IconButton>
              </Link>
            </CustomTooltip>
          </Box>
        );

      default:
        return null;
    }
  };

  return <TableCell align="left">{renderContent()}</TableCell>;
};

export default CustomDisplay;

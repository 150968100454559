import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBannerValidationSchema } from "../../../../../../utils/Schema";
import Card from "../../../../../../components/card";
import Heading from "../../../../../../components/heading/Heading";
import ImgBox from "../../../../../../components/imgBox/ImgBox";
import { setBanner } from "../../../../../../redux/slices/servicePageSlice";
import { uploadImage, uploadImagelotify } from "../../../../../../api/Career";
import Lottie from "lottie-react/build";
import { drop } from "../../../../../../assets/images";
import ColorField from "../../../../../../components/colorField/ColorField";
import { CustomTooltip } from "../../../../../../components/customTooltip";
import { geturl } from "../../../../../../api/ServicePage";

//=============style=================

const main = { p: "20px" };

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};

const root = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const dispBox = {
  display: "flex",
  flexWrap: "wrap",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const clButton = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const selectedimg = {
  border: `1px dashed #0575E6`,
  cursor: "pointer",
  borderRadius: "4px",
  width: "100%",
  background: "#0575E61A",
  minHeight: "70px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
};
const dropImg = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

//=============component=================

const AddBanner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [inputKey, setInputKey] = useState(0);
  const [inputKey1, setInputKey1] = useState(0);
  const [loti, setloti] = useState(null);
  const { banner } = useSelector((state) => state.page);
  const initialValues = {
    // name: "",
    title: "",
    lottiefile: "",
    bgIcon: "",
    image: "",
    titleColor: "",
    descColor: "",
    bgColor: "",
    formBgColor: "",
    inputTextColor: "",
    inputBgColor: "",
    btnBgColor: "",
    btnTextColor: "",
    description: "",
    lottiefileName: "",
  };

  const handleImageChange = async (event, image) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const imageUrl = await uploadImage(file);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
        setInputKey(inputKey + 1);
        handleBlur();
      }
    } catch (error) {}
  };

  const handleDrop = async (event, image) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append(image, selectedImage);
    // setSelectedFileLoading(true);
    try {
      const imageUrl = await uploadImage(selectedImage);

      if (imageUrl) {
        formik.setFieldValue(image, imageUrl);
      }
    } catch (error) {}
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChangeFile = (file) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = async (e) => {
        const fileContent = e.target.result;
        try {
          const lottieData = JSON.parse(fileContent);
          const imageUrl = await uploadImagelotify(file);
          setloti(imageUrl);
          if (isLottieFile(lottieData)) {
            formik.setFieldValue("lottiefile", lottieData);
            setInputKey1(inputKey + 1);
          } else {
            formik.setFieldError("lottiefile", "Invalid Lottie file");
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };
    } else {
      console.error("No file selected");
    }
  };

  const isLottieFile = (data) => {
    // You can implement your validation logic here
    // For example, check if the data contains required fields for Lottie files
    return (
      data !== null &&
      typeof data === "object" &&
      "v" in data &&
      "fr" in data &&
      "ip" in data &&
      "op" in data &&
      "assets" in data &&
      "layers" in data
      // Add more conditions if needed
    );
  };

  const handleFileChange = async (e) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      formik.setFieldValue("lottiefileName", file.name);
      // Check if the file is of type JSON
      if (file.type === "application/json") {
        handleChangeFile(file);
        handleBlur();
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        try {
          const imageUrl = await uploadImagelotify(file);

          if (imageUrl) {
            setloti(imageUrl);
            formik.setFieldValue("lottiefile", imageUrl);
            setInputKey(inputKey + 1);
            handleBlur();
          }
        } catch (error) {}
      }
    } else {
      console.error("Invalid file input event");
    }
  };

  //=======formick====

  const formik = useFormik({
    initialValues,
    validationSchema: addBannerValidationSchema,
    onSubmit: (values) => {
      const formData = {
        // name: values.name,
        title: values.title,
        bgColor: values.bgColor,
        lottiefile: loti,
        titleColor: values.titleColor,
        descColor: values.descColor,
        bgIcon: values.bgIcon,
        image: values.image,
        formBgColor: values.formBgColor,
        inputTextColor: values.inputTextColor,
        inputBgColor: values.inputBgColor,
        btnBgColor: values.btnBgColor,
        btnTextColor: values.btnTextColor,
        description: values.description,
        lottiefileName: values.lottiefileName,
      };

      dispatch(setBanner(formData));
      navigate(-1);
      // navigate(`/servicePage`, {
      //   state: { item: values.lottiefile },
      // });
    },
  });

  useEffect(() => {
    if (
      location.pathname === "/edit-banner" &&
      banner &&
      Object.keys(banner).length > 0
    ) {
      // setFieldValue("name", banner?.name);
      setFieldValue("title", banner?.title);
      setFieldValue("bgColor", banner?.bgColor);
      setFieldValue("lottiefile", banner.lottiefile);
      setFieldValue("bgIcon", banner?.bgIcon);
      setFieldValue("image", banner?.image);
      setFieldValue("titleColor", banner?.titleColor);
      setFieldValue("descColor", banner?.descColor);
      setFieldValue("formBgColor", banner?.formBgColor);
      setFieldValue("inputTextColor", banner?.inputTextColor);
      setFieldValue("inputBgColor", banner?.inputBgColor);
      setFieldValue("btnBgColor", banner?.btnBgColor);
      setFieldValue("btnTextColor", banner?.btnTextColor);
      setFieldValue("description", banner?.description);
      setFieldValue("lottiefileName", banner?.lottiefileName);
      setloti(banner.lottiefile);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        // Wait for geturl to resolve the promise

        // Log the resolved data
        if (
          banner.lottiefile &&
          typeof banner.lottiefile === "object" &&
          "v" in banner.lottiefile &&
          "fr" in banner.lottiefile &&
          "ip" in banner.lottiefile &&
          "op" in banner.lottiefile &&
          "w" in banner.lottiefile
        ) {
          // If `datal` matches the expected format, set it using `setlot`
          formik.setFieldValue("lottiefile", banner.lottiefile);
          // console.log(url, "Fetched and set loti data:22", datal);
          const imageUrl = await uploadImagelotify(banner.lottiefile);
          if (imageUrl) {
            setloti(imageUrl);
          }
        } else if (
          typeof banner?.lottiefile === "string" &&
          (banner?.lottiefile.startsWith("http://") ||
            banner?.lottiefile.startsWith("https://"))
        ) {
          const datal = await geturl(banner.lottiefile);

          if (
            datal &&
            typeof datal === "object" &&
            "v" in datal &&
            "fr" in datal &&
            "ip" in datal &&
            "op" in datal &&
            "w" in datal
          ) {
            // If `datal` matches the expected format, set it using `setlot`
            formik.setFieldValue("lottiefile", datal);
            setloti(banner.lottiefile);
          } else {
            formik.setFieldValue("lottiefile", banner.lottiefile);
            setloti(banner.lottiefile);
          }

          // setloti(banner.lottiefile);
          // if (datal) {
          //   formik.setFieldValue("lottiefile", datal);
          // }
        }
      } catch (error) {
        // Handle any errors that occur during the fetch
        console.error("Error fetching lottiefile data:", error);
      }
    }

    // Only fetch data if banner.lottiefile is a non-null, non-undefined value
    if (location.pathname === "/edit-banner" && banner) {
      fetchData();
    }
  }, []);

  const handlePreview = () => {
    const formData = {
      // name: values.name,
      title: values.title,
      bgColor: values.bgColor,
      lottiefile: loti,
      titleColor: values.titleColor,
      descColor: values.descColor,
      bgIcon: values.bgIcon,
      image: values.image,
      formBgColor: values.formBgColor,
      inputTextColor: values.inputTextColor,
      inputBgColor: values.inputBgColor,
      btnBgColor: values.btnBgColor,
      btnTextColor: values.btnTextColor,
      description: values.description,
      lottiefileName: values.lottiefileName,
    };

    dispatch(setBanner(formData));
  };

  const handleBlur = () => {
    handlePreview(); // Call handleSubmit onBlur
  };

  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  useEffect(() => {
    handleBlur();
  }, [values.lottiefile, values.bgIcon, values.image]);

  const lottiefileData = JSON.stringify(values.lottiefile);
  return (
    <Card>
      <Box sx={main}>
        <form onSubmit={handleSubmit}>
          <Heading
            text={
              location.pathname === "/add-banner"
                ? "Add Banner"
                : "Update Banner"
            }
          />

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Enter Banner Section Title Here"}>
                <Typography>Title*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Background Color for Banner Section"}
              >
                <Typography>Background Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                placeholder="bgColor"
                name="bgColor"
                type="color"
                value={values.bgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.bgColor && errors.bgColor)}
                helperText={touched.bgColor && errors.bgColor}
              />
            </Box>
          </Box>

          <Box sx={root}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Lively Lottie Animation for Enhanced Banner Section"}
              >
                <Typography>Lottie File/ Image*</Typography>
              </CustomTooltip>
            </Box>

            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              {/* <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ maxWidth: "100%" }}
              >
                Upload JSON file
                <VisuallyHiddenInput
                  type="file"
                  accept=".json"
                  onChange={handleFileChange}
                />
              </Button> */}

              {values.lottiefile ? (
                <Box
                  sx={{
                    height: "120px",
                    width: "120px",
                    position: "relative",
                    borderRadius: "4px",
                    border: "1px dashed #0575E6",
                  }}
                >
                  {/* {values.lottiefile ? (
                    <Box
                      sx={{
                        "& .react_lottie": {
                          height: { xs: "100%" },
                          width: { xs: "100%" },
                        },
                      }}
                    >
                      <Lottie
                        animationData={JSON.parse(lottiefileData)}
                        className="react_lottie"
                      />
                    </Box>
                  ) : (
                    ""
                  )} */}

                  {values.lottiefile ? (
                    typeof values.lottiefile === "string" &&
                    (values.lottiefile.startsWith("http://") ||
                      values.lottiefile.startsWith("https://")) ? (
                      // Render image
                      <img
                        src={values.lottiefile}
                        alt="Image"
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      // Render Lottie animation
                      <Box
                        sx={{
                          "& .react_lottie": {
                            height: { xs: "100%" },
                            width: { xs: "100%" },
                          },
                        }}
                      >
                        <Lottie
                          animationData={JSON.parse(lottiefileData)}
                          className="react_lottie"
                        />
                      </Box>
                    )
                  ) : (
                    ""
                  )}
                  <Box sx={{ position: "absolute", right: 0, top: 0 }}>
                    <CloseIcon
                      onClick={() => {
                        setFieldValue("lottiefile", "");
                        setloti(null);
                      }}
                      sx={{
                        width: "15px",
                        cursor: "pointer",
                        fill: "#0575E6",
                        background: "#fff",
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <label style={selectedimg} htmlFor="Lottie">
                  <Box sx={dropImg}>
                    <img src={drop} alt="drop" width="30px" />
                    <Typography variant="h6" sx={{ color: "#000", mt: "5px" }}>
                      Click to choose your Lottie File
                    </Typography>
                  </Box>
                </label>
              )}
              <Typography variant="h6" mt={0.5}>
                Choose your Lottie File
              </Typography>

              <input
                id="Lottie"
                type="file"
                accept=".json, image/*"
                key={inputKey1}
                style={{ display: "none" }}
                onChange={handleFileChange}
                onBlur={handleBlur}
              />

              {touched.lottiefile && Boolean(errors.lottiefile) && (
                <FormHelperText error>
                  {touched.lottiefile && errors.lottiefile}
                </FormHelperText>
              )}
            </Box>
          </Box>

          {/* <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Background Icon for Banner Section"}
              >
                <Typography>Image*</Typography>
              </CustomTooltip>
            </Box>
            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              onBlur={handleBlur}
              fieldName="image" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box> */}

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Title Color for Banner Section"}>
                <Typography>Title Color </Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="titleColor"
                name="titleColor"
                type="color"
                value={values.titleColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.titleColor && errors.titleColor)}
                helperText={touched.titleColor && errors.titleColor}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Choose Description Color for Banner Section"}
              >
                <Typography>Description Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="Description Color"
                name="descColor"
                type="color"
                value={values.descColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.descColor && errors.descColor)}
                helperText={touched.descColor && errors.descColor}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip
                title={"Select Background Icon for Banner Section"}
              >
                <Typography>Background Icon*</Typography>
              </CustomTooltip>
            </Box>
            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              onBlur={handleBlur}
              fieldName="bgIcon" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              <CustomTooltip title={"Select Form Background Color"}>
                <Typography>Form Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                name="formBgColor"
                value={values.formBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.formBgColor && errors.formBgColor)}
                helperText={touched.formBgColor && errors.formBgColor}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Button Background Color"}>
                <Typography>Button Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="btnBgColor"
                name="btnBgColor"
                value={values.btnBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.btnBgColor && errors.btnBgColor)}
                helperText={touched.btnBgColor && errors.btnBgColor}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Button Text Color"}>
                <Typography>Button Text*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="btnTextColor"
                name="btnTextColor"
                value={values.btnTextColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.btnTextColor && errors.btnTextColor)}
                helperText={touched.btnTextColor && errors.btnTextColor}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Input Text Color"}>
                <Typography>Input Text*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="inputTextColor"
                name="inputTextColor"
                value={values.inputTextColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.inputTextColor && errors.inputTextColor)}
                helperText={touched.inputTextColor && errors.inputTextColor}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Input Background Color"}>
                <Typography>Input Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="inputBgColor"
                name="inputBgColor"
                value={values.inputBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(touched.inputBgColor && errors.inputBgColor)}
                helperText={touched.inputBgColor && errors.inputBgColor}
              />
            </Box>
          </Box>

          <Box sx={dispBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Enter Description for Banner Section"}>
                <Typography>Description*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                name="description"
                minRows={4}
                maxRows={4}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Box>

          {/* Add a Button for submitting the data */}
          <Box sx={clButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              cancel
            </Button>
            <Link
              to={{
                pathname: "/preview",
                state: { item: values.lottiefile },
              }}
              onClick={handlePreview}
              target="_blank"
            >
              <Button variant="contained" sx={{ maxWidth: "90px" }}>
                preview
              </Button>
            </Link>
            <Button variant="contained" type="submit" sx={{ maxWidth: "90px" }}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default AddBanner;

import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import Card from "../../../components/card";
import { useFormik } from "formik";
import Heading from "../../../components/heading/Heading";
import { AddServicesSchema } from "../../../utils/Schema";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { editServices } from "../../../api/Services";
import { useSelector } from "react-redux";
import ImgBox from "../../../components/imgBox/ImgBox";

//============style=============

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };

const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "60px",
  gap: 1,
};

const inputBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};
const buttonBox = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

//============component=============

const EditServices = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { token } = useSelector((state) => state.login);
  const { selectedItem } = useSelector((state) => state.team);
  const [inputKey, setInputKey] = useState(0);
  const initialValues = {
    title: selectedItem?.title ? selectedItem?.title : "",
    subTitle: selectedItem?.subTitle ? selectedItem?.subTitle : "",
    image: selectedItem?.mainIcon ? selectedItem?.mainIcon : "",
    description: selectedItem?.description ? selectedItem?.description : "",
    subDescription: selectedItem?.subDescription
      ? selectedItem?.subDescription
      : "",
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setFieldValue("image", selectedImage);
    setInputKey(inputKey + 1);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    setFieldValue("image", selectedImage);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  //===================api==============

  const { isLoading, mutate } = useMutation(editServices, {
    onSuccess: (data) => {
      toast.success("Edit services successfully.");
      queryClient.refetchQueries("dataser");
      navigate("/services");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: () => AddServicesSchema(),
    onSubmit: (values) => {
      const formData = {
        title: values.title,
        description: values.description,
        subTitle: values.subTitle,
        subDescription: values.subDescription,
        mainIcon: values.image,
      };
      mutate({ id: selectedItem._id, formData, token });
      navigate("/services");
    },
  });
  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  useEffect(() => {
    if (id !== selectedItem._id) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card>
      <Box sx={{ p: "20px" }}>
        <form onSubmit={handleSubmit}>
          <Heading text="Service Title & Description" />
          <Box sx={inputBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Image*</Typography>
            </Box>

            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fieldName="image" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Title*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Box>
          </Box>
          <Box sx={inputBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Description*</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                name="description"
                minRows={4}
                maxRows={4}
                value={values.description}
                onChange={handleChange}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Sub-Title</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Sub-Title"
                name="subTitle"
                value={values.subTitle}
                onChange={handleChange}
                error={Boolean(touched.subTitle && errors.subTitle)}
                helperText={touched.subTitle && errors.subTitle}
              />
            </Box>
          </Box>
          <Box sx={inputBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Sub-Description</Typography>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Sub-Description"
                multiline
                name="subDescription"
                minRows={4}
                value={values.subDescription}
                onChange={handleChange}
                error={Boolean(touched.subDescription && errors.subDescription)}
                helperText={touched.subDescription && errors.subDescription}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={buttonBox}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ maxWidth: "80px" }}
              onClick={() => navigate(-1)}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              disabled={isLoading}
              type="submit"
              sx={{ maxWidth: "80px" }}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              ) : (
                "submit"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default EditServices;

import { palette } from "./palette";

export const typography = {
  h1: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "31px",
    letterSpacing: "normal",
    textAlign: "left",
    color: palette.primary.dark,
    textTransform: "capitalize",
  },
  h2: {
    fontFamily: "Poppins-SemiBold",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: palette.primary.dark,
  },

  h3: {
    fontFamily: "Poppins-Medium",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "normal",
    textAlign: "left",
    color: palette.primary.dark,
  },
  h4: {
    fontFamily: "Poppins-Medium",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: palette.primary.dark,
  },
  h5: {
    fontFamily: "Poppins-Regular",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "normal",
    color: palette.primary.gray,
  },
  h6: {
    fontFamily: "Poppins-Regular",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "19px",
    letterSpacing: "normal",
    color: "#666666",
  },

  subtitle1: {
    fontFamily: "Poppins-Regular",
    fontSize: "13px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: palette.primary.black,
  },

  subtitle: {
    fontFamily: "Poppins-Regular",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: palette.primary.gray,
  },
  body2: {
    fontFamily: "Poppins-Bold",
    fontSize: "18px",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
};

import React, { useEffect } from "react";
import Card from "../../../components/card";
import Heading from "../../../components/heading/Heading";
import { Box, Divider, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Pdficon, downloadsvicon, Docxicon } from "../../../assets/images";
import dayjs from "dayjs";
import {
  baseFileName,
  capitalizeFirstLetter,
  convertJobTypeLabel,
  downloadFile,
} from "../../../helper/helper";
//=============style=================

const root = { p: { xs: "10px", sm: "20px" } };
const detail = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  alignItems: { xs: "flex-start", sm: "center" },
  minHeight: "40px",
  padding: "10px 0px",
  height: "auto",
};

const boxDirection = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  width: "100%",
};

const datailBox = {
  height: "auto",
  width: { xs: "100%", md: "100%" },
  p: "10px",
  pl: { xs: "0px", md: "16px" },
};

const titleHeading = {
  color: "#202C40",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "capitalize",
  width: { xs: "220px", sm: "350px " },
};

//=============component=================

const ViewJobApplication = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedItem } = useSelector((state) => state.team);

  useEffect(() => {
    if (id !== selectedItem._id) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card>
      <Box sx={root}>
        <Heading text="Job Details " />
        <Box sx={boxDirection}>
          <Box sx={datailBox}>
            <Box sx={detail}>
              <Typography sx={titleHeading}>Job Title</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {" "}
                {selectedItem?.job?.title}
              </Typography>
            </Box>
            <Divider />

            <Box sx={detail}>
              <Typography sx={titleHeading}>Department Name</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {selectedItem?.job?.department === "sqa"
                  ? "SQA"
                  : selectedItem?.job?.department}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Designation</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {" "}
                {selectedItem?.job?.designation}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Job Type</Typography>
              <Typography variant="h6">
                {convertJobTypeLabel(selectedItem?.job?.jobType)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Job Location</Typography>
              <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                {selectedItem?.job?.jobLocation}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Heading text="Applicant Details " />
        <Box sx={boxDirection}>
          {/* <Box sx={imgBox}>
            <Box sx={mainImg}>
              <img
                src={selectedItem.photo}
                alt="profile"
                width="100%"
                height="100%"
                style={{ borderRadius: "50%" }}
              />
            </Box>
          </Box> */}

          <Box sx={datailBox}>
            <Box sx={detail}>
              <Typography sx={titleHeading}>Name</Typography>
              <Typography variant="h6">
                {capitalizeFirstLetter(selectedItem?.name)}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Email</Typography>
              <Typography variant="h6">{selectedItem?.email}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Phone Number</Typography>
              <Typography variant="h6">{selectedItem?.mobile}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Country</Typography>
              <Typography variant="h6">{selectedItem?.country}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Apply Date</Typography>
              <Typography variant="h6">
                {dayjs(selectedItem?.applyDate).format("DD-MM-YYYY")}
              </Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Notice Period</Typography>
              <Typography variant="h6">{selectedItem?.joiningDays}</Typography>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Linkedin Profile URL</Typography>
              <Box sx={{ maxWidth: { xs: "100%", sm: "40%", md: "50%" } }}>
                <a
                  href={selectedItem.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#0575e6",
                      wordBreak: "break-all",
                    }}
                  >
                    {selectedItem.url}
                  </Typography>
                </a>
              </Box>
            </Box>
            <Divider />
            <Box sx={detail}>
              <Typography sx={titleHeading}>Resume</Typography>
              <Box sx={{ display: "flex", gap: "4px" }}>
                <img
                  src={
                    selectedItem?.resume.split(".").pop() === "pdf"
                      ? Pdficon
                      : Docxicon
                  }
                  alt={"Pdficon"}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "4px",
                    flexDirection: "row",
                  }}
                  onClick={() => downloadFile(selectedItem?.resume)}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#0575E6", cursor: "pointer" }}
                  >
                    {baseFileName(selectedItem?.resume)}.
                    {selectedItem?.resume.split(".").pop()}
                  </Typography>
                  <img
                    src={downloadsvicon}
                    alt={"downloadsvicon"}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />

            <Box sx={detail}>
              <Typography sx={titleHeading}>Description</Typography>
              <Typography
                variant="h6"
                sx={{
                  maxWidth: { xs: "100%", sm: "40%", md: "50%" },
                  height: "auto",
                }}
              >
                {selectedItem.description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ViewJobApplication;

// import axios from "axios";
// import { REACT_APP_API_URL } from "../helper/envUrl";
import Axiosapi from "../utils/utils";

// const customParamsSerializer = (params) => {
//   return Object.entries(params)
//     .map(
//       ([key, value]) =>
//         `${decodeURIComponent(key)}=${encodeURIComponent(value)}`
//     )
//     .join("&");
// };

export const fetchCareerData = async (
  paramData,

  token,
  handleLogout
) => {
  const queryParams = [];
  if (paramData?.page) {
    queryParams.push(`page=${encodeURIComponent(paramData.page)}`);
  }
  if (paramData?.department?.trim()?.length > 1) {
    queryParams.push(
      `department=${encodeURIComponent(paramData.department.trim())}`
    );
  }
  if (paramData?.designation?.trim()?.length > 1) {
    queryParams.push(
      `designation=${encodeURIComponent(paramData.designation.trim())}`
    );
  }
  if (paramData?.jobType?.trim()?.length > 3) {
    queryParams.push(`jobType=${encodeURIComponent(paramData.jobType.trim())}`);
  }
  if (paramData?.postDateFrom?.trim()?.length > 3) {
    queryParams.push(
      `postDateFrom=${encodeURIComponent(paramData.postDateFrom.trim())}`
    );
  }
  if (paramData?.postDateTo?.trim()?.length > 3) {
    queryParams.push(
      `postDateTo=${encodeURIComponent(paramData.postDateTo.trim())}`
    );
  }
  if (paramData?.dueDateFrom?.trim()?.length > 3) {
    queryParams.push(
      `dueDateFrom=${encodeURIComponent(paramData.dueDateFrom.trim())}`
    );
  }
  if (paramData?.dueDateTo?.trim()?.length > 3) {
    queryParams.push(
      `dueDateTo=${encodeURIComponent(paramData.dueDateTo.trim())}`
    );
  }

  try {
    const response = await Axiosapi.get(
      `/api/careers?${queryParams.join("&")}`,
      {}
    );

    // Handle successful response here, e.g., return data
    return response;
  } catch (error) {
    // Handle errors here
    console.error("An error occurred while fetching data:", error);
    throw error; // Optional: Rethrow the error to propagate it to the caller
  }
};
export const addJobData = async ({ formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", formData.title);
  bodyFormData.append("department", formData.department);
  bodyFormData.append("designation", formData.designation);
  bodyFormData.append("image", formData.image);
  // bodyFormData.append("description", formData.description);
  bodyFormData.append("positions", formData.positions);
  bodyFormData.append("jobType", formData.jobType);
  bodyFormData.append("jobLocation", formData.jobLocation);
  bodyFormData.append("postDate", formData.postDate);
  bodyFormData.append("dueDate", formData.dueDate);
  bodyFormData.append("city", "lahore");
  bodyFormData.append("country", formData.country);
  bodyFormData.append("description", "ggggg");
  return await Axiosapi.post(`/api/careers`, formData, {}).then((res) => {
    const data = res.data;
    return data;
  });
};
export const uploadImage = async (file) => {
  // Check if the selected file is an image
  if (!file || !file.type.startsWith("image/")) {
    console.error("Invalid file format. Please select an image.");
    return;
  }

  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await Axiosapi.post(`/api/careers/upload`, formData, {});

    if (response.data && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Re-throw the error so that the calling code can handle it
  }
};
export const uploadImagelotify = async (file) => {
  // Check if the selected file is an image
  // if (!file || !file.type.startsWith("image/")) {
  //   console.error("Invalid file format. Please select an image.");
  //   return;
  // }

  const formData = new FormData();
  formData.append("lottieFile", file);

  try {
    const response = await Axiosapi.post(`/api/pages/file`, formData, {});

    if (response.data && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Re-throw the error so that the calling code can handle it
  }
};
export const deleteCareer = async ({ id }) => {
  return await Axiosapi.delete(`/api/careers/${id}`, {}).then((response) => {
    return response.data; // Modify this based on your API response structure
  });
};
export const editjobData = async ({ id, formData, token }) => {
  var bodyFormData = new FormData();
  bodyFormData.append("title", formData.title);
  bodyFormData.append("department", formData.department);
  bodyFormData.append("designation", formData.designation);
  bodyFormData.append("image", formData.image);
  // bodyFormData.append("description", formData.description);
  bodyFormData.append("positions", formData.positions);
  bodyFormData.append("jobType", formData.jobType);
  bodyFormData.append("jobLocation", formData.jobLocation);
  bodyFormData.append("postDate", formData.postDate);
  bodyFormData.append("dueDate", formData.dueDate);
  bodyFormData.append("city", "lahore");
  bodyFormData.append("country", formData.country);
  bodyFormData.append("description", "ggggg");
  return await Axiosapi.patch(`/api/careers/${id}`, formData, {}).then(
    (res) => {
      const data = res.data;
      return data;
    }
  );
};

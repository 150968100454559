import React, { useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MessageInput from "./MessageInput";
import { replay } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setReplayData } from "../../../redux/slices/chatSlice";
import { useQuery, useQueryClient } from "react-query";
import { messagesData, profileData } from "../../../api/ContactUs";
import Loader from "../../../components/loader/Loader";
import NoData from "../../../components/noData/NoData";
import dayjs from "dayjs";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import ViewModal from "../../../components/modals/ViewModal";
import { setSelectItem } from "../../../redux/slices/teamSlice";
import { OpenDeleteModal } from "../../../redux/slices/adminSlice";

//=============style==================

const menuePropsStyle = {
  elevation: 0,
  sx: {
    overflow: "visible",
    borderRadius: "4px",
    maxWidth: "230px",
    mt: 1.5,
    pading: "0px",

    "& .MuiMenu-list": {
      padding: "0px",
    },
    "& .MuiMenuItem-root": {
      margin: "0px",
    },
  },
};

const root = {
  background: "#fff",
  borderRadius: "4px",
  border: "1px solid #E9ECEF",
  width: { xs: "97%", md: "auto" },
  m: { xs: "17px auto", md: 0 },
  minHeight: "85vh",
};
const msgbox = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  height: "45px",
  px: "15px",
};
const dataBox = {
  width: "100%",
  mb: "15px",
};
const avtimg = {
  width: 30,
  height: 30,
  textTransform: "capitalize",
  cursor: "pointer",
};
const msglist = { height: { xs: "65vh", md: "68vh" }, overflowY: "scroll" };
const msgdata = {
  display: "flex",
  justifyContent: "center",
  my: "20px",
};
const msgdate = {
  background: "#E9ECEF",
  height: "25px",
  borderRadius: "15px",
  minWidth: "108px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const msgStyle = (item) => ({
  display: "flex",
  flexDirection: item?.contactUs?._id ? "row" : "row-reverse",
  alignItems: "center",
});

const resultStyle = {
  width: { xs: "100%", md: "70%" },
  mb: "10px",
};

const resultBox = (item) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: item?.contactUs?._id ? "row" : "row-reverse",
  width: "100%",
});

const markBox = (item) => ({
  background: item?.contactUs?._id ? "#E9ECEF" : "#EFF1F7",
  borderRadius: item?.contactUs?._id ? "6px 6px 6px 0px" : "6px 6px 0px 6px",
  p: "8px 10px",
  width: "100%",
  minHeight: "41px",
});

const markStyle = {
  display: "flex",
  p: "10px",
  background: "#0575E61A",
  minHeight: "46px",
  borderLeft: "2px solid #0575E6",
  borderRadius: "4px",
};

const youStyle = {
  color: "#0575E6",
  fontFamily: "Poppins-SemiBold",
};

const contactUsStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const timeBox = (item) => ({
  display: "flex",
  justifyContent: "flex-end",
  mt: "5px",
  flexDirection: item?.contactUs?._id ? "row" : "row-reverse",
});
const replayBox = {
  display: "flex",
  alignItems: "center",
  gap: "5px",
};

const iconStyle = { ml: "4px", mb: "4px" };

//=============component==================

const MessageList = ({ filter }) => {
  const dispatch = useDispatch();
  const messageListRef = useRef(null);
  const { token } = useSelector((state) => state.login);
  const { selectedEmail } = useSelector((state) => state.chat);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userId, setUserId] = React.useState(null);
  const queryClient = useQueryClient();

  const handleMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setUserId(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelReplay = (message) => {
    dispatch(setReplayData(userId));
  };

  //===================get all data api=============

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["dataContactMessage", selectedEmail],
    queryFn: () => messagesData(token, selectedEmail, filter?.resource),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
    onSuccess: () => {
      queryClient.refetchQueries("dataContact");
    },
  });
  const res = data?.data?.data;
  //===================get all data api=============

  const { data: resData, isLoading: proLoading } = useQuery({
    queryKey: ["dataContactMessages", selectedEmail],
    queryFn: () => profileData(token, selectedEmail, filter?.resource),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
    onSuccess: () => {
      queryClient.refetchQueries("dataContact");
    },
  });
  const profile = resData?.data?.data;

  const get = res?.flatMap((itm) =>
    itm?.result?.filter((i) => Object.keys(i?.contactUs).length === 0)
  );

  const lastItem = get && get[get?.length - 1];

  // Function to scroll to the bottom of the message list
  const scrollToBottom = () => {
    if (messageListRef.current) {
      const scrollOptions = {
        behavior: "smooth",
        top: messageListRef.current.scrollHeight,
      };
      messageListRef.current.scrollTo(scrollOptions);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  // Whenever selectedEmail changes, trigger a refetch
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmail]);

  const handleOpen = () => {
    dispatch(setSelectItem(profile));
    dispatch(OpenDeleteModal(true));
  };

  return (
    <Box sx={root}>
      {isLoading || isFetching ? (
        <Loader />
      ) : !res?.length ? (
        <NoData title="Please select chat." />
      ) : (
        <>
          <Box>
            <Box sx={msgbox}>
              <Avatar sx={avtimg} onClick={handleOpen}>
                {res && res[0]?.result[0]?.name?.charAt(0)}{" "}
              </Avatar>
              <Box sx={{ cursor: "pointer" }} onClick={handleOpen}>
                <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                  {res && res[0]?.result[0]?.name}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: "5px" }} />
          </Box>
          <Box
            ref={messageListRef} // Set the ref here
            elevation={3}
            sx={msglist}
          >
            <Box sx={{ p: "15px" }}>
              {res?.map((message) => (
                <Box key={message.date} sx={dataBox}>
                  {message.date && (
                    <Box sx={msgdata}>
                      <Box sx={msgdate}>
                        <Typography variant="h6" sx={{ color: "#000" }}>
                          {dayjs(message.date).format("DD MMM YYYY")}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box>
                    {message?.result &&
                      message?.result?.map((item, i) => (
                        <Box key={i}>
                          <Box sx={msgStyle(item)}>
                            <Box sx={resultStyle}>
                              <Box sx={resultBox(item)}>
                                <Box sx={markBox(item)}>
                                  {item?.mark && (
                                    <Box sx={markStyle}>
                                      <Box
                                        sx={{
                                          width: "90%",
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            variant="h6"
                                            sx={youStyle}
                                          >
                                            You
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Typography
                                            variant="h6"
                                            sx={contactUsStyle}
                                          >
                                            {item?.contactUs?.message}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      color: "#000",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {item.message}
                                  </Typography>
                                  {item?.filePath && (
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        color: "#0575E6",
                                        wordBreak: "break-all",
                                      }}
                                    >
                                      <InsertDriveFileOutlinedIcon
                                        sx={{
                                          fill: "#0575E6",
                                          fontSize: "12px",
                                        }}
                                      />{" "}
                                      <a
                                        href={item?.filePath}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          textDecoration: "none",
                                          color: "#0575e6",
                                        }}
                                      >
                                        {item?.filePath}
                                      </a>
                                    </Typography>
                                  )}
                                </Box>
                                <Box sx={{ width: "50px" }}>
                                  {item?.contactUs?._id ? null : (
                                    <IconButton
                                      onClick={(e) => handleMenu(e, item)}
                                      size="small"
                                      sx={iconStyle}
                                      aria-controls={
                                        anchorEl ? "account-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        anchorEl ? "true" : undefined
                                      }
                                    >
                                      <MoreVertIcon
                                        sx={{ fontSize: "x-large" }}
                                      />{" "}
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                              <Box sx={timeBox(item)}>
                                <Typography variant="h6" sx={{ color: "#000" }}>
                                  {dayjs(item.createdAt).format("HH:mm A")}
                                </Typography>
                                <Box sx={{ width: "50px" }} />
                              </Box>
                            </Box>
                          </Box>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={menuePropsStyle}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              onClick={() => handelReplay(item.message)}
                            >
                              <Box sx={replayBox}>
                                <Box>
                                  <img src={replay} alt="replay" />{" "}
                                </Box>
                                <Box>
                                  <Typography variant="h6">Replay</Typography>{" "}
                                </Box>
                              </Box>
                            </MenuItem>
                          </Menu>
                        </Box>
                      ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <MessageInput
            id={lastItem?._id}
            name={res && res[0]?.result[0]?.name}
          />
        </>
      )}
      <ViewModal />
    </Box>
  );
};

export default MessageList;

import { Box } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/slices/adminSlice";
import { setActive } from "../../redux/slices/teamSlice";
import JobApplicationTable from "../../components/jobApplicationTable/JobApplicationTable";

const JobApplicationSection = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setSelectedTab(5));
    dispatch(setActive(5));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <JobApplicationTable />
    </Box>
  );
};

export default JobApplicationSection;

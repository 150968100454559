import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddProductTags,
  setAddTags,
  setProductPage,
} from "../../../../redux/slices/servicePageSlice";
import Card from "../../../../components/card";
import Heading from "../../../../components/heading/Heading";
import AddNewProducts from "./component/AddNewProducts";
import { addProductValidationSchema } from "../../../../utils/Schema";
import ColorField from "../../../../components/colorField/ColorField";
import { CustomTooltip } from "../../../../components/customTooltip";

//=============style=================

const main = { p: "20px" };

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};

const dispBox = {
  display: "flex",
  flexWrap: "wrap",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

const clButton = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

//=============component=================

const Product = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { product, addProductTags } = useSelector((state) => state.page);
  const initialValues = {
    title: "",
    titleColor: "",
    description: "",
    descColor: "",
    bgColor: "",
    productImagesBgColor: "",
    cardBgColor: "",
    tagsBgColor: "",
    tagsColor: "",
    products: [],
  };

  //=======formick====

  const formik = useFormik({
    initialValues,
    validationSchema: addProductValidationSchema,
    onSubmit: (values) => {
      const formData = {
        title: values.title,
        titleColor: values.titleColor,
        description: values.description,
        descColor: values.descColor,
        bgColor: values.bgColor,
        productImagesBgColor: values.productImagesBgColor,
        cardBgColor: values.cardBgColor,
        tagsBgColor: values.tagsBgColor,
        tagsColor: values.tagsColor,
        products: values.products,
        // products: addProductTags,
      };

      dispatch(setProductPage(formData));
      navigate(-1);
    },
  });

  useEffect(() => {
    dispatch(setAddProductTags([]));
    if (
      location.pathname === "/edit-Product" &&
      product &&
      Object.keys(product).length > 0
    ) {
      setFieldValue("title", product?.title);
      setFieldValue("titleColor", product?.titleColor);
      setFieldValue("description", product?.description);
      setFieldValue("descColor", product?.descColor);
      setFieldValue("bgColor", product?.bgColor);
      setFieldValue("productImagesBgColor", product?.productImagesBgColor);
      setFieldValue("cardBgColor", product?.cardBgColor);
      setFieldValue("tagsBgColor", product?.tagsBgColor);
      setFieldValue("tagsColor", product?.tagsColor);
      setFieldValue("products", product?.products);

      dispatch(setAddProductTags(product?.products));
    }
  }, []);

  useEffect(() => {
    setFieldValue("products", addProductTags);
  }, [addProductTags]);

  const handlePreview = () => {
    const formData = {
      title: values.title,
      titleColor: values.titleColor,
      description: values.description,
      descColor: values.descColor,
      bgColor: values.bgColor,
      productImagesBgColor: values.productImagesBgColor,
      cardBgColor: values.cardBgColor,
      tagsBgColor: values.tagsBgColor,
      tagsColor: values.tagsColor,
      products: values.products,
      // products: addProductTags,
    };

    dispatch(setProductPage(formData));
  };

  const handleBlur = () => {
    handlePreview(); // Call handleSubmit onBlur
  };

  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  useEffect(() => {
    handleBlur();
  }, [values.products]);

  return (
    <Card>
      <Box sx={main}>
        <form onSubmit={handleSubmit}>
          <Heading
            text={
              location.pathname === "/add-product"
                ? "Add Product"
                : "Update Product"
            }
          />

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Product Section Title"}>
                <Typography>Title*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Title"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Background Color for Product Section"}>
                <Typography>BgColor*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="bgColor"
                name="bgColor"
                type="color"
                value={values.bgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.bgColor && errors.bgColor)}
                helperText={touched.bgColor && errors.bgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Background Color for Product Images"}>
                <Typography>Images Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="productImagesBgColor"
                name="productImagesBgColor"
                value={values.productImagesBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                type="color"
                error={Boolean(
                  touched.productImagesBgColor && errors.productImagesBgColor
                )}
                helperText={
                  touched.productImagesBgColor && errors.productImagesBgColor
                }
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Background Color for Product Cards" }>
                <Typography>Card Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="cardBgColor"
                name="cardBgColor"
                type="color"
                value={values.cardBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.cardBgColor && errors.cardBgColor)}
                helperText={touched.cardBgColor && errors.cardBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Background Color for Product Tags"}>
                <Typography>Tags Background*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="tagsBgColor"
                name="tagsBgColor"
                type="color"
                value={values.tagsBgColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.tagsBgColor && errors.tagsBgColor)}
                helperText={touched.tagsBgColor && errors.tagsBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Color for Product Tags"}>
                <Typography>Tags Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="tagsColor"
                type="color"
                name="tagsColor"
                value={values.tagsColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.tagsColor && errors.tagsColor)}
                helperText={touched.tagsColor && errors.tagsColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Select Title Color for Product Section"}>
                <Typography>Title Color* </Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="titleColor"
                name="titleColor"
                type="color"
                value={values.titleColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.titleColor && errors.titleColor)}
                helperText={touched.titleColor && errors.titleColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Choose Description Color for Product Section"}>
                <Typography>Description Color*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <ColorField
                fullWidth
                placeholder="Description Color"
                name="descColor"
                type="color"
                value={values.descColor}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.descColor && errors.descColor)}
                helperText={touched.descColor && errors.descColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>
          </Box>

          <Box sx={dispBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Enter Description for Product Section"}>
                <Typography>Description*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <TextField
                fullWidth
                placeholder="Description"
                multiline
                name="description"
                minRows={4}
                maxRows={4}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Box>

          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <CustomTooltip title={"Add Products"}>
                <Typography>Add Products*</Typography>
              </CustomTooltip>
            </Box>
            <Box sx={{ width: { sx: "100%", md: "50%" } }}>
              <AddNewProducts
                tokenCardsErrors={errors}
                tokenCardsTouched={touched}
                setValue={setFieldValue}
                productValue={values}
              />
            </Box>
          </Box>

          {/* Add a Button for submitting the data */}
          <Box sx={clButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ maxWidth: "90px" }}
            >
              cancel
            </Button>
            <Link
              to={{
                pathname: "/preview",
                state: { item: values.lottiefile },
              }}
              onClick={handlePreview}
              target="_blank"
            >
              <Button variant="contained" sx={{ maxWidth: "90px" }}>
                preview
              </Button>
            </Link>
            <Button variant="contained" type="submit" sx={{ maxWidth: "90px" }}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default Product;

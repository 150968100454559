import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "../../components/card";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteIcone, editTableIcon, plus } from "../../assets/images";
import SectionButton from "./component/sectionButton/SectionButton";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  fetchSinglePageData,
  newPages,
  newPagesUpdate,
} from "../../api/ServicePage";
import BannerPreview from "./component/banner/component/bannerPreview/BannerPreview";
import Fact from "./component/token/component/Fact";
import Portfolio from "./component/product/component/Portfolio";
import LetGetStarted from "./component/getStarted/component/LetGetStarted";
import CTO from "./component/professionals/component/CTO";
import {
  setBanner,
  setGetStarted,
  setNamePage,
  setProductPage,
  setProfessional,
  setTokenPage,
} from "../../redux/slices/servicePageSlice";
import Loader from "../../components/loader/Loader";

//=============style==================

const wrap = {
  minHeight: "87vh",
};

const mainBox = (theme) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  p: "12px 16px",
  borderBottom: `2px solid ${theme.palette.primary.lightGray}`,
  justifyContent: "space-between",
  "@media (max-width: 570px)": {
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "8px 0px",
  },
});

const clButton = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
  mt: 2,
};

const ServicePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { name: Name } = useParams();
  const { banner, tokenPage, product, getStarted, professional, namePage } =
    useSelector((state) => state.page);

  // const [name, setName] = useState(Name ? Name : "");
  const [errors, setErrors] = useState({
    banner: "",
    tokenPage: "",
    product: "",
    getStarted: "",
    professional: "",
    name: "",
  });

  // useEffect(() => {
  //   dispatch(setNamePage(Name ? Name : ""));
  // }, [Name]);

  //============api delete ============
  const { mutate, isLoading } = useMutation(newPages, {
    onSuccess: (data) => {
      toast.success(`${data?.message}`);
      navigate("/campaign-ads");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });
  const { mutate: updatePage, isLoading: isLoadingUpdate } = useMutation(
    newPagesUpdate,
    {
      onSuccess: (data) => {
        toast.success(`${data?.message}`);
        navigate("/campaign-ads");
      },
      onError: (error) => {
        toast.error(
          `${error?.response?.data?.message}` || "Something went wrong!"
        );
      },
    }
  );

  const handleNewPage = () => {
    let formIsValid = true;
    const data = {
      name: namePage,
      banner,
      token: tokenPage,
      product,
      getStarted,
      professionals: professional,
    };
    const newErrors = {
      banner: "",
      tokenPage: "",
      product: "",
      getStarted: "",
      professional: "",
      name: "",
    };

    if (data.name === "") {
      newErrors.name = "Name is required";
      formIsValid = false;
    }

    if (!data.banner) {
      newErrors.banner = "Banner is required";
      formIsValid = false;
    }

    if (!data.token) {
      newErrors.tokenPage = "Token is required";
      formIsValid = false;
    }

    if (!data.product) {
      newErrors.product = "Product is required";
      formIsValid = false;
    }

    if (!data.getStarted) {
      newErrors.getStarted = "GetStarted is required";
      formIsValid = false;
    }

    if (data.professionals.length == 0) {
      newErrors.professional = "Professional is required";
      formIsValid = false;
    }

    if (formIsValid) {
      if (location.pathname !== "/add-campaign-ads") {
        updatePage({ data: data, name: Name });
        // mutate({ data });
      } else {
        mutate({ data });
      }
    } else {
      setErrors(newErrors);
    }
  };

  //===================get all data api=============

  const shouldEnableQuery =
    banner === null ||
    tokenPage === null ||
    product === null ||
    getStarted === null ||
    professional.length === 0;
  const {
    data,
    isLoading: loading,
    isFetching,
  } = useQuery({
    queryKey: ["dataPageSing", Name],
    queryFn: () => fetchSinglePageData(Name, dispatch),
    enabled: shouldEnableQuery,
    // keepPreviousData: true,
    // notifyOnChangeProps: ["data", "error"],
  });

  // useEffect(() => {
  //   if (res) {
  //   }
  //   dispatch(setNamePage(res?.name));
  //   dispatch(setBanner(res?.banner));
  //   dispatch(setTokenPage(res?.token));
  //   dispatch(setProductPage(res?.product));
  //   dispatch(setGetStarted(res?.getStarted));
  //   dispatch(setProfessional(res?.professionals));
  // }, [res]);
  return (
    <div>
      {(loading || isFetching) && location.pathname !== "/add-campaign-ads" ? (
        <Loader />
      ) : (
        <Card>
          <Box sx={{ p: "20px" }}>
            <Typography>Enter Name</Typography>
            <TextField
              value={namePage}
              placeholder="Name"
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                dispatch(setNamePage(trimmedValue));
                // setName(trimmedValue);
              }}
              fullWidth
              error={errors.name && namePage === ""}
              helperText={errors.name}
            />
          </Box>
          <Box sx={wrap}>
            <Box sx={mainBox}>
              <Typography variant="h2">Services Sections</Typography>
            </Box>
            <Box sx={{ p: { xs: "3px", sm: "20px" } }}>
              <Box>
                {banner === null ? (
                  <Box>
                    <SectionButton
                      onClick={() => {
                        Name
                          ? navigate("/edit-banner")
                          : navigate("/add-banner");
                      }}
                      minHeight="150px"
                      imageSrc={plus}
                      altText="plus"
                      text="Add Banner"
                    />
                    {errors.banner && banner === null && (
                      <FormHelperText error sx={{ pl: "10px" }}>
                        {errors.banner}
                      </FormHelperText>
                    )}
                  </Box>
                ) : (
                  <Box sx={{ mt: 2, position: "relative" }}>
                    <IconButton
                      sx={{
                        color: "blue",
                        padding: "6px",
                        fontSize: "1rem",
                        background: "#fff",
                        position: "absolute",
                        top: 3,
                        right: 3,
                        zIndex: 1,
                        "&:hover, &:active": {
                          background: "#fff",
                        },
                      }}
                      onClick={() =>
                        navigate(`/edit-banner`, {
                          state: { item: banner?.lottiefile },
                        })
                      }
                    >
                      <img
                        src={editTableIcon}
                        width="12px"
                        height="12px"
                        alt="edit icon"
                      />
                    </IconButton>
                    <IconButton
                      sx={{
                        color: "blue",
                        padding: "6px",
                        fontSize: "1rem",
                        background: "#fff",
                        position: "absolute",
                        top: 3,
                        right: 30,
                        zIndex: 1,
                        "&:hover, &:active": {
                          background: "#fff",
                        },
                      }}
                      onClick={() => dispatch(setBanner(null))}
                    >
                      <img
                        src={deleteIcone}
                        width="12px"
                        height="12px"
                        alt="edit icon"
                      />
                    </IconButton>

                    <BannerPreview data={banner} />
                  </Box>
                )}

                {tokenPage === null ? (
                  <Box mt={2}>
                    <SectionButton
                      onClick={() => {
                        Name ? navigate("/edit-token") : navigate("/add-token");
                      }}
                      minHeight="150px"
                      imageSrc={plus}
                      altText="plus"
                      text="Add Token"
                    />
                    {errors.tokenPage && tokenPage === null && (
                      <FormHelperText error sx={{ pl: "10px" }}>
                        {errors.tokenPage}
                      </FormHelperText>
                    )}
                  </Box>
                ) : (
                  <>
                    <Fact data={tokenPage} />
                  </>
                )}
                {product === null ? (
                  <Box mt={2}>
                    <SectionButton
                      onClick={() => {
                        Name
                          ? navigate("/edit-product")
                          : navigate("/add-Product");
                      }}
                      minHeight="150px"
                      imageSrc={plus}
                      altText="plus"
                      text="Add Product"
                    />
                    {errors.product && product === null && (
                      <FormHelperText error sx={{ pl: "10px" }}>
                        {errors.product}
                      </FormHelperText>
                    )}
                  </Box>
                ) : (
                  <>
                    <Portfolio data={product} />
                  </>
                )}

                {professional.length === 0 ? (
                  <Box mt={2}>
                    <SectionButton
                      onClick={() => navigate("/professionals")}
                      minHeight="150px"
                      imageSrc={plus}
                      altText="plus"
                      text="Add Professionals"
                    />
                    {errors.professional && professional.length === 0 && (
                      <FormHelperText error sx={{ pl: "10px" }}>
                        {errors.professional}
                      </FormHelperText>
                    )}
                  </Box>
                ) : (
                  <>
                    <CTO data={professional} />
                  </>
                )}

                {getStarted === null ? (
                  <Box mt={2}>
                    <SectionButton
                      onClick={() => {
                        Name
                          ? navigate("/edit-getStarted")
                          : navigate("/add-getStarted");
                      }}
                      minHeight="150px"
                      imageSrc={plus}
                      altText="plus"
                      text="Add GetStarted"
                    />
                    {errors.getStarted && getStarted === null && (
                      <FormHelperText error sx={{ pl: "10px" }}>
                        {errors.getStarted}
                      </FormHelperText>
                    )}
                  </Box>
                ) : (
                  <>
                    <LetGetStarted data={getStarted} />
                  </>
                )}

                <Box sx={clButton}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate(-1)}
                    sx={{ maxWidth: "90px" }}
                  >
                    cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleNewPage}
                    disabled={isLoadingUpdate || isLoading}
                    sx={{ maxWidth: "90px" }}
                  >
                    {isLoadingUpdate || isLoading ? (
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    ) : Name ? (
                      "update"
                    ) : (
                      "submit"
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </div>
  );
};

export default ServicePage;

import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import { deleteTableIcon, editTableIcon } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteExpertise, fetchDataExpertise } from "../../api/Expirtise";
import DeleteModal from "../modals/DeleteModal";
import { OpenDeleteModal } from "../../redux/slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSelectItem } from "../../redux/slices/teamSlice";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";
import NoData from "../noData/NoData";

const icons = {
  color: "blue",
  padding: "6px",
  fontSize: "1rem",
};

const cardStyle = {
  width: "100%",
  minHeight: "186px",
  border: "1px solid #E9ECEF",
  boxShadow: "3px 4px 7px 0px #0000000A",
  p: "20px",
  borderRadius: "8px",
};
const cardbox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mb: "5px",
};
const cardimg = {
  width: "40%",
  height: "33px",
};
const actionButton = { display: "flex", justifyContent: "flex-end" };

export default function ServicesCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { selectedItem } = useSelector((state) => state.team);
  const { token } = useSelector((state) => state.login);

  const handleOpen = (item) => {
    dispatch(setSelectItem(item));
    dispatch(OpenDeleteModal(true));
  };

  const handleEdit = (item) => {
    dispatch(setSelectItem(item));
    navigate(`/edit-expertise/${item._id}`);
  };

  //===================get all data api=============

  const { data, isLoading } = useQuery({
    queryKey: ["dataexp"],
    queryFn: () => fetchDataExpertise(),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
  });

  const res = data?.data?.data;

  //============api delet ============

  const { mutate: deleteMutate } = useMutation(deleteExpertise, {
    onSuccess: (data) => {
      toast.success("Expertise deleted successfully.");
      queryClient.refetchQueries("dataexp");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  const handleDelete = () => {
    deleteMutate({ id: selectedItem._id, token });
    dispatch(OpenDeleteModal(false));
  };
  return (
    <Box sx={{ p: "20px" }}>
      <Grid container spacing={2} sx={{ ml: { xs: "-16px", md: "-20px" } }}>
        {isLoading ? (
          <Loader />
        ) : !res?.length ? (
          <NoData />
        ) : (
          res &&
          res?.map((card) => (
            <Grid item xs={12} sm={4} md={4} key={card._id}>
              <Box sx={cardStyle}>
                <Box sx={cardbox}>
                  <Box sx={cardimg}>
                    <img
                      src={card.image}
                      alt="expImg"
                      width="100%"
                      height="100%"
                    />
                  </Box>

                  <Box sx={actionButton}>
                    <>
                      <IconButton
                        sx={icons}
                        onClick={() => handleEdit(card)}
                        data-cy="edit-DepartmentTable"
                      >
                        <img
                          src={editTableIcon}
                          width="12px"
                          height="100%"
                          alt="edit icon"
                        />
                      </IconButton>
                    </>

                    <>
                      <IconButton sx={icons} onClick={() => handleOpen(card)}>
                        <img
                          src={deleteTableIcon}
                          width="12px"
                          height="100%"
                          alt="edit icon"
                        />
                      </IconButton>
                    </>
                  </Box>
                </Box>
                <Divider sx={{ my: "10px" }} />
                <Box>
                  <Typography variant="h6">{card.description}</Typography>
                </Box>
              </Box>
            </Grid>
          ))
        )}
      </Grid>

      <DeleteModal handleDelete={handleDelete} />
    </Box>
  );
}

import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";


const root = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: { xs: 2, sm: 2, md: 4, lg: 4 },
  width: { xs: "100%", sm: "100%", md: "80%", lg: "80%" },
  flexWrap: "wrap",
};

const cardBody = {
  flexDirection: "column",
  padding: "16px 20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  width: 292,
  height: 188,
  position: "relative",
  cursor: "pointer",
  transition:
    "background-color 1s, box-shadow 0.5s, transform 1s, background-image 1s",
};

const heading = {
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: 1.28,
  letterSpacing: "normal",
  textAlign: "center",
  mb: 1,
};

const text = {
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: 1.57,
  letterSpacing: "normal",
  textAlign: "center",
  color: "#dfe7f2",
};

const Card = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <Box sx={root}>
      {data?.map((item, index) => (
        <React.Fragment key={index}>
          {item.title === "more..." ? (
            <Link to={`${item.linkUrl}`} key={index}>
              <Box
                sx={{
                  ...cardBody,
                  backgroundImage: `url(${
                    activeIndex === index || hoveredIndex === index
                      ? "/images/factBg.svg"
                      : "/images/hoverFactBg.svg"
                  })`,
                  backgroundColor:
                    activeIndex === index || hoveredIndex === index
                      ? item?.cardBgColor
                      : item?.cardBgColor,
                  boxShadow:
                    activeIndex === index
                      ? "3px 5px 16px 0 rgba(63, 109, 253, 0.55)"
                      : hoveredIndex === index
                      ? "3px 5px 16px 0 rgba(63, 109, 253, 0.55)"
                      : "none",
                }}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(index)}
              >
                <Typography
                  variant="h3"
                  sx={{
                    ...heading,
                    color:
                      activeIndex === index || hoveredIndex === index
                        ? item?.nameColor || "#FFF"
                        : item?.nameColor || "#202c40",
                  }}
                >
                  {item.name}
                </Typography>
                {activeIndex === index || hoveredIndex === index ? (
                  <Typography sx={text}>{item.description}</Typography>
                ) : (
                  ""
                )}
              </Box>
            </Link>
          ) : (
            <Box
              sx={{
                ...cardBody,
                backgroundImage: `url(${
                  activeIndex === index || hoveredIndex === index
                    ? "/images/factBg.svg"
                    : "/images/hoverFactBg.svg"
                })`,
                backgroundColor:
                  activeIndex === index || hoveredIndex === index
                    ? item?.cardBgColor
                    : item?.cardBgColor,
                boxShadow:
                  activeIndex === index
                    ? "3px 5px 16px 0 rgba(63, 109, 253, 0.55)"
                    : hoveredIndex === index
                    ? "3px 5px 16px 0 rgba(63, 109, 253, 0.55)"
                    : "none",
              }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(index)}
            >
              <Typography
                variant="h3"
                sx={{
                  ...heading,
                  color:
                    activeIndex === index || hoveredIndex === index
                      ? item?.nameColor || "#FFF"
                      : item?.nameColor || "#202c40",
                }}
              >
                {item.name}
              </Typography>
              {activeIndex === index || hoveredIndex === index ? (
                <Typography sx={text}>{item.description}</Typography>
              ) : (
                ""
              )}
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Card;

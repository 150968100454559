import React, { useEffect, useRef, useState } from "react";
import { Box, Tooltip, Zoom, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";
import { useDeviceSize } from "../../helper/hooks";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    PopperProps={{
      modifiers: [
        {
          name: "flip",
          options: {
            fallbackPlacements: ["right", "top", "left", "bottom"],
          },
        },
      ],
    }}
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
    boxShadow: "0 3px 20px 0 rgba(0, 0, 0, 0.1)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 4px 0 rgba(0, 0, 0, 0.1)",
    color: "#000",
    fontWeight: 300,
    minHeight: "20px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: "10px",
    fontSize: "12px",
    justifyContent: "center",
    borderRadius: "6px",
    maxWidth: "480px",
  },
}));

export const CustomTooltip = ({ title, children, placement }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };
  const tooltipRef = useRef(null);
  const handleOutsideClick = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setIsTooltipOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const width = useDeviceSize();
  return (
    <>
      {width < 768 ? (
        <Box ref={tooltipRef}>
          <BootstrapTooltip
            arrow
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 500 }}
            title={title}
            placement={placement || "left"}
            open={isTooltipOpen}
            // onClick={handleTooltipClick}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Box>{children}</Box>
          </BootstrapTooltip>
        </Box>
      ) : (
        <BootstrapTooltip
          arrow
          TransitionComponent={Zoom}
          TransitionProps={{ timeout: 500 }}
          title={title}
          placement={placement || "top"}
        >
          <Box>{children}</Box>
        </BootstrapTooltip>
      )}
    </>
  );
};

import axios from "axios";
import { setIsValidToken, setLoginData, setToken, setTwoFa } from "../redux/slices/loginSlice";
import jwtDecode from "jwt-decode";
import { toast } from "react-hot-toast";
import { REACT_APP_API_URL } from "../helper/envUrl";
import store from "../redux/store";

//=======login============

export const logIn = async ({ data, dispatch, navigate }) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/users/login`, data, {
      headers: {
        "Content-Type": "application/json", // Adjust headers as needed
      },
    })
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      dispatch(setToken(response.data.token));
      const decodedJwt = jwtDecode(response.data.token);
      dispatch(setLoginData(decodedJwt));

      if (decodedJwt.twoFAStatus) {
        navigate("/two-fa");
      } else {
        navigate("/");
      }

      return decodedJwt;
    });
};

//=========2fa =================

export const fetch2FAData = async (token, dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/users/request-twofa`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Process the response data here
    const responseData = response.data;
    dispatch(setTwoFa(responseData));
    return responseData;
  } catch (error) {
    // Handle errors here
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error to be handled further up the call stack
  }
};

//===================== enableDisableTwoFA =====

export const enableDisableTwoFA = async ({ dataRes, token, dispatch }) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/users/enable-disable`, dataRes, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      localStorage.setItem("token", res?.data?.token);
      const decodedtoken = jwtDecode(res?.data?.token);
      dispatch(setLoginData(decodedtoken));
      dispatch(setToken(res?.data?.token));
      return res.data;
    });
};

//=========change=====

export const changePassword = async ({ values, token, dispatch }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/users/update-password`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    localStorage.setItem("token", response?.data?.token);
    const decodedtoken = jwtDecode(response?.data?.token);
    dispatch(setLoginData(decodedtoken));
    dispatch(setToken(response?.data?.token));

    return response; // Modify this based on your API response structure
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong!");
    throw new Error("An error occurred while submitting the form");
  }
};

//=================verify 2fa========

export const verifyTwoFA = async ({ dataRes, token, dispatch }) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/api/users/verify-twofa`, dataRes, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      localStorage.setItem("token", res?.data?.token);
      const decodedtoken = jwtDecode(res?.data?.token);
      dispatch(setLoginData(decodedtoken));
      dispatch(setToken(res?.data?.token));
      return res.data;
    });
};
export const handleLogingOut = () => {
  store.dispatch(setIsValidToken(false));
};

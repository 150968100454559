import { Box, Button, FormHelperText, Switch, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { copyIcon1 } from "../../../assets/images";
import copy from "copy-to-clipboard";
import { MuiOtpInput } from "mui-one-time-password-input";
import { QRCodeSVG } from "qrcode.react";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import DoneIcon from "@mui/icons-material/Done";

import { useNavigate } from "react-router-dom";
import Heading from "../../../components/heading/Heading";
import { useDispatch, useSelector } from "react-redux";
import { enableDisableTwoFA, fetch2FAData } from "../../../api/Auth";
import Loader from "../../../components/loader/Loader";

const mainContainer = {
  p: 2,
  // minHeight: "60vh",
  maxWidth: "470px",
  margin: "0 auto",
};

const codeInputBox = {
  "& .MuiOutlinedInput-input": {
    padding: " 7.5px 0px 7.5px 0px !important",
    maxHeight: "25px !important",
    maxWidth: "50px !important",
    width: "100%",
    height: "100%",
  },
  "& ::placeholder": {
    pl: "43%",
  },
};

const scan = {
  display: "flex",
  justifyContent: "center",
  background: "#fff",
  width: { xs: "155px", sm: "195px" },
  height: { xs: "140px", sm: "180px" },
  m: "20px auto 20px auto",
};
const buttonBox = {
  textAlign: "right",
  width: { xs: "92%", sm: "90%" },
  justifyContent: "center",
};
const helperTextStyle = {
  height: "15px",
  width: "100%",
  ml: { xs: "30px", sm: "10%" },
};
const errorStyle = { paddingLeft: "5px", mt: 0, fontSize: "13px" };

const backButton = {
  maxWidth: "80px",
  mr: 2,
};

const buttonStyle = {
  maxWidth: "80px",
};
const textBox = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const copyBox = {
  width: "100%",
  maxWidth: "25px",
  height: "25px",
  borderRadius: "10px",
  boxShadow: "-7px -7px 10px 0 rgba(255, 255, 255, 0.5)",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  ml: 1,
};
const coadBox = {
  display: "inline-flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const divider = (theme) => ({
  width: "98%",
  backgroundColor: `${theme.palette.primary.lightGray}`,
  mt: "15px",
  mb: 3,
  height: "1px",
});

const headText = { color: "#666666", fontFamily: "Poppins-Regular" };
const mainBox = { m: "0 auto", position: "relative", maxWidth: "450px" };

// ---------Component ---------

const TwofaSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, loginData } = useSelector((state) => state.login);
  const [otp, setOtp] = React.useState("");
  const [empty, setEmpty] = useState(false);
  const [view, setView] = useState(loginData?.twoFAStatus ? true : false);

  const [wasACopied, setWasACopied] = useState(false);

  //====2fa api======

  const { data, isLoading: isLoadingtwoFA } = useQuery({
    queryKey: "twoFA",
    queryFn: () => fetch2FAData(token, dispatch),
    keepPreviousData: true,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: view && !loginData?.twoFAStatus,
    notifyOnChangeProps: ["data", "error"],
  });

  const { mutate, isLoading } = useMutation(enableDisableTwoFA, {
    onSuccess: (data) => {
      if (data.message === "Two Factor Authentication Disabled") {
        toast.success("Two Factor Authentication Disabled");
        setView(!view);
      } else {
        toast.success("Two Factor Authentication Enabled");
        setOtp("");
      }
    },

    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong!");
    },
  });

  const handleChangeOtp = (newValue) => {
    setEmpty(false);
    setOtp(newValue);
  };

  const handleEnableDisable = () => {
    if (otp !== "" && otp.length === 6) {
      if (loginData?.twoFAStatus) {
        const dataRes = {
          status: 0,
          code: otp,
        };
        mutate({ dataRes, token, dispatch });
      } else {
        const dataRes = {
          formattedKey: data?.formattedKey,
          uri: data?.uri,
          status: 1,
          code: otp,
        };
        mutate({ dataRes, token, dispatch });
      }
    } else {
      setEmpty(true);
    }
  };

  const handleAddressCopy = () => {
    copy(data?.formattedKey ? data?.formattedKey : loginData.formattedKey);
    setWasACopied(true);
    setTimeout(() => {
      setWasACopied(false);
    }, 3000);
  };

  return (
    <>
      <Box>
        <Heading text="Two Factor Authentication" />
        <Box sx={textBox}>
          <Typography variant="h4" sx={headText}>
            Enable Two Factor Authentication:
          </Typography>
          <Switch
            checked={view}
            disabled={loginData?.twoFAStatus}
            onClick={(e) => {
              e.preventDefault();
              setView(!view);
              setOtp("");
            }}
            color="primary"
          />
        </Box>
        <Box sx={divider}></Box>
      </Box>
      <Box sx={mainBox}>
        {isLoadingtwoFA ? (
          <Loader />
        ) : (
          <Box
            sx={{
              filter: view ? "blur(0px)" : "blur(10px)",
              cursor: view ? "unset" : "pointer",
            }}
          >
            <Box sx={mainContainer}>
              <Box sx={scan}>
                <QRCodeSVG
                  value={!view ? "" : data?.uri ? data?.uri : loginData?.uri}
                  size="100%"
                  bgColor="#ffffff"
                  fgColor="#000000"
                  level="L"
                  includeMargin
                  renderAs="svg"
                  eyeRadius={10}
                />
              </Box>
              <Box marginBottom={2} sx={coadBox}>
                <Typography variant="h4" sx={{ fontFamily: "Poppins-Regular" }}>
                  {!view
                    ? ""
                    : data?.formattedKey
                    ? data?.formattedKey
                    : loginData.formattedKey}
                </Typography>

                <Box sx={copyBox} onClick={handleAddressCopy}>
                  {wasACopied ? (
                    <DoneIcon sx={{ fill: "#0575E6" }} />
                  ) : (
                    <img src={copyIcon1} alt="copyIcon" />
                  )}
                </Box>
              </Box>
              <MuiOtpInput
                length={6}
                value={otp}
                display="flex"
                justifyContent="center"
                gap={1}
                onChange={handleChangeOtp}
                TextFieldsProps={{
                  placeholder: "-",
                }}
                sx={codeInputBox}
              />
              <Box sx={helperTextStyle}>
                {empty && (
                  <FormHelperText error sx={errorStyle}>
                    Code is required
                  </FormHelperText>
                )}
              </Box>
            </Box>

            <Box sx={buttonBox}>
              <Button
                variant="outlined"
                sx={backButton}
                onClick={() => navigate(-1)}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={buttonStyle}
                onClick={handleEnableDisable}
                disabled={isLoading || isLoadingtwoFA}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "#fff" }} size={20} />
                ) : loginData?.twoFAStatus ? (
                  "Disable"
                ) : (
                  "Enable"
                )}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TwofaSetting;

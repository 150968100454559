import Axiosapi from "../utils/utils";

export const fetchApplicantData = async (
  paramData,

  token,
  handleLogout
) => {
  const queryParams = [];
  const nameOrEmail = paramData?.name?.trim();

  if (/\S+@\S*/.test(nameOrEmail)) {
    // It's an email
    queryParams?.push(`email=${nameOrEmail}`);
  } else if (nameOrEmail && nameOrEmail.length > 1) {
    // It's a name
    queryParams.push(`name=${nameOrEmail}`);
  }
  if (paramData?.page) {
    queryParams.push(`page=${encodeURIComponent(paramData.page)}`);
  }
  if (paramData?.jobId?.trim()?.length > 3) {
    queryParams.push(`jobId=${encodeURIComponent(paramData.jobId.trim())}`);
  }

  if (paramData?.jobType?.trim()?.length > 3) {
    queryParams.push(`jobType=${encodeURIComponent(paramData.jobType.trim())}`);
  }
  if (paramData?.department?.trim()?.length > 1) {
    queryParams.push(
      `department=${encodeURIComponent(paramData.department.trim())}`
    );
  }
  if (paramData?.designation?.trim()?.length > 1) {
    queryParams.push(
      `designation=${encodeURIComponent(paramData.designation.trim())}`
    );
  }
  if (paramData?.applyDateFrom?.trim()?.length > 3) {
    queryParams.push(
      `applyDateFrom=${encodeURIComponent(paramData.applyDateFrom.trim())}`
    );
  }
  if (paramData?.applyDateTo?.trim()?.length > 3) {
    queryParams.push(
      `applyDateTo=${encodeURIComponent(paramData.applyDateTo.trim())}`
    );
  }

  try {
    const response = await Axiosapi.get(
      `/api/applicant/all?${queryParams.join("&")}`,
      {}
    );

    // Handle successful response here, e.g., return data
    return response;
  } catch (error) {
    // Handle errors here
    console.error("An error occurred while fetching data:", error);
    throw error; // Optional: Rethrow the error to propagate it to the caller
  }
};
export const fetchApplicantresume = async (paramData) => {
 
  const queryParams = [];
  const nameOrEmail = paramData?.name?.trim();

  if (/\S+@\S*/.test(nameOrEmail)) {
    // It's an email
    queryParams?.push(`email=${nameOrEmail}`);
  } else if (nameOrEmail && nameOrEmail.length > 1) {
    // It's a name
    queryParams.push(`name=${nameOrEmail}`);
  }

  if (paramData?.jobId?.trim()?.length > 3) {
    queryParams.push(`jobId=${encodeURIComponent(paramData.jobId.trim())}`);
  }
  if (paramData?.jobType?.trim()?.length > 3) {
    queryParams.push(`jobType=${encodeURIComponent(paramData.jobType.trim())}`);
  }
  if (paramData?.department?.trim()?.length > 1) {
    queryParams.push(
      `department=${encodeURIComponent(paramData.department.trim())}`
    );
  }
  if (paramData?.designation?.trim()?.length > 1) {
    queryParams.push(
      `designation=${encodeURIComponent(paramData.designation.trim())}`
    );
  }
  if (paramData?.applyDateFrom?.trim()?.length > 3) {
    queryParams.push(
      `applyDateFrom=${encodeURIComponent(paramData.applyDateFrom.trim())}`
    );
  }
  if (paramData?.applyDateTo?.trim()?.length > 3) {
    queryParams.push(
      `applyDateTo=${encodeURIComponent(paramData.applyDateTo.trim())}`
    );
  }

  try {
    const response = await Axiosapi.get(
      `/api/applicant/export?${queryParams.join("&")}`,
      {}
    );

    // Handle successful response here, e.g., return data
    return response;
  } catch (error) {
    // Handle errors here
    console.error("An error occurred while fetching data:", error);
    throw error; // Optional: Rethrow the error to propagate it to the caller
  }
};

import React, { useRef, useState } from "react";
import {
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { send, fileIcon, closeIcon } from "../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { setReplayData } from "../../../redux/slices/chatSlice";
import { setMessages } from "../../../api/ContactUs";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";

//=============style==================

const root = {
  display: "flex",
  gap: "10px",
  p: "15px",
  height: "10vh",
  alignItems: "flex-end",
};
const boxroot = {
  marginBottom: "10px",
  position: "absolute",
  top: 20,
  left: "1.8%",
  width: "96.4%",
};
const repltstyle = {
  width: "100%",
  p: "5px 10px",
  background: "#0575E61A",
  borderRadius: "4px",
  borderLeft: "3px solid #0575E6",
  display: "flex",
  justifyContent: "space-between",
  mb: "5px",
};

const text = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};

const filebox = {
  width: "100%",
  p: "5px 10px",
  background: "#0575E61A",
  borderRadius: "4px",
  borderLeft: "3px solid #0575E6",
  display: "flex",
  justifyContent: "space-between",
};
const filename = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
const inputStyle = (replayData, file) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  background: "#fff",
  "& .MuiOutlinedInput-root": {
    padding: replayData || file?.name ? "16px 16px 5px " : "8px 0px",
    minHeight: replayData && file?.name ? "210px" : "",
  },
  "& .MuiOutlinedInput-input": {
    padding: replayData || file?.name ? "5px 0px  !important" : "5px 12px",
  },

  "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
    mt:
      replayData && file?.name
        ? "75px"
        : replayData
        ? "62px"
        : file?.name
        ? "40px"
        : 0,
  },
  "& .css-1laqsz7-MuiInputAdornment-root": {
    alignItems: replayData || file?.name ? "flex-start" : "center",
    mt: replayData && "20px",
    mr: "10px",
  },
  "& .css-ju4f8z-MuiInputBase-root-MuiOutlinedInput-root": {
    p: "16.5px 14px 5px",
  },
});
const sendButton = { minWidth: "40px", maxWidth: "40px", minHeight: "44px" };
const replayName = { color: "#0575E6", textTransform: "capitalize" };
const iconStyle = { mt: "-5px", mr: "-10px" };

//=============component==================

const MessageInput = ({ id, name }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const { token } = useSelector((state) => state.login);
  const { replayData } = useSelector((state) => state.chat);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  //===================api==============

  const { isLoading, mutate } = useMutation(setMessages, {
    onSuccess: (data) => {
      toast.success("Message send successfully.");
      queryClient.refetchQueries("dataContactMessage");
      setMessage("");
      setFile(null);
      dispatch(setReplayData(null));
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      const formdata = {
        mark: replayData !== null ? true : false,
        message: message,
        filePath: file ? file : "",
      };
      const idData = replayData ? replayData?._id : id;
      mutate({ formdata, token, idData });
    }
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleAttachFileClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  return (
    <Box sx={root}>
      <TextField
        placeholder="Type a message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        variant="outlined"
        multiline
        maxRows={2}
        fullWidth
        disabled={isLoading}
        sx={inputStyle(replayData, file)}
        InputProps={{
          startAdornment: (replayData || file?.name) && (
            <Box>
              <Box sx={boxroot}>
                {replayData && (
                  <Box sx={repltstyle}>
                    <Box
                      sx={{
                        width: "90%",
                      }}
                    >
                      <Typography sx={replayName}>{name} </Typography>
                      <Typography variant="h6" sx={text}>
                        {replayData?.message}
                      </Typography>
                    </Box>
                    <Box sx={iconStyle}>
                      <IconButton onClick={() => dispatch(setReplayData(null))}>
                        <img src={closeIcon} alt="closeIcon" />
                      </IconButton>
                    </Box>
                  </Box>
                )}
                {file?.name && (
                  <Box sx={filebox}>
                    <Typography variant="h6" sx={filename}>
                      {file?.name}
                    </Typography>
                    <Box sx={iconStyle}>
                      <IconButton onClick={() => setFile(null)}>
                        <img src={closeIcon} alt="closeIcon" />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          ),
          endAdornment: (
            <Box>
              <InputAdornment position="end">
                <IconButton
                  aria-label="attach file"
                  onClick={handleAttachFileClick}
                  edge="end"
                >
                  <img src={fileIcon} alt="send" />
                </IconButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".pdf, image/*"
                  onChange={handleFileInputChange}
                />
              </InputAdornment>
            </Box>
          ),
        }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSendMessage}
        sx={sendButton}
        disabled={isLoading || message === ""}
      >
        {isLoading ? (
          <CircularProgress sx={{ color: "#fff" }} size={20} />
        ) : (
          <img src={send} alt="send" />
        )}
      </Button>
    </Box>
  );
};

export default MessageInput;

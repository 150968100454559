import React, { useEffect, useState } from "react";
import { Box, Container, IconButton, Typography } from "@mui/material";
import Slider from "../../../../../components/slider/Slider";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteIcone, editTableIcon } from "../../../../../assets/images";
import { useDispatch } from "react-redux";
import { setProductPage } from "../../../../../redux/slices/servicePageSlice";

const styles = {
  root: (data) => ({
    width: "100%",
    background: `${data?.cardBgColor}` || "#fff",
    position: "relative",
    paddingBottom: 38,
    ".MuiContainer-root": {
      backgroundColor: "transparent",
    },
  }),
  wrapper: (data) => ({
    width: "100%",
    background: `${data?.bgColor}` || "#3f6dfd",
    padding: "40px 0px",
  }),
  wrapperDetail: {
    width: "100%",
    background: "#fff",
    position: "relative",
  },
  rootWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flexDirection: "column",
  },
  infoWrapperBoxMain: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "6px",
    boxShadow: "0 16px 40px 0 rgba(112, 144, 176, 0.2)",
    mb: -34,
  },
  infoWrapperBox: (data) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    flexWrap: "wrap",
    position: "relative",
    backgroundColor: `${data?.cardBgColor}`,
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    minHeight: "400px",

    // height: { xs: "100%", sm: "100%", md: 430, lg: 360 },
  }),
  infoWrapper: {
    width: { xs: "100%", sm: "100%", md: "32%", lg: "32%" },
    minHeight: { sx: "450px" },
  },
  infoWrapperData: {
    position: {
      xs: "relative",
      sm: "relative",
      md: "absolute",
      lg: "absolute",
    },
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    top: 36,
    left: 30,
    width: { xs: "90%", sm: "90%", md: "37%", lg: "37%" },
    "@media (max-width: 420px)": {
      width: "80%",
    },
  },
  infoWrapper2: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: { xs: "100%", sm: "100%", md: "55%", lg: "68%" },
  },
  headingWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: { xs: "100%", sm: "80%", md: "60%", lg: "60%" },
  },
  skillBox: (data) => ({
    borderRadius: "15px",
    background: `${data?.tagsBgColor}`,
    padding: "10px 16px",
    lineHeight: 1,
    display: "flex",
    color: `${data?.tagsColor}`,
    fontSize: 12,
    fontWeight: 500,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    mb: "11px",
    mr: "11px",
  }),
  titleHeading: (data) => ({
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: "40px",
    color: `${data?.nameColor}`,
  }),
  titleText: (data) => ({
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: 1.71,
    color: `${data?.descColor}` || "#666666",
    mb: 1.75,
  }),
  headingTitle: (data) => ({
    color: `${data?.titleColor}`,
    textAlign: "center",
    lineHeight: "36px",
    mb: 2,
  }),
  slideAnimation: {
    transition: "transform 1s ease, opacity 1s ease",
  },
  slideAnimationActive: {
    opacity: 0,
  },
};

const Portfolio = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  const images = data?.products.map((item) => ({ imgSrc: item.imgUrl }));

  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 500);
    return () => clearTimeout(timeout);
  }, [currentSlideIndex]);

  return (
    <Box sx={{ mt: 2, position: "relative" }}>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 3,
          zIndex: 1,
          display:location.pathname==="/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => navigate("/edit-Product")}
      >
        <img src={editTableIcon} width="12px" height="12px" alt="edit icon" />
      </IconButton>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 30,
          zIndex: 1,
          display:location.pathname==="/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => dispatch(setProductPage(null))}
      >
        <img src={deleteIcone} width="12px" height="12px" alt="edit icon" />
      </IconButton>
      <Box sx={styles.root(data)}>
        <Box sx={styles.wrapper(data)}>
          <Container>
            <Box sx={styles.rootWrapper}>
              <Box sx={styles.headingWrapper}>
                <Typography variant="body2" sx={styles.headingTitle(data)}>
                  {data?.title}
                </Typography>

                <Typography
                  mb={5}
                  lineHeight={1.5}
                  color={data?.descColor || "#fff"}
                  textAlign={"center"}
                >
                  {data?.description}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.infoWrapperBoxMain}>
              <Box sx={styles.infoWrapperBox(data)}>
                <Box sx={styles.infoWrapper}>
                  <Box
                    sx={{
                      ...styles.infoWrapperData,
                      ...styles.slideAnimation,
                      ...(animate && styles.slideAnimationActive),
                    }}
                  >
                    <Typography
                      sx={styles.titleHeading(
                        data?.products[currentSlideIndex]
                      )}
                    >
                      {data?.products[currentSlideIndex]?.name}
                    </Typography>

                    <Typography
                      sx={styles.titleText(data?.products[currentSlideIndex])}
                    >
                      {data?.products[currentSlideIndex]?.description}
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {data?.products[currentSlideIndex]?.tags?.map(
                        (item, index) => (
                          <Box sx={styles.skillBox(data)} key={index}>
                            {item}
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.infoWrapper2}>
                  <Slider
                    setCurrentSlideIndex={setCurrentSlideIndex}
                    data={images}
                  />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Portfolio;

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { logoIcon, logoutIcon } from "../../../assets/images";

import * as React from "react";

import { useNavigate } from "react-router-dom";
import { adminData } from "../..";
import { useLogout } from "../../../hooks/logout";
import { useDispatch, useSelector } from "react-redux";
import { setActive } from "../../../redux/slices/teamSlice";
import { Link } from "react-router-dom";

const drawerWidth = 229;

const openedMixin = (theme) => ({
  width: drawerWidth,
  height: "100vh",
  borderRadius: "0px 0px 12px 0px ",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  zIndex: 0,
});

const closedMixin = (theme) => ({
  borderTopRightRadius: "12px",
  borderBottomRightRadius: "12px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.5s",
  }),
  overflowX: "hidden",
  zIndex: 0,
  height: "100vh",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(11)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "100vh",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    borderRadius: "0 10px 10px 0",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const sectionStyle = (open) => {
  return {
    dispaly: "flex",
    alignItems: "center",
    justifyContent: open ? "initial" : "flex-start",
  };
};

const logoSection = (theme) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "64px",
  px: 2.5,
  borderBottom: `2px solid ${theme.palette.primary.lightGray}`,
});

const logoBox = {
  width: "30px",
  height: "33px",
  cursor: "pointer",
};

const logoText = (open) => {
  return {
    pl: 1,
    cursor: "pointer",
    transition: "0.5s all",
    opacity: open ? 1 : 0,
  };
};

const menuListStyle = {
  height: "90vh",
  overflowY: "auto",
  overflowX: "hidden",
  px: 2.5,
};

const buttonBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "42px",
  cursor: "pointer",
};

const iconTextBox = {
  display: "flex",
  alignItems: "center",
  transition: "0.5s all",
};

const iconBox = (open) => {
  return {
    ml: !open && "4px",
    // mr: open && "8px",
    transition: "0.5s all",
  };
};

const sideIconStyle = (open) => ({
  width: "22px",
  height: "22px",
  border: `2px solid rgba(233, 236, 239, 1)`,
  borderRadius: "4px",
  position: "absolute",
  left: open ? 216 : 77,
  top: 82,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#ffff",
  zIndex: 1,
  transition: "0.4s all",
  cursor: "pointer",
});

const logoutStyle = (theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "45px",
  cursor: "pointer",
  borderTop: `2px solid ${theme.palette.primary.lightGray}`,
});

const logoutTextStyle = {
  display: "flex",
  alignItems: "center",
  px: 2.5,
};

const logoutText = (open) => {
  return {
    transition: "0.5s all",
    opacity: open ? 1 : 0,
    color: "rgba(0, 0, 0, 1)",
    fontFamily: "Poppins-Medium",
    fontSize: "14px",
  };
};

export default function MiniDrawer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = useLogout();
  const { active } = useSelector((state) => state.team);

  const [open, setOpen] = React.useState(true);
  const [hoveredIndex, setHoveredIndex] = React.useState(null);
  const [flag, setFlag] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClick = (index, item) => {
    dispatch(setActive(index));
    index?.toString() !== active?.toString() && setFlag(item?.open);
    index?.toString() === active?.toString() && setFlag(!flag);
    item?.path && navigate(item?.path);
  };

  return (
    <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        {/* drawer header --------- */}
        <Box sx={sectionStyle(open)}>
          <Box sx={logoSection}>
            <Box sx={logoBox} onClick={() => navigate("/")}>
              <img
                src={logoIcon}
                alt="magnus mage"
                width="30px"
                height="33px"
              />
            </Box>

            <Typography
              variant="h2"
              sx={logoText(open)}
              onClick={() => navigate("/")}
            >
              Magnus Mage
            </Typography>
          </Box>
        </Box>

        {/* drawer side menu list section----- */}
        <List sx={menuListStyle}>
          <Box sx={sectionStyle(open)}>
            {adminData?.map((item, index) => (
              <Box position="relative" key={index}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={item?.path}
                  key={index}
                  sx={buttonBox}
                  data-cy="menu-drawer"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleClick(index, item)}
                >
                  <Box sx={iconTextBox}>
                    <IconButton sx={iconBox(open)}>
                      <img
                        src={
                          active?.toString() === index?.toString()
                            ? item?.activeImage
                            : hoveredIndex === index
                            ? item?.activeImage
                            : item?.image
                        }
                        alt="magnus mage"
                        width="18px"
                        height="18px"
                      />
                    </IconButton>

                    <Typography
                      variant="h6"
                      sx={{
                        textDecoration: "none",
                        transition: "0.5s all",
                        fontFamily:
                          active?.toString() === index?.toString()
                            ? "Poppins-Medium"
                            : "Poppins-Regular",
                        opacity: open ? 1 : 0,
                        color:
                          active?.toString() === index?.toString() ||
                          hoveredIndex === index
                            ? "rgba(5, 117, 230, 1)"
                            : "rgba(116, 121, 130, 1)",
                      }}
                    >
                      {item?.name}
                    </Typography>
                  </Box>
                </Link>
              </Box>
            ))}
          </Box>
        </List>

        {/* logout button------ */}
        <List>
          <Box sx={sectionStyle(open)}>
            <Box
              sx={logoutStyle}
              data-cy="logout-drawer"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Box sx={logoutTextStyle} onClick={handleLogout}>
                <IconButton sx={iconBox(open)}>
                  <img
                    src={logoutIcon}
                    alt="magnus mage"
                    width="18px"
                    height="18px"
                  />
                </IconButton>

                <Typography variant="subtitle" sx={logoutText(open)}>
                  Logout
                </Typography>
              </Box>
            </Box>
          </Box>
        </List>
      </Drawer>

      <Box sx={sideIconStyle(open)} onClick={handleDrawerOpen}>
        {open ? (
          <KeyboardArrowLeftIcon
            sx={{
              fill: "rgba(5, 117, 230, 1)",
              width: "1em",
              height: "1em",
            }}
          />
        ) : (
          <KeyboardArrowRightIcon
            sx={{
              fill: "rgba(5, 117, 230, 1)",
              width: "1em",
              height: "1em",
            }}
          />
        )}
      </Box>
    </Box>
  );
}

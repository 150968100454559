import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import Card from "../../../components/card";
import { useFormik } from "formik";
import Heading from "../../../components/heading/Heading";
import { AddExpertiseSchema } from "../../../utils/Schema";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { editExpertiseData } from "../../../api/Expirtise";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import ImgBox from "../../../components/imgBox/ImgBox";

//============style=============

const labelText = { width: { xs: "100%", md: "20%" }, mb: "5px" };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "60px",
};
const formData = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
};
const buttonBox = {
  width: { xs: "100%", md: "70%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};
const disStyle = { width: { sx: "100%", md: "50%" } };

//============component=============

const EditExpertise = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { token } = useSelector((state) => state.login);
  const { selectedItem } = useSelector((state) => state.team);
  const [inputKey, setInputKey] = useState(0);
  const initialValues = {
    name: selectedItem?.title ? selectedItem?.title : "",
    image: selectedItem?.image ? selectedItem?.image : "",
    description: selectedItem?.description ? selectedItem?.description : "",
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setFieldValue("image", selectedImage);
    setInputKey(inputKey + 1);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const selectedImage = event.dataTransfer.files[0];
    setFieldValue("image", selectedImage);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: () => AddExpertiseSchema(),
    onSubmit: (values) => {
      const data = {
        title: values.name,
        image: values.image,
        description: values.description,
      };
      mutate({ id: selectedItem._id, data, token });
    },
  });
  const { values, handleSubmit, handleChange, touched, errors, setFieldValue } =
    formik;

  //===================api==============

  const { isLoading, mutate } = useMutation(editExpertiseData, {
    onSuccess: (data) => {
      toast.success("Expertise edit successfully.");
      queryClient.refetchQueries("dataexp");
      navigate("/expertise");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });

  useEffect(() => {
    if (id !== selectedItem._id) {
      navigate("/not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Card>
      <Box sx={{ p: "20px" }}>
        <form onSubmit={handleSubmit}>
          <Heading text="Expertise icon & description" />
          <Box sx={formData}>
            <Box sx={labelText}>
              {" "}
              <Typography>Image*</Typography>
            </Box>

            <ImgBox
              inputKey={inputKey}
              values={values}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              handleFileChange={handleImageChange} // Your file change handler
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              fieldName="image" // Field name for this file
              acceptedFileTypes={["image/*"]} // Accepted file types
              maxResolution="1024 x 1024 px" // Maximum resolution
            />
          </Box>
          <Box sx={fieldBox}>
            <Box sx={labelText}>
              {" "}
              <Typography>Name*</Typography>
            </Box>
            <Box sx={disStyle}>
              <TextField
                fullWidth
                placeholder="Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
          </Box>
          <Box sx={formData}>
            <Box sx={labelText}>
              {" "}
              <Typography>Description</Typography>
            </Box>
            <Box sx={disStyle}>
              <TextField
                fullWidth
                placeholder="Description here..."
                multiline
                name="description"
                minRows={4}
                value={values.description}
                onChange={handleChange}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
              />
            </Box>
          </Box>

          <Box sx={buttonBox}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ maxWidth: "80px" }}
              onClick={() => navigate(-1)}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{ maxWidth: "80px" }}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              ) : (
                "submit"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default EditExpertise;

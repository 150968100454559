// SidebarDrawer.jsx

import React from "react";
import { Drawer, Box, Stack, Typography, Divider, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CustomSelect from "./Customselect";
import SingleDatePicker from "../DateFilter";
import dayjs from "dayjs";
import { Designation, JobType, options } from "../../pages/career/reusablefield/FormOption";

const SidebarDrawer = ({
  open,
  handleDrawerClose,
  setdrawerfilter,
  formik,
  resetForm,
  filter,
  setFilter,
}) => {
 
 

  const handleClearEndDate = (e) => {
    // Clear the end date here
    e.stopPropagation();
    formik.setFieldValue("postDateTo", null);
  };
  const handleClearStartDate = (e) => {
    e.stopPropagation();
    formik.setFieldValue("postDateFrom", null);
  };
  const handleCleardueEndDate = (e) => {
    // Clear the end date here
    e.stopPropagation();
    formik.setFieldValue("dueDateTo", null);
  };
  const handleCleardueStartDate = (e) => {
    e.stopPropagation();
    formik.setFieldValue("dueDateFrom", null);
  };
  const [startDateOpen, setStartDateOpen] = React.useState(false);
  const [EndDateOpen, setEndDateOpen] = React.useState(false);
  const [duestartDateOpen, setduestartDateOpen] = React.useState(false);
  const [dueEndDateOpen, setdueEndDateOpen] = React.useState(false);
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleDrawerClose}
      sx={{ boxShadow: "-5px 5px 14px 0px #0000001A" }}
      BackdropProps={{
        sx: {
          backgroundColor: "transparent", // Set the background color to transparent
        },
      }}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{ width: { xs: "298px", md: "320px" }, paddingBlock: "18px" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ paddingX: { xs: "10px", md: "15px" } }}
        >
          <Typography variant="h1">Filters jobs</Typography>
          <ClearIcon
            color="#0575E6"
            sx={{
              fill: "#0575E6 !important",
              fontSize: "28px",
              cursor: "pointer",
            }}
            onClick={handleDrawerClose}
          />
        </Stack>
        <Divider sx={{ paddingY: "8px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: { xs: "10px", md: "15px" },
            gap: "12px",
            marginTop: "10px",
          }}
        >
          <CustomSelect
            fieldProps={{
              ...formik.getFieldProps("department"),
            }}
            options={options}
            formikvalue={formik.values.department}
            label="Select Department"
          />
          <CustomSelect
            fieldProps={{
              ...formik.getFieldProps("jobType"),
            }}
            options={JobType}
            formikvalue={formik.values.jobType}
            label="Job Type"
          />
          <CustomSelect
            fieldProps={{
              ...formik.getFieldProps("designation"),
            }}
            options={Designation}
            formikvalue={formik.values.designation}
            label="Select Designation"
          />
          <Typography variant="h3">Date Posted  </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <SingleDatePicker
              title=""
              disablefuture={true}
              open={startDateOpen}
              setOpen={setStartDateOpen}
              handleClear={handleClearStartDate}
              date={formik.values.postDateFrom}
              handleChange={(newValue) => {
                let formattedValue;

                if (newValue) {
                  // If it's AM, format the time in 12-hour format
                  formattedValue = dayjs(newValue).format("YYYY-MM-DD").trim();

                  // Check if the hour is 12 and change it to 00
                }

                formik.setFieldValue("postDateFrom", formattedValue);
              }}
              placeholder="From Date"
              id="postDateFrom"
            />
            <SingleDatePicker
              title=""
              disablefuture={true}
              open={EndDateOpen}
              setOpen={setEndDateOpen}
              handleClear={handleClearEndDate}
              date={formik.values.postDateTo}
              handleChange={(newValue) => {
                let formattedValue;

                if (newValue) {
                  // If it's AM, format the time in 12-hour format
                  formattedValue = dayjs(newValue).format("YYYY-MM-DD").trim();

                  // Check if the hour is 12 and change it to 00
                }

                formik.setFieldValue("postDateTo", formattedValue);
              }}
              placeholder="To Date"
              id="postDateTo"
            />
          </Box>
          <Typography variant="h3">Deadline </Typography>
          <Box sx={{ display: "flex", gap: "10px", mb: "30px" }}>
            <SingleDatePicker
              title=""
              open={duestartDateOpen}
              setOpen={setduestartDateOpen}
              handleClear={handleCleardueStartDate}
              date={formik.values.dueDateFrom}
              handleChange={(newValue) => {
                let formattedValue;

                if (newValue) {
                  // If it's AM, format the time in 12-hour format
                  formattedValue = dayjs(newValue)?.format("YYYY-MM-DD").trim();

                  // Check if the hour is 12 and change it to 00
                }

                formik.setFieldValue("dueDateFrom", formattedValue);
              }}
              placeholder="From Date"
              id="dueDateFrom"
            />
            <SingleDatePicker
              title=""
              open={dueEndDateOpen}
              setOpen={setdueEndDateOpen}
              handleClear={handleCleardueEndDate}
              date={formik.values.dueDateTo}
              handleChange={(newValue) => {
                let formattedValue;

                if (newValue) {
                  // If it's AM, format the time in 12-hour format
                  formattedValue = dayjs(newValue).format("YYYY-MM-DD").trim();

                  // Check if the hour is 12 and change it to 00
                }

                formik.setFieldValue("dueDateTo", formattedValue);
              }}
              placeholder="To Date"
              id="dueDateTo"
            />
          </Box>

          <Button variant="contained" type="submit">
            Search
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              resetForm();
            }}
            sx={{ maxWidth: "100% !important" }}
          >
            Reset
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SidebarDrawer;

export const MuiDivider = {
  styleOverrides: {
    root: {
      "&.MuiDivider-root": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unescaped-entities */
import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  Checkbox,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteIcone, editTableIcon, pakflag } from "../../../../../assets/images";
import { useDispatch } from "react-redux";
import { setGetStarted } from "../../../../../redux/slices/servicePageSlice";

const PoligonIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.897"
      height="32"
      viewBox="0 0 27.897 32"
    >
      <path
        id="Polygon_14"
        data-name="Polygon 14"
        d="M22.262,0a3,3,0,0,1,2.6,1.507l6.279,10.949a3,3,0,0,1,0,2.985L24.865,26.39a3,3,0,0,1-2.6,1.507H9.738a3,3,0,0,1-2.6-1.507L.856,15.441a3,3,0,0,1,0-2.985L7.135,1.507A3,3,0,0,1,9.738,0Z"
        transform="translate(27.897) rotate(90)"
        fill={color || "#e5eefe"}
      />
    </svg>
  );
};

const root = (data) => ({
  width: "100%",
  background: data?.bgColor || "#F9FDFE",
  position: "relative",
});
const rootWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: { xs: "40px 0px", sm: "40px 20px", md: "40px 0px", lg: "40px 0px" },
  flexWrap: "wrap",
};
const headingWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: { xs: "100%", sm: "100%", md: "40%", lg: "40%" },
};
const wrapper = (data) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: { xs: "100%", sm: "100%", md: "40%", lg: "40%" },
  background: data?.formBgColor || "#F2F7FF",
  zIndex: 2,
});

const input = (data) => ({
  mb: "5px",
  minHeight: "58px",
  borderRadius: "4px",

  "& .MuiOutlinedInput-root": {
    background: `${data?.inputBgColor}` || "#4668D4",
    border: "none",

    "& .MuiOutlinedInput-input": {
      background: `${data?.inputBgColor}` || "#4668D4",
      color: `${data?.inputTextColor}` || "#fff",
      fontStyle: "normal",
      borderRadius: "4px",
      border: "none",
      "&:focus": {
        border: "none",
      },
    },
  },

  ".MuiFormHelperText-root": {
    color: "#ab2828",
  },

  ".Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ab2828",
  },

  ".MuiSvgIcon-root": {
    color: "#3f6dfd",
    position: "absolute",
    right: "-2%",
  },
});

const feed = (data) => ({
  marginTop: "0px",
  marginBottom: "1px",
  minHeight: "140px",
  borderRadius: "4px",

  "& .MuiOutlinedInput-root": {
    background: `${data?.inputBgColor}` || "#4668D4",
    border: "none",

    "& .MuiOutlinedInput-input": {
      background: `${data?.inputBgColor}` || "#4668D4",
      color: `${data?.inputTextColor}` || "#fff",
      fontStyle: "normal",
      borderRadius: "4px",
      border: "none",
      "&:focus": {
        border: "none",
      },
    },
  },

  ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ab2828",
  },

  ".MuiFormHelperText-root": {
    color: "#ab2828",
  },
});

const contentBox = {
  width: "90%",
  m: "27px 0px",
};

const form = {
  width: "100%",
  maxWidth: "1900px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const hexagoneWrapper = {
  width: "100%",
  maxWidth: "1900px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  mb: 2,
};
const hexagone = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "20px",
};
const hexagoneBackImage = {
  position: "absolute",
  height: 32,
  width: 32,
};
const backImage = {
  position: "absolute",
  top: "48%",
  right: "54%",
  height: { xs: 120, sm: 180, md: 203 },
  width: { xs: 115, sm: 175, md: 193 },
  zIndex: 2,
};

const LetGetStarted = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <Box sx={{ mt: 2, position: "relative" }}>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 3,
          zIndex: 1,
          display:location.pathname==="/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => navigate("/edit-getStarted")}
      >
        <img src={editTableIcon} width="12px" height="12px" alt="edit icon" />
      </IconButton>
      <IconButton
        sx={{
          color: "blue",
          padding: "6px",
          fontSize: "1rem",
          background: "#fff",
          position: "absolute",
          top: 3,
          right: 30,
          zIndex: 1,
          display:location.pathname==="/preview" && "none",
          "&:hover, &:active": {
            background: "#fff",
          },
        }}
        onClick={() => dispatch(setGetStarted(null))}
      >
        <img src={deleteIcone} width="12px" height="12px" alt="edit icon" />
      </IconButton>
      <Box sx={root(data)}>
        <Box sx={backImage}>
          <img src={data?.bgIcon} alt="background" />
        </Box>
        <Container>
          <Box sx={rootWrapper}>
            <Box sx={headingWrapper}>
              <Typography variant="body2" sx={{ color: data?.titleColor }}>
                {data?.title}
              </Typography>
              <Typography mb={2} sx={{ color: data?.descColor }}>
                {data?.description}
              </Typography>

              {data?.list?.map((item, index) => (
                <Box sx={hexagoneWrapper} key={index}>
                  <Box sx={hexagone}>
                    <Box sx={hexagoneBackImage}>
                      <PoligonIcon color={data?.bulletBgColor} />
                    </Box>
                    <Typography
                      sx={{
                        zIndex: 1,
                        color: data?.titleColor || "#202c40",
                        fontSize: 14,
                        mr: "3px",
                      }}
                    >
                      {index + 1}
                    </Typography>
                  </Box>
                  <Typography sx={{ color: data?.descColor }}>
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box sx={wrapper(data)}>
              <Box sx={contentBox}>
                <form style={form}>
                  <TextField
                    sx={input(data)}
                    multiline={false}
                    placeholder="Full Name"
                    type="text"
                    fullWidth
                  />

                  <TextField
                    sx={input(data)}
                    placeholder="Email Address"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    sx={input(data)}
                    placeholder="+92"
                    type="number"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            minWidth: "28px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box mt={1}>
                            <img src={pakflag} alt="pakflag" />
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    sx={feed(data)}
                    placeholder="Message here"
                    multiline
                    rows="4.65"
                    type="text"
                    fullWidth
                  />
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "flex-start",
                      mb: "20px",
                    }}
                  >
                    <Checkbox
                      sx={{
                        mt: "-5px",
                        ml: "-10px",
                        color: data?.inputBorderColor || "",
                      }}
                      name="check"
                      color="primary"
                    />
                    <Typography
                      sx={{
                        color: data?.descColor || "#202c40",
                        lineHeight: 1.64,
                      }}
                    >
                      By using this form you agree with the storage and handling
                      of your data by this website.
                    </Typography>
                  </FormControl>
                  <Button
                    type="submit"
                    sx={{
                      width: "100%",
                      background: data?.btnBgColor,
                      color: data?.btnTextColor,
                    }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LetGetStarted;

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import queryString from "query-string";
import DeleteModal from "../../../../components/modals/DeleteModal";
import TablePagination from "../../../../components/pagination";
import TableNoData from "../../../../components/tablenodata";
import TableLoader from "../../../../components/tableloader";
import Card from "../../../../components/card";
import { setSelectItem } from "../../../../redux/slices/teamSlice";
import { OpenDeleteModal } from "../../../../redux/slices/adminSlice";
import { deleteEmployee, fetchData } from "../../../../api/Employee";
import { useLogout } from "../../../../hooks/logout";
import {
  deleteTableIcon,
  editTableIcon,
  searchIcon,
  viewIcon,
} from "../../../../assets/images";
import { deletePage, fetchPageData } from "../../../../api/ServicePage";
import {
  setBanner,
  setGetStarted,
  setNamePage,
  setProductPage,
  setProfessional,
  setTokenPage,
} from "../../../../redux/slices/servicePageSlice";
import { CustomTooltip } from "../../../../components/customTooltip";
import { Link } from "react-router-dom";

// ---------Component style------------

const mainBox = (theme) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  p: "12px 16px",
  borderBottom: `2px solid ${theme.palette.primary.lightGray}`,
  justifyContent: "space-between",
  "@media (max-width: 570px)": {
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "8px 0px",
  },
});

const tableBox = {
  width: "100%",
  overflowY: "auto",
  pl: 2,
  pr: 2,
  minHeight: "510px",
};
const actionBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  minWidth: "70px",
  maxWidth: "70px",
  gap: 1,
};

const searchfieldStyle = {
  display: "flex",
  "& .MuiOutlinedInput-root": {
    height: "34px",
  },
};

const searchButtonStyle = {
  width: "36px",
  height: "33px",
  display: "flex",
  background: "#E9ECEF",
  justifyContent: "center",
  borderTopRightRadius: "3px",
  borderBottomRightRadius: "3px",
  marginRight: "-14px",
  cursor: "pointer",
};

const addButtonStyle = () => ({
  maxWidth: "160px",
  ml: 1.5,
  fontWeight: "normal",
  "@media (max-width: 570px)": {
    mt: 1.5,
    ml: 0,
    maxWidth: "100%",
  },
});

const container = {
  display: "flex",
  "@media (max-width: 570px)": {
    width: "100%",
    flexDirection: "column",
    margin: "8px 0px",
  },
};

const icons = {
  color: "blue",
  padding: "6px",
  fontSize: "1rem",
};

const wrap = {
  minHeight: "60vh",
};

// ---------Component ------------

const AllPages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const handleLogout = useLogout();
  const queryClient = useQueryClient();
  const { selectedItem } = useSelector((state) => state.team);
  const { token } = useSelector((state) => state.login);
  const [recordStart, setRecordStart] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filter, setFilter] = React.useState({});

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setSearchTerm(e.target.value.toLowerCase());
    } else {
      setFilter({ ...filter, name: "" });
    }
  };

  const handleOpen = (item) => {
    dispatch(setSelectItem(item));
    dispatch(OpenDeleteModal(true));
  };
  const handleEdit = (item) => {
    navigate(`/edit-campaign-ads/${item.name}`, {
      state: { item: item?.banner?.lottiefile },
    });
  };

  //===================get all data api=============

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["dataPage", filter],
    queryFn: () => fetchPageData(filter),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
  });

  const res = data?.data?.data.result;

  //============api delet ============

  const { mutate: deleteMutate } = useMutation(deletePage, {
    onSuccess: (data) => {
      toast.success(`${data?.message}`);
      queryClient.refetchQueries("dataPage");
    },
    onError: (error) => {
      toast.error(
        `${error?.response?.data?.message}` || "Something went wrong!"
      );
    },
  });
  const handleDelete = () => {
    deleteMutate({ id: selectedItem.name });
    dispatch(OpenDeleteModal(false));
  };

  const handlePageChange = (event, value) => {
    setFilter({ ...filter, page: value });
    navigate(`/campaign-ads?page=${value}`);
  };

  React.useEffect(() => {
    const { page = 1 } = queryString.parse(location?.search);

    page && setFilter({ ...filter, page: parseInt(page) });
    const start = (parseInt(page) - 1) * 10 + 1;
    setRecordStart(start);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleViewClick = (item) => {
    dispatch(setSelectItem(item));
    navigate(`/team/${item._id}`);
  };

  const handleSearch = () => {
    setFilter({ name: searchTerm });
    setRecordStart(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  React.useEffect(() => {
    dispatch(setBanner(null));
    dispatch(setTokenPage(null));
    dispatch(setProductPage(null));
    dispatch(setGetStarted(null));
    dispatch(setProfessional([]));
    dispatch(setNamePage(""));
  }, []);
  return (
    <>
      <Card>
        <Box sx={wrap}>
          <Box sx={mainBox}>
            <Typography variant="h1"> Pages</Typography>
            <Box sx={container}>
              <>
                <Button
                  variant="contained"
                  sx={addButtonStyle}
                  onClick={() => navigate("/add-campaign-ads")}
                  data-cy="add-Employee"
                >
                  + Add campaign
                </Button>
              </>
            </Box>
          </Box>
          <Box sx={tableBox}>
            <TableContainer>
              <Table hover="true" responsive="true" sx={{ minWidth: "100%" }}>
                <TableHead>
                  <TableRow sx={{ background: "transparent !important" }}>
                    <TableCell align="center">
                      <Typography variant="h3">#</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h3">Name</Typography>
                    </TableCell>

                    {/* <TableCell align="left">
                      <Typography variant="h3">Department</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h3">Designation</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h3">Category</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="h3">Sequence</Typography>
                    </TableCell> */}
                    <TableCell align="left">
                      <Typography variant="h3">Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* || isFetching */}
                  {isLoading ? (
                    <TableLoader colSpan={3} height="50vh" />
                  ) : !res?.length ? (
                    <TableNoData colSpan={3} height="50vh" />
                  ) : (
                    res?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left" sx={{ width: "50px" }}>
                          <Typography variant="h6">
                            {recordStart + index}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ width: "240px", textTransform: "capitalize" }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              maxWidth: { xs: "100px", sm: "240px" }, // Adjust the width according to your layout
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Link
                              to={`${process.env.REACT_APP_WEBSITE_PAGE_URL}/${item?.name}`}
                              underline="none"
                              target="_blank"
                              style={{textDecoration:"none", color:"#0575E6"}}
                            >
                              {item?.name}{" "}
                            </Link>
                          </Typography>
                        </TableCell>
                        {/* <TableCell
                          align="left"
                          sx={{ width: "240px", textTransform: "capitalize" }}
                        >
                          <Typography variant="h6">
                            {item?.department}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{ width: "240px", textTransform: "capitalize" }}
                        >
                          <Typography variant="h6">
                            {item.designation}{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h6">{item.category} </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            sx={{
                              width: "20px",
                              height: "20px",
                              background: " #0575E64D",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: item.level >= 10 ? "10px" : "11px",
                            }}
                          >
                            {item.level}
                          </Box>
                        </TableCell> */}
                        <TableCell align="left">
                          <Box sx={actionBox}>
                            {/* <>
                              {" "}
                              <IconButton
                                sx={icons}
                                onClick={() => handleViewClick(item)}
                                data-cy="view-Employee"
                              >
                                <img
                                  src={viewIcon}
                                  width="15px"
                                  height="100%"
                                  alt="view icon"
                                />
                              </IconButton>
                            </> */}

                            <>
                              <IconButton
                                sx={icons}
                                onClick={() => handleEdit(item)}
                                data-cy="edit-DepartmentTable"
                              >
                                <img
                                  src={editTableIcon}
                                  width="12px"
                                  height="100%"
                                  alt="edit icon"
                                />
                              </IconButton>
                            </>

                            <>
                              <IconButton
                                sx={icons}
                                onClick={() => {
                                  handleOpen(item);
                                }}
                                data-cy="delete-Employee"
                              >
                                <img
                                  src={deleteTableIcon}
                                  width="12px"
                                  height="100%"
                                  alt="edit icon"
                                />
                              </IconButton>
                            </>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box
          sx={{
            minHeight: "80px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {data?.data?.data?.page_total > 1 && (
            <TablePagination
              page={data?.data?.data?.page_number}
              count={data?.data?.data?.page_total}
              handleChange={handlePageChange}
            />
          )}
        </Box>
      </Card>
      <DeleteModal handleDelete={handleDelete} />
    </>
  );
};

export default AllPages;

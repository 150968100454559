import { Box, useTheme } from "@mui/material";
import React from "react";

const Card = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: "4px",
        background: theme.palette.background.default,
        height: "auto",
        position: "relative",
        width: "97%",
        m:"17px auto"
      }}
    >
      {children}
    </Box>
  );
};

export default Card;

export const GraphCard = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderRadius: "4px",
        background: theme.palette.background.default,
        width: "100%",
        height: "100%",
        position: "relative",
        border: "2px solid #E6EDFF",
      }}
    >
      {children}
    </Box>
  );
};

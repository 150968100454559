// formOptions.js

// import { EditorState } from "react-draft-wysiwyg";

export const options = [
  { label: "Frontend", value: "frontend" },
  { label: "Backend", value: "backend" },
  { label: "Design", value: "design" },
  { label: "SQA", value: "sqa" },
  { label: "HR", value: "hr" },
  { label: "Admin", value: "admin" },
  { label: "Management", value: "management" },
  { label: "Marketing ", value: "marketing" },
];

export const JobType = [
  { label: "Full-Time", value: "fullTime" },
  { label: "Part-Time", value: "partTime" },
  { label: "Special Service Contract", value: "contract" },
  { label: "Internship", value: "internship" },
];
export const JobStatus = [
  { label: "Open (Actively hiring)", value: "open" },
  { label: "Paused (On hold)", value: "paused" },
  { label: "Closed (Not hiring)", value: "closed" },
];

export const Designation = [
  { label: "Hod", value: "hod" },
  { label: "Lead", value: "lead" },
  { label: "Senior", value: "senior" },
  { label: "Associate", value: "associate" },
  { label: "Assistant", value: "assistant" },
  { label: "Junior", value: "junior" },
  { label: "Intern", value: "intern" },
];

export const main = { p: "20px" };

export const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
export const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};
export const genderBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "50px",
  gap: 1,
};
export const root = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};
export const dispBox = {
  display: "flex",
  flexWrap: "wrap",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  gap: 1,
};

export const clButton = {
  width: { xs: "100%", md: "81%", lg: "70.6%" },
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
};

// ImageLabel.js
import React from "react";
import { Box, Typography } from "@mui/material";
import { labelText } from "./FormOption"; 

const Reusablelable = ({label}) => {
  return (
    <Box sx={labelText}>
      <Typography>{label}</Typography>
    </Box>
  );
};

export default Reusablelable;

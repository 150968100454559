import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React, { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { setAddTags } from "../../../../../../redux/slices/servicePageSlice";
import { validationTagSchema } from "../../../../../../utils/Schema";
import ColorField from "../../../../../../components/colorField/ColorField";
import { drop, plus } from "../../../../../../assets/images";
import { useLocation } from "react-router-dom";

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const field = { minHeight: "90px" };

const AddTags = ({ tokenCardsErrors, tokenCardsTouched, setValue }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addTags } = useSelector((state) => state.page);
  const [open, setOpen] = useState(false);
  const [index, setindex] = useState(null);

  const initialValues = {
    name: "",
    nameColor: "",
    descColor: "",
    cardBgColor: "",
    description: "",
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
    setindex(null);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationTagSchema,
    onSubmit: (values) => {
      if (index !== null) {
        const editedIndex = addTags.findIndex((item, i) => i === index);
        const newProfessional = [...addTags];
        if (editedIndex !== -1) {
          newProfessional[editedIndex] = values;
          dispatch(setAddTags(newProfessional));
          setValue("tokenCards ", newProfessional);
          handleClose();
        }
      } else {
        dispatch(setAddTags([...addTags, values]));
        setValue("tokenCards ", [...addTags, values]);
        handleClose();
      }
    },
  });


  const handleEdit = (item, i) => {
    setFieldValue("name", item?.name);
    setFieldValue("nameColor", item?.nameColor);
    setFieldValue("description", item?.description);
    setFieldValue("descColor", item?.descColor);
    setFieldValue("cardBgColor", item?.cardBgColor);
    setindex(i);
    setOpen(true);
    // setFieldValue("tags", updatedTags);
  };

  const handleDelete = (index) => {
    const updatedTags = addTags.filter((item, i) => i !== index);
    dispatch(setAddTags(updatedTags)); // Dispatch action to update state
  };

  const {
    values,
    handleSubmit,
    handleChange,
    touched,
    errors,
    resetForm,
    setFieldValue,
  } = formik;

  return (
    <Box sx={{ mt: "0rem" }}>
      <Box>
        <Box
          sx={{
            border: `1px dashed #0575E6`,
            cursor: "pointer",
            borderRadius: "4px",
            width: "100%",
            background: "#0575E61A",
            minHeight: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
          onClick={() => setOpen(true)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={plus} alt="drop" width="30px" />
            <Typography variant="h6" sx={{ color: "#000", mt: "5px" }}>
              Add Card
            </Typography>
          </Box>
        </Box>
        {tokenCardsTouched.tokenCards &&
          Boolean(tokenCardsErrors?.tokenCards) && (
            <FormHelperText error sx={{ pl: "10px" }}>
              {tokenCardsTouched?.tokenCards && tokenCardsErrors?.tokenCards}
            </FormHelperText>
          )}
      </Box>
      <Box
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", margin: "10px 0px" }}
      >
        {addTags?.map((itm, i) => (
          <Box
            key={i}
            sx={{
              bgcolor: `${itm.cardBgColor}`,
              p: "5px 20px",
              borderRadius: "30px",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                color: `${itm.nameColor}`,
              }}
            >
              {itm.name}:
            </Typography>
            <Typography
              sx={{
                color: `${itm.descColor}`,
              }}
            >
              {itm.description}
            </Typography>
            <CloseIcon
              fontSize="20px"
              sx={{ cursor: "pointer", background: "#fff" }}
              onClick={() => handleDelete(i)}
            />
            <EditOutlinedIcon
              fontSize="20px"
              sx={{ cursor: "pointer", background: "#fff" }}
              onClick={() => handleEdit(itm, i)}
            />
          </Box>
        ))}
      </Box>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "25px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "25px",
              "@media (max-width: 480px)": {
                fontSize: "20px",
              },
            }}
          >
            Add Tags
          </Typography>
          <Box
            onClick={() => handleClose()}
            sx={{ cursor: "pointer" }}
            data-cy="add-tages-CloseIcon"
          >
            <CloseIcon />
          </Box>
        </DialogTitle>
        <Divider />
        <Box
          sx={{
            p: { xs: "20px", md: "20px" },
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={field}>
              <Typography>Name*</Typography>
              <TextField
                fullWidth
                type="text"
                name="name"
                placeholder="Name"
                value={values.name}
                onChange={handleChange}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>

            <Box sx={field}>
              <Typography>Name Color*</Typography>
              <ColorField
                fullWidth
                name="nameColor"
                placeholder="Name Color"
                type="color"
                value={values.nameColor}
                onChange={handleChange}
                error={Boolean(touched.nameColor && errors.nameColor)}
                helperText={touched.nameColor && errors.nameColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>

            <Box sx={field}>
              <Typography>Description Color*</Typography>
              <ColorField
                fullWidth
                type="color"
                placeholder="Description Color"
                name="descColor"
                value={values.descColor}
                onChange={handleChange}
                error={Boolean(touched.descColor && errors.descColor)}
                helperText={touched.descColor && errors.descColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>

            <Box sx={field}>
              <Typography>Card Background Color*</Typography>
              <ColorField
                fullWidth
                type="color"
                placeholder="Card Background Color"
                name="cardBgColor"
                value={values.cardBgColor}
                onChange={handleChange}
                error={Boolean(touched.cardBgColor && errors.cardBgColor)}
                helperText={touched.cardBgColor && errors.cardBgColor}
                // sx={{maxWidth:"150px"}}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <Typography>Description*</Typography>
              <TextField
                type="text"
                name="description"
                placeholder="Description"
                fullWidth
                value={values.description}
                onChange={handleChange}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                multiline
                minRows={4}
                maxRows={4}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "8px 0px ",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleClose()}
                sx={{ maxWidth: "90px" }}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ maxWidth: "90px" }}
              >
                submit
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
};

export default AddTags;

import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "./components/SideDrawer";
import BackButton from "../../components/backButton/BackButton";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLogout } from "../../hooks/logout";

// ---------Component style------------

const menuePropsStyle = {
  elevation: 0,
  sx: {
    overflow: "visible",
    borderRadius: "8px",
    maxWidth: "230px",
    minWidth: "230px",
    mt: 1.5,
    pading: "0px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "& .MuiMenu-list": {
      padding: "0px",
    },
    "& .MuiMenuItem-root": {
      margin: "0px",
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const navContainer = {
  display: "flex",
  alignItems: "center",
  // pr: 3,
  // width: { xs: "100%", sm: "50%", md: "50%" },
  justifyContent: "space-between",
};

const iconContainer = {
  display: "flex",
  alignItems: "center",
  // width: { xs: "100%", sm: "50%", md: "50%" },
  justifyContent: "flex-end",
};

const title = {
  // width: "160px",
};

const mainContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
};

const topDiv = (theme) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  borderBottom: `2px solid ${theme.palette.primary.lightGray}`,
  paddingRight: "16px",
  paddingLeft: "16px",
  minHeight: "64px",
});

// ---------Component ------------

const TopBar = () => {
  const { id, name } = useParams();
  const location = useLocation();
  const handleLogout = useLogout();

  const navigate = useNavigate();
  const { loginData } = useSelector((state) => state.login);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //This function is called when the user closes the menu, and it sets the anchorEl variable to null.
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setAnchorEl1(false);
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          maxHeight: "400px",
          minHeight: "64px",
          background: "rgba(255, 255, 255, 0.27)",
          flexWrap: "wrap",
          justifyContent: "space-between",
          backdropFilter: "blur(8px)",
          zIndex: 999,
        }}
      >
        <Box sx={topDiv}>
          <Box sx={navContainer}>
            <Box sx={mainContainer}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                data-cy="menu-openDrawer"
                sx={{
                  mr: 0,
                  display: { xs: "flex", sm: "flex", md: "none" },
                }}
                onClick={handleDrawerOpen}
              >
                <MenuIcon sx={{ width: "22px", height: "22px" }} />
              </IconButton>

              {location.pathname === "/" ||
              location.pathname === "/services" ||
              location.pathname === "/expertise" ||
              location.pathname === "/settings" ||
              location.pathname === "/jobs" ||
              location.pathname === "/applicant" ||
              location.pathname === "/campaign-ads" ||
              location.pathname === "/contact" ? (
                ""
              ) : (
                <BackButton />
              )}

              <Typography variant="h1" sx={title}>
                {location.pathname === "/"
                  ? "team"
                  : location.pathname === "/add-employee"
                  ? "add employee"
                  : location.pathname === `/edit-employee/${id}`
                  ? "edit employee"
                  : location.pathname === `/team/${id}`
                  ? "employee"
                  : location.pathname === "/services"
                  ? "services"
                  : location.pathname === "/add-services"
                  ? "add services"
                  : location.pathname === "/sub-services"
                  ? "sub services"
                  : location.pathname === `/edit-services/${id}`
                  ? "edit services"
                  : location.pathname === `/edit-sub-services/${id}`
                  ? "edit sub services"
                  : location.pathname === "/expertise"
                  ? "expertise"
                  : location.pathname === "/add-expertise"
                  ? "add-expertise"
                  : location.pathname === `/edit-expertise/${id}`
                  ? "edit expertise"
                  : location.pathname === `/contact/${id}`
                  ? "contact"
                  : location.pathname === `/applicant/${id}`
                  ? "Applicants"
                  : location.pathname === `/applicant`
                  ? "Applicants"
                  : location.pathname === `/edit-job/${id}`
                  ? "Jobs"
                  : location.pathname === `/jobs/${id}`
                  ? "Jobs"
                  : location.pathname === `/edit-campaign-ads/${name}`
                  ? "Edit Campaign Ads"
                  : location.pathname === `/jobs`
                  ? "Jobs"
                  : location.pathname === `/edit-banner`
                  ? "Edit Banner"
                  : location.pathname === `/servicePage`
                  ? "Add Services"
                  : location.pathname === `/add-job`
                  ? "Jobs"
                  : location.pathname === `/edit-token`
                  ? "Edit Token"
                  : location.pathname === `/add-token`
                  ? "Add  Token"
                  : location.pathname === `/edit-product`
                  ? "Edit  product"
                  : location.pathname === `/add-product`
                  ? "Add  product"
                  : location.pathname === `/edit-getStarted`
                  ? "Edit  GetStarted"
                  : location.pathname === `/add-getStarted`
                  ? "Add  GetStarted"
                  : location.pathname === `/edit-professionals`
                  ? "Edit  professionals"
                  : location.pathname === `/add-professionals`
                  ? "Add  professionals"
                  : location.pathname === `/add-campaign-ads`
                  ? "Add Campaign Ads"
                  : location.pathname === `/campaign-ads`
                  ? "Campaign Ads"
                  : location.pathname.substring(1)}
              </Typography>
            </Box>
          </Box>

          <Box sx={iconContainer}>
            <IconButton
              data-cy="menu-drawer"
              onClick={handleMenu}
              size="small"
              sx={{ ml: "4px", mb: "4px" }}
              aria-controls={anchorEl ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
            >
              <Avatar sx={{ width: 28, height: 28, background: "#0575E6" }}>
                A
              </Avatar>
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuePropsStyle}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            my: 1.5,
            px: 2.5,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="h3" noWrap sx={{ textTransform: "capitalize" }}>
            {loginData?.name || ""}
          </Typography>
          <Typography
            variant="subtitle3"
            sx={{ fontSize: "14px", fontFamily: "Poppins-Regular" }}
          >
            {loginData?.email || ""}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem sx={{ m: 1 }} onClick={() => navigate("/settings")}>
          <Typography variant="h2">Settings</Typography>
        </MenuItem>
        <MenuItem
          sx={{ m: 1 }}
          onClick={(e) => {
            e.preventDefault();
            handleLogout();
            navigate("/sign-in");
          }}
        >
          <Typography variant="h2">Logout</Typography>
        </MenuItem>
      </Menu>
      <MiniDrawer
        anchorEl1={anchorEl1}
        setAnchorEl1={setAnchorEl1}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        setOpen={setOpen}
      />
    </React.Fragment>
  );
};

export default TopBar;

// RichTextEditor.js
import React from "react";
import { Editor } from "react-draft-wysiwyg";
// import {
//   EditorState,
//   ContentState,
//   convertToRaw,
//   convertFromHTML,
// } from "draft-js";
// import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { palette } from "../../../theme/lightTheme/palette";

const RichTextEditor = ({ value, onChange, error }) => {
  const handleEditorStateChange = (editorState) => {
    onChange(editorState);
  };

  return (
    <Editor
      wrapperStyle={{
        border: error
          ? "1px solid red"
          : `1px solid ${palette.primary.lightGray}`,
        borderRadius: "5px",
      }}
      editorState={value}
      onEditorStateChange={handleEditorStateChange}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "embedded",
          "emoji",
          // "image",
          "remove",
          "history",
        ],
        inline: {
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "monospace",
          ],
        },
        blockType: {
          options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
        },
        fontSize: {
          options: [8, 12, 16, 20, 24, 28, 32, 36, 40],
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ],
        },
        list: {
          options: ["unordered", "ordered"],
        },
        textAlign: {
          options: ["left", "center", "right", "justify"],
        },
        colorPicker: {
          className: "custom-css-class",
        },
        link: {
          className: "custom-css-class",
        },
        embedded: {
          className: "custom-css-class",
        },
        emoji: {
          className: "custom-css-class",
        },
        image: {
          className: "custom-css-class",
        },
        remove: {
          className: "custom-css-class",
        },
        history: {
          className: "custom-css-class",
        },
      }}
    />
  );
};

export default RichTextEditor;

// FormField.js
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
// import ReusableSelect from "./ReusableSelect"; // Replace with the correct path
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Radio from "@mui/material/Radio";

const labelText = { minWidth: { xs: "100%", md: "30%", lg: "20%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "67px",
  gap: 1,
};
const FormField = ({
  label,
  name,
  type,
  values,
  handleChange,
  touched,
  errors,
  options,
  radioOptions,
  labeltext,
}) => {
  return (
    <Box sx={fieldBox}>
      <Box sx={labelText}>
        <Typography>{`${label}*`}</Typography>
      </Box>
      <Box sx={{ width: { sx: "100%", md: "50%" } }}>
        {type === "text" && (
          <TextField
            fullWidth
            placeholder={labeltext ? labeltext : label.toLowerCase()}
            name={name}
            value={values[name]}
            onChange={handleChange}
            error={Boolean(touched[name] && errors[name])}
            helperText={touched[name] && errors[name]}
          />
        )}

        {type === "number" && (
          <TextField
            fullWidth
            placeholder={label}
            name={name}
            value={values[name]}
            onChange={handleChange}
            type="number"
            inputProps={{
              min: 0,
            }}
            error={Boolean(touched[name] && errors[name])}
            helperText={touched[name] && errors[name]}
          />
        )}
        {type === "Labeltext" && (
          <Box sx={labelText}>
            <Typography>{label}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FormField;

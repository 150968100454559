import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import * as React from "react";

// ---------Component style------------

const paginationStyle = {
  "& .MuiPaginationItem-root": {
    color: "black",

    "& ul > li:not(:first-of-type):not(:last-child) > button:not(.Mui-selected)":
      {
        backgroundColor: "#fff",
        color: "black !important",
        borderColor: "#3f6dfd",
        fontFamily: "Poppins-Regular",
        fontWeight: 700,
        fontSize: 14,
      },
  },
  "& .Mui-selected": {
    backgroundColor: "#3f6dfd !important",
    color: "#fff !important",
    borderColor: "#3f6dfd",
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    fontSize: 14,
    "&:hover": {
      backgroundColor: "#3f6dfd !important",
      color: "#fff",
      borderColor: "#fff",
      fontFamily: "Poppins-Regular",
      fontWeight: 700,
      fontSize: 14,
    },
  },
  "& .MuiPaginationItem-page": {
    color: "black",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#7a9aff",
      color: "#fff",
      borderColor: "#3f6dfd",
      fontFamily: "Poppins-Regular",
      fontWeight: 700,
      fontSize: 14,
    },
  },

  "& .MuiPagination-ul": {
    justifyContent: "end",
  },
};

// ---------Component ---------

export default function TablePagination(props) {
  return (
    <Box
      sx={{
        width: "95%",
        margin: "auto",
      }}
    >
      <Pagination
        sx={paginationStyle}
        count={props.count}
        page={props.page}
        onChange={props.handleChange}
        shape="rounded"
        showFirstButton
        showLastButton
        variant="outlined"
      />
    </Box>
  );
}

import React from "react";

import { Box } from "@mui/material";
import MiniDrawer from "./components/Menue";

const SideBar = () => {
  return (
    <Box>
      <MiniDrawer />
    </Box>
  );
};

export default SideBar;

import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

const headers = [
  "#",
  "Job Title",
  "Name",
  "Email",
  "Phone Number",
  "Profile Url",
  "Job Location",
  "Resume",
  "City",
  "Action",
];

const TableHeadComponent = () => {
  return (
    <TableRow sx={{ background: "transparent !important" }}>
      {headers.map((header, index) => (
        <TableCell align={index === 0 ? "center" : "left"} key={index}>
          <Typography variant="h3">{header}</Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableHeadComponent;

import {
  Box,
  IconButton,
  InputAdornment,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { filterIcon, searchIcon, viewIcon } from "../../assets/images";
import Card from "../card";
import TableNoData from "../tablenodata";
import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "react-query";
import TableLoader from "../tableloader";
import { useDispatch } from "react-redux";
import { setSelectItem } from "../../redux/slices/teamSlice";
import { fetchApplicantData } from "../../api/JobApplication";
import queryString from "query-string";
import JobapplicationDrawer from "../reusableFilter/JobapplicationDrawer";
import TableHeadComponent from "./Tablehead";
import Link from "@mui/material/Link";
import CopyBox from "../copyBox";
import { CustomTooltip } from "../customTooltip";
import useJobFilterForm from "./useJobFilterForm"; // Adjust the import path
import FilterChips from "./FilterChips";
import ReusablePagination from "./ReusablePagination";
import {
  buttonfilter,
  container,
  drawerbtn,
  ellpsisBox,
  icons,
  mainBox,
  searchButtonStyle,
  searchfieldStyle,
  tableBox,
  wrap,
} from "../tableStyle/style";
import {
  capitalizeFirstLetter,
  convertJobTypeLabel,
  downloadFile,
} from "../../helper/helper";
import CustomDisplay from "./CustomDisplay";
import TablePagination from "../pagination";

// ---------Component ------------
const JobApplicationTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [recordStart, setRecordStart] = React.useState(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filter, setFilter] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [drawerfilter, setDrawerfilter] = React.useState({});

  const handleChange = (e) => {
    if (e.target.value !== "") {
      setSearchTerm(e.target.value.toLowerCase());
    } else {
      setFilter({ ...filter, name: "" });
    }
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const { formik, resetForm, resetSpecificValue } = useJobFilterForm(
    setDrawerfilter,
    handleDrawerClose
  );
  //===================get all data api=============

  // Log the updated URL

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["Jobdata", filter],
    queryFn: () => fetchApplicantData(filter),
    keepPreviousData: true,
    notifyOnChangeProps: ["data", "error"],
  });

  const res = data?.data?.data.result;

  const Params = new URLSearchParams(location.search);

  const handlePageChange = (event, value) => {
    setFilter({ ...filter, page: value });
    const idParam = Params.get("id");
    if (idParam) {
      navigate(`/applicant?id=${idParam}&page=${value}`);
    } else {
      navigate(`/applicant?page=${value}`);
    }
  };
  React.useEffect(() => {
    const Params = new URLSearchParams(location.search);
    Params.get("id");
    const { page = 1 } = queryString.parse(location?.search);
    page &&
      setFilter({
        ...filter,
        page: parseInt(page),
        jobId: Params.get("id"),
        ...drawerfilter,
      });
    const start = (parseInt(page) - 1) * 10 + 1;
    setRecordStart(start);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, drawerfilter]);

  const handleViewClick = (item) => {
    dispatch(setSelectItem(item));
    // navigate(`/applicant/${item._id}`);
  };

  const handleSearch = () => {
    setFilter({ name: searchTerm, ...drawerfilter });
    setRecordStart(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const filteredValues = Object.keys(drawerfilter).reduce((acc, key) => {
    if (drawerfilter[key] !== "" && drawerfilter[key] !== null) {
      acc[key] = drawerfilter[key];
    }
    return acc;
  }, {});

  return (
    <>
      <Card>
        <Box sx={wrap}>
          <Box sx={mainBox}>
            <Box
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                flexWrap: "wrap",
                minHeight: "45px",
              }}
            >
              <Typography variant="h1">Job Applicants</Typography>
              <FilterChips
                filteredValues={filteredValues}
                drawerFilter={drawerfilter}
                resetSpecificValue={resetSpecificValue}
                convertJobTypeLabel={convertJobTypeLabel}
              />
            </Box>

            <Box sx={container}>
              <TextField
                placeholder="Search By Name/Email"
                fullWidth
                sx={searchfieldStyle}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                autoComplete="off"
                inputProps={{
                  "data-cy": "search-CareerTable", // Cypress ID assigned to the search input element
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={searchButtonStyle}
                        onClick={handleSearch}
                        data-cy="search-button-Employee"
                      >
                        <img
                          src={searchIcon}
                          style={{ width: "13px" }}
                          alt="visibleon"
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={buttonfilter}>
                <Box sx={drawerbtn} onClick={handleDrawerOpen}>
                  <IconButton>
                    <img src={filterIcon} alt="filterImage" />
                  </IconButton>
                </Box>
              </Box>
              <JobapplicationDrawer
                open={open}
                handleDrawerClose={handleDrawerClose}
                setDrawerfilter={setDrawerfilter}
                formik={formik}
                resetForm={resetForm}
                filter={filter}
                setFilter={setFilter}
              />
            </Box>
          </Box>
          <Box sx={tableBox}>
            <TableContainer>
              <Table hover="true" responsive="true">
                <TableHeadComponent />
                <TableBody>
                  {isLoading ||
                  (Params.get("id") && isFetching) ||
                  (isFetching && Object.keys(filteredValues).length > 0) ? (
                    <TableLoader colSpan={10} height="60vh" />
                  ) : !res?.length ? (
                    <TableNoData colSpan={10} height="60vh" />
                  ) : (
                    res?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left" sx={{ width: "50px" }}>
                          <Typography variant="h6">
                            {recordStart + index}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          <CustomTooltip
                            title={capitalizeFirstLetter(item?.job?.title)}
                          >
                            <Typography variant="h6" sx={ellpsisBox("100px")}>
                              {item?.job?.title}{" "}
                            </Typography>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell align="left">
                          <CustomTooltip
                            title={
                              item && item.name
                                ? capitalizeFirstLetter(item.name)
                                : ""
                            }
                          >
                            <Typography variant="h6" sx={ellpsisBox("160px")}>
                              {item && item.name
                                ? capitalizeFirstLetter(item.name)
                                : ""}
                            </Typography>
                          </CustomTooltip>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: "150px",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CustomTooltip title={item?.email}>
                              <Typography variant="h6" sx={ellpsisBox("130px")}>
                                {item?.email}{" "}
                              </Typography>
                            </CustomTooltip>
                            <CopyBox title={item?.email} />
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h6">{item?.mobile} </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <CustomTooltip title={item?.url}>
                              <Typography variant="h6" sx={ellpsisBox("130px")}>
                                <Link
                                  href={item?.url}
                                  underline="none"
                                  target="_blank"
                                >
                                  {item?.url}
                                </Link>
                              </Typography>
                            </CustomTooltip>
                            <CopyBox title={item?.url} />
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h6">
                            {item?.job?.jobLocation}{" "}
                          </Typography>
                        </TableCell>
                        <CustomDisplay
                          type="file"
                          data={item?.resume}
                          index={index}
                          recordStart={recordStart}
                          downloadFile={downloadFile}
                        />

                        <CustomDisplay type="city" data={item?.city} />

                        <CustomDisplay
                          type="action"
                          data={item}
                          handleAction={handleViewClick}
                          icons={icons}
                          viewIcon={viewIcon}
                        />
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <ReusablePagination
          page={data?.data?.data?.page_number}
          count={data?.data?.data?.page_total}
          handlePageChange={handlePageChange}
        />
      </Card>
    </>
  );
};
export default JobApplicationTable;

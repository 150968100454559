import React from "react";
import { Box } from "@mui/material";
import TablePagination from "../pagination";

const ReusablePagination = ({ count,page, handlePageChange }) => {
  return (
    <>
      {count > 1 && (
        <Box
          sx={{
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            pb: { xs: "30px", sm: "20px" },
          }}
        >
          <TablePagination
            count={count}
            page={page}
            handleChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
};

export default ReusablePagination;

// import CloseIcon from "@mui/icons-material/Close";
// import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { IconTool } from "components/tooltip";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpenDeleteModal } from "../../redux/slices/adminSlice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import { OpenDeleteModal } from "store/slices/modalsSlice";

const CareerDeleteModal = ({ handleDelete }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.admin);
  const fullScreen = useMediaQuery(theme.breakpoints.down("480px"));

  const handleClose = () => {
    dispatch(OpenDeleteModal(false));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      data-cy={`delete-close`}
      aria-labelledby="responsive-dialog-title"
      BackdropProps={{
        sx: { backdropFilter: "blur(10px)" }, // Increase blur amount
      }}
    >
      <Box sx={{ position: "absolute", right: 9, top: 8, cursor: "pointer" }}>
        <CloseOutlinedIcon sx={{ fill: "blue" }} onClick={handleClose} />
      </Box>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "100px",
          padding: "20px 50px",
          gap: "10px",
          "@media (max-width: 480px)": {
            padding: "20px 10px",
          },
        }}
      >
        <InfoOutlinedIcon
          sx={{
            color: "blue",
            backgoundColor: "blue",
            fill: "blue",
            height: "35px",
            width: "35px",
          }}
        />
        <Typography
          variant="h2"
          sx={{
            fontSize: "20px",
            textAlign: "center",
          }}
        >
          Are You Sure?
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          You will not be able to recover this!
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          padding: "8px 90px 24px 90px",
          justifyContent: "center",
          "@media (max-width: 480px)": {
            fontSize: "12px",
            padding: "8px 10px 24px 10px",
            justifyContent: "center",
          },
        }}
      >
        <Button
          autoFocus
          onClick={handleClose}
          data-cy="cancel-submit"
          variant="outlined"
          sx={{
            maxWidth: "80px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          data-cy="delete-submit"
          autoFocus
          variant="contained"
          sx={{
            maxWidth: "80px",
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CareerDeleteModal;

import React from "react";
import { Box, Chip, Divider } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";

const FilterChips = ({ filteredValues, drawerFilter, resetSpecificValue, convertJobTypeLabel }) => {
  return (
    <>
      {Object.keys(filteredValues).length > 0 && (
        <Divider orientation="vertical" sx={{ height: "40px", ml: "5px" }} />
      )}
      {Object?.entries(drawerFilter).map(
        ([key, value]) =>
          value && (
            <Box
              key={key}
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Chip
                label={
                  key === "applyDateFrom"
                    ? dayjs(value).format("DD-MM-YYYY")
                    : key === "applyDateTo"
                    ? dayjs(value).format("DD-MM-YYYY")
                    : convertJobTypeLabel(value)
                }
                sx={{ borderColor: "#0575E6", marginBlock: "12px" }}
                variant="outlined"
                deleteIcon={<CancelIcon sx={{ fill: "#0575E6" }} />}
                onDelete={() => {
                  resetSpecificValue(key, "");
                  // Clear the specific field
                }}
                style={{ margin: "5px" }}
              />
            </Box>
          )
      )}
    </>
  );
};

export default FilterChips;

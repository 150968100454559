import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePasswordSchema } from "../../../utils/Schema";
import Heading from "../../../components/heading/Heading";
import { changePassword } from "../../../api/Auth";

// ---------Component style------------

const labelText = { minWidth: { xs: "100%", md: "38%", lg: "25%" } };
const fieldBox = {
  display: "flex",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
  minHeight: "70px",
  gap: 1,
};

const lableText = { mb: 0.5 };

const buttonStyle = {
  maxWidth: "80px",
};
const backButton = {
  maxWidth: "80px",
  mr: 2,
};
const buttonBox = {
  textAlign: "end",
  width: { xs: "100%", md: "89%", lg: "75.6%" },
  justifyContent: "center",
};

// ---------Component ---------

const PasswordSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.login);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showCPassword, setShowCPassword] = React.useState(false);

  const handleClickShowCPassword = () => setShowCPassword((show) => !show);

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const [showDPassword, setShowDPassword] = React.useState(false);

  const handleClickShowDPassword = () => setShowDPassword((show) => !show);

  const handleMouseDownDPassword = (event) => {
    event.preventDefault();
  };

  const { mutate, isLoading } = useMutation(changePassword, {
    onSuccess: (data) => {
      toast.success("Updated password successfully ");
      navigate("/");
    },
    onError: (error) => {},
  });

  // Formik object for managing form state and validation

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: () => changePasswordSchema(),
    onSubmit: (values) => {
      mutate({ values, token, dispatch });
    },
  });

  // ---------Component ------------

  const { values, handleBlur, handleSubmit, handleChange, touched, errors } =
    formik;

  return (
    <Box>
      <Heading text="Change Password" />
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Box sx={fieldBox}>
          <Box sx={labelText}>
            <Typography sx={lableText}>Current Password</Typography>
          </Box>
          <Box sx={{ width: { sx: "100%", md: "50%" } }}>
            <TextField
              placeholder="Enter Current Password"
              name="currentPassword"
              inputProps={{
                "data-cy": "currentPassword-ChangePassword", // Cypress ID assigned to the input element
              }}
              type={showPassword ? "text" : "password"}
              value={values.currentPassword || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={Boolean(touched.currentPassword && errors.currentPassword)}
              helperText={touched.currentPassword && errors.currentPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      data-cy="currentPassword-visible"
                    >
                      {showPassword ? (
                        <Visibility
                          sx={{ fill: "#0575E6", fontSize: "18px" }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ fill: "#0575E6", fontSize: "18px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box sx={fieldBox}>
          <Box sx={labelText}>
            <Typography sx={lableText}>New Password</Typography>
          </Box>
          <Box sx={{ width: { sx: "100%", md: "50%" } }}>
            <TextField
              placeholder="Enter password"
              name="password"
              inputProps={{
                "data-cy": "password-ChangePassword", // Cypress ID assigned to the input element
              }}
              type={showCPassword ? "text" : "password"}
              value={values.password || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCPassword}
                      onMouseDown={handleMouseDownCPassword}
                      edge="end"
                      data-cy="password-visible"
                    >
                      {showCPassword ? (
                        <Visibility
                          sx={{ fill: "#0575E6", fontSize: "18px" }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ fill: "#0575E6", fontSize: "18px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box sx={fieldBox}>
          <Box sx={labelText}>
            <Typography sx={lableText}>Confirm Password</Typography>
          </Box>
          <Box sx={{ width: { sx: "100%", md: "50%" } }}>
            <TextField
              placeholder="Confirm Password"
              name="confirmPassword"
              type={showDPassword ? "text" : "password"}
              value={values.confirmPassword || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                "data-cy": "confirmPassword-ChangePassword", // Cypress ID assigned to the input element
              }}
              fullWidth
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowDPassword}
                      onMouseDown={handleMouseDownDPassword}
                      edge="end"
                      data-cy="confirmPassword-visible"
                    >
                      {showDPassword ? (
                        <Visibility
                          sx={{ fill: "#0575E6", fontSize: "18px" }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{ fill: "#0575E6", fontSize: "18px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box sx={buttonBox}>
          <Button
            variant="outlined"
            data-cy="back-leave"
            sx={backButton}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            data-cy="submit-ChangePassword"
            sx={buttonStyle}
            fullWidth
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              "Update"
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default PasswordSetting;

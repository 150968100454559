import React, { useEffect } from "react";
import ServicesCard from "../../components/servicesCard/ServicesCard";
import Card from "../../components/card";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../redux/slices/adminSlice";
import { setActive } from "../../redux/slices/teamSlice";

//=============style==================

const wrap = {
  minHeight: "87vh",
};

const mainBox = (theme) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  p: "12px 16px",
  borderBottom: `2px solid ${theme.palette.primary.lightGray}`,
  justifyContent: "space-between",
  "@media (max-width: 570px)": {
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
    margin: "8px 0px",
  },
});

const container = {
  display: "flex",
  "@media (max-width: 570px)": {
    width: "100%",
    flexDirection: "column",
    margin: "8px 0px",
  },
};

const addButtonStyle = () => ({
  maxWidth: "160px",
  ml: 1.5,
  fontWeight: "normal",
  "@media (max-width: 570px)": {
    mt: 1.5,
    ml: 0,
    maxWidth: "100%",
  },
});

//=============component==================

const ExpertiseData = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSelectedTab(0));
    dispatch(setActive(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Card>
        <Box sx={wrap}>
          <Box sx={mainBox}>
            <Typography variant="h2">Expertise List</Typography>
            <Box sx={container}>
              <>
                <Button
                  variant="contained"
                  sx={addButtonStyle}
                  onClick={() => navigate("/add-expertise")}
                  data-cy="add-Employee"
                >
                  + Add Expertise
                </Button>
              </>
            </Box>
          </Box>
          <ServicesCard />
        </Box>
      </Card>
    </div>
  );
};

export default ExpertiseData;
